.login-page {
    @include flex;
    //min-height: 100vh;

    .branding {
        width: 50%;
        background-image: url(../assets/images/Rectangle.png);
        background-size: cover;
        @include flex;
        @include align-items(center);
        @include justify-content(center);
        @include flex-direction(column);
        padding: 0 13%;
        text-align: center;

        &-info {
            @include responsive-size(480px, 1920, 1850, width);

            hr {
                width: 100%;
                height: 1px;
                background: #fff;
                border: none;
            }

            &__logo {
                @include responsive-size(280px, 1920, 1300, width);
                @include responsive-size(20px, 1920, 992, margin-bottom)
            }

            &__desc {
                color: #fff;
                font-size: 20px;
                line-height: 30px;
                font-weight: 400;
                margin: 0;
            }

        }

        @include media(992) {
            display: none;
        }
    }

    .login {
        width: 100%;
        @include flex;
        @include flex-direction(column);

        &-form {
            @include responsive-size(460px, 1920, 1680, max-width);
            width: 100%;
            padding-bottom: 3%;
            margin: auto;
            text-align: center;

            &__logo {
                @include responsive-size(320px, 1920, 1400, width);
                @include responsive-size(50px, 1920, 1200, margin-bottom)
            }

            &__title {
                font-weight: 600;
                line-height: 40px;
                color: $mainColor;
                @include responsive-size(50px, 1920, 1300, margin-bottom);
                @include responsive-size(26px, 1920, 1680, font-size);
            }

            .token-message {
                background: rgba($mainColor, 0.15);
                border-radius: 4px;
                padding: 15px;
                margin-bottom: 15px;

                p {
                    font-size: 14px;
                    line-height: 20px;
                    color: $mainColor;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .form-group {
                @include flex;
                @include justify-content(space-between);
                @include align-items(flex-start);
                margin-bottom: 0;

                .data_check {
                    font-size: 14px;
                }

                .form-field {
                    width: 48.5%;

                    &.salutation {
                        @include flex;
                        @include justify-content(space-between);
                        @extend %radioBtn;
                    }
                }

                @include media(576) {
                    display: block;
                    .form-field {
                        width: 100%;

                        &.salutation {
                            width: 250px;
                        }
                    }
                }
            }

            .agreement {
                text-align: left;

                &__title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #524F63;
                    margin: 10px 0;
                }

                label {
                    @include flex;
                    margin-left: -26px;
                    cursor: pointer;

                    p {
                        font-size: 14px !important;
                        line-height: 21px !important;
                        text-align: justify;
                        color: rgba(#524F63, 0.5);
                        margin-left: 10px;

                        &, a {
                            color: rgba($textColor, 0.5);
                        }

                        a {
                            font-weight: 500;
                            text-decoration: underline;
                        }
                    }

                    @extend %checkbox;

                    span {
                        margin-top: 4px;
                    }

                    &:last-child {
                        margin-bottom: 20px;
                    }

                    @include media(576) {
                        margin-left: 0;
                    }
                }
            }

            &__forgot {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: $mainColor;
                text-align: right;
            }

            &__email {
                font-size: 14px;
                line-height: 20px;

                a {
                    font-weight: 600;
                    color: $mainColor;
                    text-decoration: none;
                }
            }
        }

        &--register {
            @include responsive-size(480px, 1920, 1800, max-width);
        }

        .footer {
            background: $greyBg;
            text-align: center;

            &-menu {
                display: inline-flex;
                list-style-type: none;
                padding: 0;

                &__item {
                    padding: 0 15px;
                    position: relative;

                    a {
                        font-size: 14px;
                        line-height: 21px;
                        color: rgba($textColor, 0.4);
                        text-decoration: none;
                    }

                    &:not(:last-child)::after {
                        content: '';
                        width: 1px;
                        height: 100%;
                        background: rgba($textColor, 0.2);
                        position: absolute;
                        right: 0;
                        top: 0;
                    }

                    &--desc {
                        position: relative;

                        &:hover {
                            &::before {
                                content: attr(data-info);
                                min-width: 350px;
                                background: #FFFFFF;
                                box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
                                padding: 10px 15px;
                                border-radius: 2px;
                                font-size: 14px;
                                line-height: 21px;
                                color: rgba(82, 79, 99, 0.4);
                                text-align: left;
                                position: absolute;
                                right: 0;
                                bottom: 35px;
                            }

                            a {
                                &::before {
                                    content: '';
                                    border-top: 10px solid #dbdfe4;
                                    border-left: 10px solid transparent;
                                    border-right: 10px solid transparent;
                                    position: absolute;
                                    left: 50%;
                                    bottom: 25px;
                                    z-index: 2;
                                }

                                &::after {
                                    content: '';
                                    border-top: 8px solid #ffffff;
                                    border-left: 8px solid transparent;
                                    border-right: 8px solid transparent;
                                    position: absolute;
                                    left: 51%;
                                    bottom: 27px;
                                    z-index: 2;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include media(1000) {
            width: 100%;
        }
        @include media(576) {
            &-form {
                padding: 6%;
            }
            .footer {
                &-menu {
                    display: block;

                    &__item {
                        margin: 10px;

                        &::after {
                            display: none;
                        }

                        &--desc:hover::before {
                            min-width: 270px;
                        }
                    }
                }
            }
        }
    }

    p {
        font-size: 16px !important;
        line-height: 24px;
        margin: 0 0 7px 0;
    }

    .modal {
        &-dialog {
            &.checkEmail {
                text-align: center;

                p {
                    font-size: 18px;
                }
            }

            .modal-content {
                .modal-header {
                    border-bottom: none;
                    padding-bottom: 0;

                    .close {
                        span {
                            font-weight: 400;
                            font-size: 30px;
                        }
                    }
                }

                .modal-body {
                    padding: 0 10%;

                    .modal-title {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                        margin-bottom: 30px;

                        &--big {
                            font-weight: bold;
                            font-size: 22px;
                            line-height: 33px;
                            color: $mainColor;
                        }
                    }

                    p {
                        font-weight: 500;

                        span {
                            font-weight: 600;
                        }
                    }

                    hr {
                        background: $mainColor;
                        border-radius: 5px;
                        width: 25%;
                        margin: 4% auto 5%;
                    }

                    .form-field__input {
                        font-size: 15px;
                        line-height: 22px;
                    }
                }

                .modal-footer {
                    padding-bottom: 5%;
                    border-top: none;
                    @include justify-content(center);
                }
            }
        }
    }

    .form-field {
        position: relative;
        margin-bottom: 10px;

        .salutation {
            @include responsive-size(180px, 1920, 1200, width);
            @include flex;
            @include justify-content(space-between);
            @extend %radioBtn;
        }

        &.salutation {
            label {
                @include flex;
                @include align-items(center);

                span {
                    margin-right: 5px;
                }
            }
        }

        label {
            font-weight: 600;
            @include responsive-size(16px, 1920, 1600, font-size);
        }

        &__input {
            @extend %form-field;
        }

        &__select {
            @extend %form-field;
        }

        &__error {
            font-size: 9px !important;
            line-height: 10px;
            margin: 5px 0 0;
            text-align: left;
            color: $errorColor;
        }

        &--pass {
            position: relative;

            input {
                padding-right: 45px;
            }

            label {
                position: absolute;
                right: 10px;
                top: 9px;
                cursor: pointer;
            }
        }
    }

    .select2 {
        &.select2-container .selection {
            .select2-selection {
                background: $mainTransparent;
                border: none;
                border-radius: 4px;
                height: 37.5px;
                font-size: 13.3333333333px;
                line-height: 24px;
                width: 100%;
                padding: 0 15px;
                margin: 0;
                outline: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .select2-selection__rendered {
                    padding: 0;
                    color: #999;
                }
            }
        }
    }

    /* width */
    ::-webkit-scrollbar {
        width: 7px;

    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(82, 79, 99, 0.2);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(82, 79, 99, 0.25);
        border-radius: 40px
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(82, 79, 99, 0.30);
    }
}
