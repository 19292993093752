//snotify

.snotify-rightTop {
    top: 20px !important;
    right: 40px !important;
    max-width: 50%;
}

.snotify {
    width: auto !important;

    .snotifyToast {
        margin: 0 0 5px !important;
        background: none;
        overflow: hidden;
    }
}

.snotifyToast__inner {
    padding: 0 !important;
    min-height: unset !important;

    .success {
        background-color: #2DC996;
    }

    .error {
        background-color: #EB5757;
    }

    .warning {
        background-color: #f9b754;
    }

    div {
        width: 100%;
        height: 100%;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 18px 20px !important;
        border-radius: 4px;

        .toast-image {
            margin-right: 18px;
        }

        .toast-message {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.snotify-rightTop .fadeIn {
    animation-name: fadeInTop !important;
}

.snotify-rightTop .fadeOut {
    animation-name: fadeOutTop !important;
}

@keyframes fadeInTop {
    0% {
        opacity: 0;
        transform-origin: 0 0;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform-origin: 0 0;
        transform: scaleY(1);
    }
}

@keyframes fadeOutTop {
    0% {
        opacity: 1;
        transform-origin: 0 0;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform-origin: 0 0;
        transform: scaleY(0);
    }
}

//snotify end
