.main-button {
    min-width: 200px;
    height: 50px;
    border-radius: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: $mainFont, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    &:hover, &:active, &:focus {
        cursor: pointer;
        outline: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    }

    &__disabled {
        border: 1px solid rgba($textColor, 0.3);
        background-color: rgba($cancelButtonBackgroundColor, 0.5);
        color: rgba($textColor, 0.7);
    }

    &__gray {
        background: #F4F5F8;
        opacity: 0.4;
        border: 1px solid rgba(82, 79, 99, 0.5);
        box-sizing: border-box;
        border-radius: 4px;
        color: #524F63;
        width: 150px;
    }

    &__green {
        border: 1px solid #48C89E;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #48C89E;
        background: transparent;
        width: 160px;
        padding: 10px;
    }

    &__save {
        border: none;
        background-color: $mainColor;
        color: white;
    }
    &__preview {
        color: #27AEDE;
        background: rgba(39, 174, 222, 0.05);
        border: 1px solid rgba(39, 174, 222, 0.5);
        text-align: left;
        img {
            margin: 0 30px 0 20px;
        }
    }

    &__verify {
        width: 252px;
    }

    &__user-data {
        height: 40px;
        margin: 0;
        border: 1px solid $secondColor !important;
        color: $secondColor !important;
        background-color: #fff !important;
    }
}

.main__button--big {
    height: 50px;
    border-radius: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: $mainFont, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    border: 1px solid $mainColor;
    background-color: $mainColor;
    color: white;

    &:hover, &:active, &:focus {
        cursor: pointer;
        outline: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    }
}

button.blue_login_button {
    span {
        font-size: 18px;
        @include media("xl") {
            font-size: 16px;
        }
    }

    border-radius: 4px;
    background-color: $mainColor;
    color: white;
    width: fit-content;
    height: 50px;
    font-family: $mainFont, sans-serif;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    border: none;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 10px 20px;
    box-shadow: 1px 1px 17px 1px #ccc;
    @include media("xl") {
        width: 180px;
        height: 40px;
        font-size: 15px;
    }
    @include media("xs") {
        width: 50px;
    }
}

.cancel {
    cursor: pointer;
    border: 1px solid #524F63;
    width: 184px;
    height: 50px;
    background-color: white;
    color: #524F63;
    margin-top: 38px;
    border-radius: 4px;

    &:hover {
        border: 1px solid #524F63 !important;
    }

    @include media("xs") {
        width: 100%;
        margin-bottom: 20px;
    }
}

.save {
    width: 184px;
    height: 50px;
    background-color: $mainColor;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid $mainColor;

    @include media("xs") {
        width: 100%;
    }
}

/*------- Text buttons -------*/
.text-color-buttons-blue {
    color: $secondColor;
    cursor: pointer;
    font-size: 16px;

    @include media(1500) {
        font-size: 14px;
    }
}

.text-color-buttons-blue-hover {
    color: $buttons_blue_hover;
}

.remove-button-custom {
    display: flex;
    margin-left: 2px;
    align-items: center;

    h1 {
        margin-bottom: 0;

        :hover {
            cursor: pointer;
        }
    }

    @include media(768) {
        right: 2em;
    }
}

.history_btn {
    border: 1px solid $secondColor;
    border-radius: 4px;
    background: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $secondColor;
    padding: 5px 15px;
    &:hover {
        color: $secondColor;
    }
    svg {
        margin-left: 5px;
    }
}

.pricing-btn {
    padding: 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    border: none;
    text-transform: uppercase;
    background: none;
    position: absolute;
    bottom: 31.8%;
    left: 7%;
    outline: none;
}

/*------- Text buttons end -------*/

/*------- Tables buttons -------*/
.table-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    margin: 5px;
    border: none;
    padding: 0;

    &:hover, &:focus, &:visited {
        background-color: transparent;
        outline: none;
    }

    svg {
        &:hover {
            outline: none;
        }
    }
}
/*------- Tables buttons end -------*/

.deleteDomain {
    button {
        min-width: 100px;
        margin-top: 15px !important;
    }
}

.actions {
    .comp_admin_action {
        .dropdown-menu {
            a {
                button {
                    font-weight: 600;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 21px;
                    color: #524F63;
                }
            }
        }
    }
}

.form-button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}

/*----- DirectoreiesPageHeader.vue start -----*/

.addOrRemoveLanguage {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-left: auto;
    color: $mainColor;
    display: block;
    border: none;
    background: transparent;
    font-style: italic;
}

/*----- DirectoreiesPageHeader.vue end -----*/

.add_dpo_button {
    button {
        width: 165px;
    }
}

/*-------------- Responsive ----------------*/

@include media(1200) {
    .save,
    .cancel,
    .main-button,
    .main-button--big,
    button.blue_login_button {
        height: 40px;
    }
}
