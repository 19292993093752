.mainFilter {
    @include flex;
    @include justify-content(space-between);
    @include align-items(center);
    margin-bottom: 25px;

    &-action {
        //max-width: 60%;
        width: 100%;
        @include flex;
        @include align-items(center);
        @include justify-content(space-between);

        .filter-add {
            //margin-right: 20px;

            .main-button {
                min-width: 120px;
                height: 40px;

                &.main-button__save {
                    &:hover {
                        color: #fff;
                    }
                }
                img {
                    margin-right: 10px;
                }
            }
            .info_icon {
                svg {
                    width: 18px;
                    height: 18px;
                    margin: 0 10px 2px 0;
                    path {
                        fill: #fff;
                    }
                }
            }
        }

        .filter-select {
            @include flex;
            @include align-items(center);

            &__all {
                @include flex;
                @include align-items(center);
                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
                border-radius: 4px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                label {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #524F63;
                    margin-bottom: 0;
                    margin-left: 10px;

                    &.container1 .checkmark {
                        top: -9px;
                    }
                }
            }

            &__dropdown {
                .selected_btn {
                    button {
                        color: rgba(64, 146, 196, 0.4);;
                        padding: 10px 15px;
                        border-radius: 4px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        border: none;
                        box-shadow: none;
                        color: rgba(82, 79, 99, 0.8);
                        background: #F2F6FC;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        height: 40px;
                        display: flex;

                        &:active, &:focus{
                            background: #F2F6FC !important;
                            box-shadow: 0 2px 4px rgba(64, 146, 196, 0.16);
                        }
                    }
                }

                .dropdown-toggle {
                    &::before {
                        content: '';
                        width: 1px;
                        height: 50%;
                        background: rgba(64, 146, 196, 0.5);
                        position: absolute;
                        right: 32.5px;
                    }

                    &::after {
                        margin-left: 15px;
                        border-top: 0.4em solid #27AEDE;
                        border-left: 0.4em solid transparent;
                        border-right: 0.4em solid transparent;
                        margin-top: 8px;
                    }
                }
            }
        }

        .filter-button {
            margin: 0 20px;
            display:flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            height: 40px;
            padding: 0 20px;
            background: rgba(39, 174, 222, 0.05);
            border: 1px solid rgba(39, 174, 222, 0.5);

            .headSlide {
                transition: all 2s;
                //max-width: 0;
                overflow: hidden;

                p {
                    margin-bottom: 0;
                    padding-left: 10px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    white-space: nowrap;
                    color: $mainColor;
                }
            }

            &:hover{
                border: 1px solid rgba(39, 174, 222, 0.7);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
                //.headSlide{
                //    width: auto !important;
                //    max-width: 200px !important;
                //    text-align: center;
                //}
            }

            &:hover, &:active, &:focus {
                cursor: pointer;
                outline: none;
            }
        }

        .filter-search {
            position: relative;
            display: flex;
            align-items: center;
            margin-right: auto;
            .search__icon {
                position: absolute;
                left: 15px;
                border-right: 1px solid rgba(64, 146, 196, 0.5);
                padding-right: 10px
            }

            .search__fields {
                padding: 5px 5px 5px 55px;
                width: 100%;
                min-width: 170px;
                background: #FFFFFF;
                border: 1px solid #4092C4;
                border-radius: 4px;
                height: 40px;

                &::placeholder {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(82, 79, 99, 0.7);
                }

                &:focus {
                    outline: none;
                    box-shadow: 0 2px 4px rgba(64, 146, 196, 0.16);
                }
            }
        }
    }

    &-filter {
        @include flex;
        @include align-items(center);

        .filter-select-area {
            position: relative;
            margin-right: 20px;

            .filter-icon {
                position: absolute;
                right: 0;
                cursor: pointer;
            }

            .vue-treeselect.main-filter-tree {
                width: 100%;

                .vue-treeselect__control {
                    &-arrow-container {
                        .vue-treeselect__control-arrow.vue-treeselect__control-arrow--rotated {
                            transform: rotateZ(0deg);
                        }
                    }
                }

                &.vue-treeselect--focused:not(.vue-treeselect--open) {
                    .vue-treeselect__control {
                        box-shadow: none !important;
                    }
                }

                &.vue-treeselect--open {

                    &.vue-treeselect--open-above {
                        position: relative;

                        .vue-treeselect__menu-container {
                            &:after {
                                content: '';
                                border-top: 7px solid #fff;
                                border-left: 7px solid transparent;
                                border-right: 7px solid transparent;
                                width: 7px;
                                height: 7px;
                                position: absolute;
                                bottom: -8px;
                                right: 30px;
                            }
                        }
                    }

                    &.vue-treeselect--open-below {
                        position: relative;

                        .vue-treeselect__menu-container {
                            &:after {
                                content: "";
                                border-bottom: 6px solid #d4eff8;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                width: 6px;
                                height: 6px;
                                position: absolute;
                                top: -7px;
                                right: 13px;
                            }
                            &:before {
                                display: none;
                                /*content: "";
                                border-bottom: 6px solid #fff;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                width: 6px;
                                height: 6px;
                                position: absolute;
                                top: -7px;
                                right: 13px;*/
                            }
                        }
                    }
                }

                .vue-treeselect__multi-value-item-container {
                    display: none;
                }

                & > div.vue-treeselect__control {
                    padding: 0;
                    border: none;
                    background: transparent;
                }

                .vue-treeselect__menu-container {
                    margin-top: 15px;
                    right: -10px;
                    left: unset;

                    .vue-treeselect__menu {
                        border: none;
                        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
                        padding: 0;
                        border-radius: 0;

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            //background: #27AEDE;
                        }

                        .vue-treeselect__option {
                            padding: 10px !important;
                            padding-left: 15px;

                            &--highlight {
                                background: $mainOpacity;
                            }

                            &--selected {
                                background: $mainOpacity;
                            }

                            &-arrow {
                                color: $secondColor;
                            }
                        }

                        .vue-treeselect__list-item {
                            &.vue-treeselect__indent-level-0 {
                                & > .vue-treeselect__option {

                                    & > .vue-treeselect__option-arrow-container {
                                        svg {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .vue-treeselect__label {
                                margin-bottom: 0;
                                font-weight: normal;
                                font-style: normal;
                                font-size: 14px;
                                line-height: 21px;
                                color: $textColor;
                            }

                            &.vue-treeselect__indent-level-1 {
                                .vue-treeselect__list {
                                    background: $mainOpacity;
                                }
                            }

                            &.vue-treeselect__indent-level-2 {
                                .vue-treeselect__label {
                                    font-weight: normal;
                                }

                            }

                            &.vue-treeselect__indent-level-0 {
                                padding: 0;

                                & > .vue-treeselect__option {
                                    //background: $mainOpacity;

                                    /*.vue-treeselect__label {
                                        color: $secondColor;
                                        margin: 0;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 21px;
                                    }*/
                                }
                            }
                        }

                        .vue-treeselect__checkbox {
                            width: 15px;
                            height: 15px;

                            .vue-treeselect__check-mark {
                                width: 10px;
                                height: 10px;
                                background-size: 10px 10px;
                            }

                            .vue-treeselect__check-mark, .vue-treeselect__minus-mark {
                                left: 2px;
                                top: 2px;
                            }

                            &--unchecked {
                                border-color: rgba(82, 79, 99, 0.3);
                                background: transparent;
                                border-width: 1.5px;
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }

                .vue-treeselect__label-container {
                    @include flex;
                    @include align-items(center);
                    @include justify-content(space-between);
                    flex-direction: row-reverse;
                }

                &.vue-treeselect--focused {
                    box-shadow: none !important;
                }
            }

            .filter-main {
                display: flex;
                background: #F2F6FC;
                box-shadow: none;
                border-radius: 4px;
                align-items: center;
                width: 115px;
                height: 40px;
                cursor: pointer;
                position: relative;
                padding: 10px;
                border: 1px solid #F2F6FC;

                p {
                    margin-bottom: 0;
                    margin-left: 5px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: rgba(82, 79, 99, 0.8);
                }

                &.active{
                    border-color: rgba(39, 174, 222, 0.7);
                    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
                }

                &::after{
                    content: '';
                    width: 10px;
                    height: 6px;
                    border-top: 6px solid $mainColor;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    position: absolute;
                    right: 10px;
                    top: 17px;
                }
            }

            .selected {
                display: flex;
                align-items: center;

                & > div {
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
                    border-radius: 50px;
                    padding: 0 20px;
                    max-width: 150px;
                    width: 100%;
                    height: 30px;
                    margin: 0 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        margin-bottom: 0;
                        margin-right: 5px;
                    }
                    &:hover{
                        cursor: pointer;
                        border: 1px solid rgba(39, 174, 222, 0.7);
                        box-sizing: border-box;
                        box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1)
                    }
                }

            }

            .filter-area {
                position: absolute;
                z-index: 2000;
                left: 10%;
                top: 60px;
                @include responsive-size(700px, 1920, 1500, width);
                //height: 220px;
                height: auto;
                background: #fff;
                box-shadow: 0px 2px 9px rgb(0 0 0 / 15%);

                &__info {
                    background: rgba(39, 174, 222, 0.3);
                    height: 47px;
                   padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        top: -7px;
                        left: 20px;
                        width: 7px;
                        height: 7px;
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        border-bottom: 7px solid rgba(39, 174, 222, 0.3);
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: -7px;
                        left: 20px;
                        width: 7px;
                        height: 7px;
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        border-bottom: 7px solid #fff;
                    }

                    p {
                        color: rgba(82, 79, 99, 0.85);
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 0;
                    }

                    .clearAll {
                        margin-bottom: 0;
                        cursor: pointer;
                        color: #4092C4;

                    }
                }

                &__select {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 30px 20px;
                    gap: 20px;

                    .filter-record.vue-treeselect.main-filter-tree {
                        width: 48.4% !important;
                        height: 40px;

                        .vue-treeselect__control {
                            background: #F2F6FC;
                            border-radius: 4px;
                            height: 100%;
                            .vue-treeselect__placeholder, .vue-treeselect__single-value{
                                line-height: 37px !important;
                                padding-left: 15px;
                                padding-right: 10px;
                                color: #524F63;
                                font-size: 14px;
                            }

                            .vue-treeselect__control-arrow-container {
                                width: 30px;

                                .vue-treeselect__control-arrow {
                                    color: $secondColor;
                                }
                            }
                        }

                        .vue-treeselect__menu-container {
                            margin: 0;
                            left: 0;

                            &::after {
                                display: none;

                            }

                            .vue-treeselect__label-container {
                                flex-direction: inherit;
                            }
                        }
                    }
                }
            }

        }

        .filter-per-page {
            @include flex;
            @include align-items(center);
            margin-left: 25px;

            .per-page {
                font-size: 18px;
                line-height: 24px;
                color: #524F63;
                font-weight: normal;
                margin-right: 10px;
                margin-bottom: 0;
                @include media(1600) {
                    font-size: 13px;
                }
            }

        }
    }

    .mainFilter-action.mobile-search {
        display: none;
        min-width: 100%;

        .filter-search {
            width: 100%;
            margin-bottom: 20px;
        }
    }


    @include media(768) {
        flex-wrap: wrap;
        .mainFilter-action {
            .filter-search {
                display: none;
            }

            &.mobile-search {
                .filter-search, & {
                    display: block;
                }
            }
        }
    }
}
