.select-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .filter-select {
        max-width: 200px;
        height: 50px;
        font-size: 18px;
        line-height: 27px;
        border: none;
        background: none;
        color: black;
        font-style: normal;
        font-weight: normal;
        position: relative;
        margin-right: 5px;
    }

    .v-select {
        & > .v-select-bar {
            & > .v-select-btn-dd:before {
                border-color: $secondColor transparent transparent transparent;
                border-width: 8px 6px 0 6px;
            }
        }

        &:not(.-top).-opened {
            box-shadow: none;
        }

        .v-select-inp {
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #524F63;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #524F63;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #524F63;
            }
        }

        .v-select-list {
            width: 300px !important;
            max-height: 200px;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.35);
            border: none;
            border-radius: unset;
            padding: 0;
            overflow: scroll;

            &:before {
                position: absolute;
                right: 2px;
                top: 0px;
                content: '';
                width: 15px;
                height: 15px;
                background-color: white;
                transform: rotate(135deg);
                transform-origin: 0 0;
                box-shadow: -3px 3px 3px -3px rgba(0, 0, 0, 0.35);
            }

            &::-webkit-scrollbar {
                height: 8px;
                width: 8px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #B6B6B8;
                border-radius: 5px
            }

            .v-select-option {
                padding: 5px 15px;
                height: 36px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #524F63;
                width: 300px;
                white-space: nowrap;
                text-align: left;

                &.-marked, &:hover {
                    border-left: 5px solid $secondColor;
                    padding: 5px 15px 5px 10px;
                    background: none;
                    color: #524F63;
                }
            }

        }


    }

    //&:not(.-top)

}

.custom_multiselect {
    .multiselect__tag {
        background-color: $mainColor;
        box-shadow: 0px 2px 4px $mainOpacity;
        border-radius: 50px;
        height: 31px;
        padding-left: 21px;
        margin: 5px;
        padding-left: 21px;
        font-size: 17px;
        display: flex;
        align-items: center;
    }

    .multiselect__tag-icon {
        &:after {
            color: #fff;
        }

        &:focus, &:hover {
            background-color: $mainColor;
        }
    }

    .multiselect__select {
        display: none;
    }

    .multiselect__tags {
        padding: 8px 7px;
        background: #F2F4FE;
        border-radius: 2px;
        font-size: 20px;
        line-height: 30px;
        color: #524F63;
        border: none;
    }

    input.multiselect__input {
        margin: 0;
    }

    .multiselect__tag-icon {
        padding-right: 9px;
        top: 3px;
    }

    span.multiselect__placeholder {
        margin-bottom: 0;
    }

    .multiselect__tags-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .multiselect__content-wrapper {
        height: 179px;
        margin-top: 9px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
    }

    /* width */
    .multiselect__content-wrapper::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    .multiselect__content-wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #fff;
        border-radius: 10px;
    }

    /* Handle */
    .multiselect__content-wrapper::-webkit-scrollbar-thumb {
        width: 5px;
        background: #B6B6B8;
        border-radius: 6px;
    }

    /* Handle on hover */
    .multiselect__content-wrapper::-webkit-scrollbar-thumb:hover {
        width: 5px;
        background: #B6B6B8;
        border-radius: 6px;
    }

    .multiselect__option--disabled {
        font-size: 16px;
        line-height: 24px;
        color: #524F63 !important;
        background-color: #fff !important;
        font-weight: 600;
    }

    .multiselect__option--highlight {
        background: #fff;
        border-left: 5px solid $secondColor !important;
        color: #524F63;
        font-size: 16px;
    }

    .multiselect__option {
        border-left: 5px solid #FFFFFF;
    }

    span.multiselect__option.multiselect__option--highlight:after {
        background-color: #fff;
        color: #524F63;
    }
}

/*----- vue tree select start ------*/
.tree-select {
    border-radius: 4px;

    .vue-treeselect__control {
        background: #F2F6FC;
        border: none;
        border-radius: 4px;
        height: 50px !important;
        padding: 0 10px !important;
        display: table !important;
        width: 100% !important;

        .vue-treeselect__value-container {
            cursor: pointer;
        }

        input {
            background: transparent !important;
            border: none !important;
        }

        .vue-treeselect__control-arrow {
            width: 12px !important;
            height: 11px !important;
            color: $secondColor;

            &.vue-treeselect__control-arrow--rotated {
                transform: rotateZ(0deg) !important;
            }
        }

        @include media(1200) {
            height: 40px !important;

            .vue-treeselect__control-arrow {
                width: 9px !important;
                height: 9px !important;
            }
        }
    }

    .vue-treeselect--open-above .vue-treeselect__menu, .vue-treeselect--open-below .vue-treeselect__menu {
        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
    }

    .vue-treeselect__placeholder, .vue-treeselect__single-value {
        line-height: 25px;
    }

    .vue-treeselect__multi-value-item {
        background: #27AEDE;
        box-shadow: 0 2px 4px $mainOpacity;
        border-radius: 50px;
        color: #Fff;
        padding: 4px 8px;
        font-size: 16px;
        line-height: 24px;

        .vue-treeselect__multi-value-label {
            max-width: 150px;
            white-space: pre;
            overflow: hidden;
        }

        .vue-treeselect__value-remove {
            color: #fff;
            padding-left: 10px;
            line-height: 0;
            border-left: none !important;
        }

        @include media(1200) {
            padding: 2px 8px;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .vue-treeselect__menu {
        background: #FFFFFF;
        border: none;
        padding: 0;

        .vue-treeselect__option {
            padding: 4px 15px;
            margin: 6px 0;
            cursor: pointer;
            text-align: left;
            border-left: 6px solid transparent;
            &:hover {
                background: rgba(64, 146, 196, 0.1);
                border-left: 6px solid var(--second-color);
                color: var(--second-color);
                .vue-treeselect__checkbox--unchecked {
                    border: 2px solid rgba(112, 111, 117, 0.8);
                }
                .vue-treeselect__checkbox--checked {
                    background: #1F9AC7 url(/images/check.svg) no-repeat center;
                }
            }

            .vue-treeselect__checkbox {
                border-radius: 3px;
                width: 20px;
                height: 20px;

                .vue-treeselect__check-mark, .vue-treeselect__minus-mark {
                    opacity: 0;
                }
            }

            .vue-treeselect__checkbox--indeterminate {
                background: #27AEDE url(/images/check_minus.svg) no-repeat center;
                border: none;
                transition: unset;
            }

            .vue-treeselect__checkbox--unchecked {
                background: #FFFFFF;
                border: 2px solid rgba(112, 111, 117, 0.6);
            }

            .vue-treeselect__checkbox--checked {
                background: #27AEDE url(/images/check.svg) no-repeat center;
                border: none;
                transition: unset;
            }
        }
        .vue-treeselect__option--selected {
            background: rgba(64, 146, 196, 0.1);
            border-left: 6px solid var(--second-color);
            color: var(--second-color);
        }

        .vue-treeselect__list-item .vue-treeselect__list {
            background: $mainOpacity;

            .vue-treeselect__label {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #524F63;
            }
        }
    }

    .vue-treeselect__option--highlight {
        background: transparent;
    }

    .vue-treeselect__label {
        margin-bottom: 0;
    }

    .vue-treeselect__option {
        padding-left: 5px !important;
    }

    .vue-treeselect__label-container {
        display: flex;
        align-items: center;
        color: inherit;
        justify-content: space-between;

        .vue-treeselect__checkbox-container {
            order: 2;
        }
    }

    .vue-treeselect__option-arrow-container, .vue-treeselect__control-arrow-container {
        svg {
            path {
                fill: $secondColor;
            }
        }
    }
}

.disabled.IZ-select .IZ-select__input-wrap .IZ-select__input input, .disabled .vue-treeselect__control {
    background-color: #F4F5F8!important;
    cursor: not-allowed;
}
.disabled .vue-treeselect__control-arrow {
    opacity: unset!important;
}

.disabled .vue-treeselect__control .vue-treeselect__value-container, .disabled.main-select.IZ-select .arrow-black {
    cursor: not-allowed;
}

.vue-treeselect {
    border-radius: 4px;

    &.vue-treeselect--focused {
        box-shadow: none !important;
        background-color: unset;
        outline: 1px solid rgba(64, 146, 196, 0.5) !important;
        .vue-treeselect__control {
            box-shadow: unset!important;
        }
    }

    &.vue-treeselect--focused.errorBorder {
        outline: none!important;
        box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 8%) !important;
    }

    &.errorBorder {
        border: 1px solid rgba(255, 27, 27, 0.6) !important;

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.08) !important;
            outline: none!important;
        }

        .vue-treeselect__input {
            &:focus {
                box-shadow: none!important;
            }
        }
    }

    .vue-treeselect__input {
        &:focus {
            outline: none!important;
        }
    }

    &.treeselect-main-filter {
        .vue-treeselect__control {
            background: #F2F6FC;
            border-radius: 4px;
            border: none;
            align-items: center;
            display: flex;

            .vue-treeselect__multi-value {
                height: 40px;
                display: flex;
                align-items: center;

                .vue-treeselect__placeholder {
                    align-items: center;
                    display: flex;

                    &.vue-treeselect-helper-hide {
                        display: none;
                    }
                }
            }
        }

        .vue-treeselect__menu-container {

            .vue-treeselect__menu {
                padding: 0;
                border: none;
                box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
                border-radius: 0;

                .vue-treeselect__list-item {
                    &.vue-treeselect__indent-level-0 {
                        & > .vue-treeselect__option {
                            background: $mainOpacity;

                            & > .vue-treeselect__option-arrow-container {
                                position: relative;

                                &::before {
                                    content: '';
                                    width: 20px;
                                    height: 20px;
                                    background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.8907 21.3288L15.0636 15.2019C16.5499 13.6949 17.4707 11.6174 17.4707 9.3261C17.4707 4.73485 13.7765 1 9.23534 1C4.69414 1 1 4.73485 1 9.3261C1 13.9173 4.69414 17.6522 9.23534 17.6522C11.2287 17.6522 13.0581 16.9318 14.484 15.7364L20.3252 21.8783C20.4021 21.9596 20.5048 22 20.608 22C20.706 22 20.8036 21.9631 20.8797 21.8894C21.0358 21.7375 21.0405 21.4866 20.8907 21.3288ZM9.23534 16.8592C5.12669 16.8592 1.78432 13.48 1.78432 9.3261C1.78432 5.17217 5.12669 1.79296 9.23534 1.79296C13.344 1.79296 16.6864 5.17217 16.6864 9.3261C16.6864 13.48 13.3436 16.8592 9.23534 16.8592Z' stroke='%234092C4' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                                    position: absolute;
                                    background-size: 20px 20px;
                                    left: 10px;
                                    top: 0;
                                }

                                svg {
                                    display: none;
                                }
                            }

                            & > .vue-treeselect__label-container {
                                justify-content: flex-start;

                                .vue-treeselect__label {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }

                    &.vue-treeselect__indent-level-1 {
                        & > .vue-treeselect__option > .vue-treeselect__label-container > .vue-treeselect__label {
                            font-weight: bold !important;
                        }

                        .vue-treeselect__list {
                            background: $mainOpacity;
                        }
                    }

                    .vue-treeselect__option {
                        padding: 10px;

                        &--highlight {
                            background: transparent;
                        }

                        &-arrow {
                            color: $secondColor;
                        }

                        .vue-treeselect__label-container {
                            display: flex;
                            flex-direction: row-reverse;
                            justify-content: space-between;
                            align-items: center;
                        }

                        .vue-treeselect__label {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            color: #524F63;
                            margin-bottom: 0;
                        }
                    }

                    .vue-treeselect__list {
                    }
                }

                .vue-treeselect__checkbox {
                    width: 15px;
                    height: 15px;

                    .vue-treeselect__check-mark {
                        width: 10px;
                        height: 10px;
                        background-size: 10px 10px;
                    }

                    .vue-treeselect__check-mark, .vue-treeselect__minus-mark {
                        left: 2px;
                        top: 2px;
                    }

                    &--unchecked {
                        border-color: rgba(82, 79, 99, 0.3);
                        background: transparent;
                        border-width: 1.5px;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }

    }
}

/*----- vue tree select end -----*/

/*------ select2 ------*/
.select2-container {
    &--default {

        //multiselect with checkboxes start
        .multiple-select {
            .select2-results__option {
                color: $labelTextColor;

                &:hover {
                    background-color: white;
                    color: $labelTextColor;
                }

                &:before {
                    content: "";
                    display: inline-block;
                    position: relative;
                    height: 20px;
                    width: 20px;
                    border: 1px solid #e9e9e9;
                    border-radius: 4px;
                    background-color: #fff;
                    margin-right: 20px;
                    vertical-align: middle;
                }

                &--highlighted, &[aria-selected="true"] {
                    border: none !important;
                    background-color: white;
                    color: $labelTextColor;
                }

                &[aria-selected=true] {
                    background-color: white;
                    color: $labelTextColor;

                    &:before {
                        font-family: fontAwesome;
                        content: "\f00c";
                        color: #fff;
                        background-color: $mainColor;
                        border: 0;
                        display: inline-flex !important;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                }
            }
        }

        .select2-selection--multiple {
            padding: 3px;
            display: flex;
            align-items: center;

            .select2-selection__choice {
                background: $mainColor;
                box-shadow: 0px 2px 4px rgba(39, 174, 222, 0.3);
                border-radius: 50px;
                color: white;
                position: relative;
                padding: 0 20px 3px 10px !important;
                margin-top: 0px;
                border: none;

                &__remove {
                    position: absolute;
                    right: 5px;
                    color: white;

                    &:hover {
                        color: white;
                    }
                }
            }
        }

        &.select2-container--focus {
            .select2-selection--multiple {
                border: none;
            }
        }

        //multiselect with checkboxes end


        //single select

        .select2-results__option {
            color: $labelTextColor;

            &--highlighted, &[aria-selected="true"] {
                border-left: 5px solid var(--main-color) !important;
                background-color: white !important;
                color: $labelTextColor !important;
            }


            &:hover, &:focus, &:active {
                background-color: white;
                color: $labelTextColor;
            }
        }

        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: $mainColor transparent transparent transparent;
                    border-style: solid;
                    border-width: 8px 6px 0 6px;
                    height: 0;
                    left: calc(50% - 8px);
                    position: absolute;
                    top: 50%;
                    width: 0;
                }
            }

            .select2-selection__rendered {
                font-weight: normal;
            }

            &[aria-expanded="true"] {
                .select2-selection__arrow {
                    b {
                        border-color: transparent transparent $mainColor transparent;
                        border-style: solid;
                        border-width: 0px 6px 8px 6px;
                    }
                }
            }
        }

        //end single select


        .select2-dropdown--below {
            border: none;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        }
    }

    .select2-selection--single, .select2-selection--multiple {
        width: 100%;
        border: none;
        background-color: $mainTransparent;
        min-height: 50px;
        height: auto;
        padding-bottom: 13px;
        padding-top: 13px;
        border-radius: 4px;
        outline: 0px none rgb(73, 80, 87);
    }

    .select2-selection__arrow {
        top: 12px !important;
    }

    .select2-selection__rendered {
        line-height: unset !important;
    }

    &.select2 {
        width: 100% !important;
    }
}

.select2-search.select2-search--inline {
    input {
        height: auto;
        margin: 0 !important;
    }
}

/*------ select2 end ------*/

/*------- Vue Cool Select -------*/
.main-select.IZ-select {
    border-radius: 4px;
    font-family: $mainFont, sans-serif;

    &.location {
        .IZ-select__menu.IZ-select__menu--at-top {
            display: none !important;
        }
    }

    &.errorBorder {
        border: 1px solid rgba(255, 27, 27, 0.6) !important;

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.08) !important;
        }

        .IZ-select__input-wrap .IZ-select__input input {
            height: 48px !important;

            @include media(1200) {
                height: 38px !important;
            }
        }
    }

    .arrow-black {
        border-style: solid;
        border-color: #4092C4 transparent transparent transparent;
        border-width: 8px 6px 0 6px;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 20px;

        @include media(1200) {
            border-width: 7px 5px 0 5px;
            top: 16px;
        }
    }

    &.successor-select{
        .IZ-select__input--has-menu{
            .selected_successor{
                opacity: 0.4 !important;
            }
        }

        .selected_successor {
            position: absolute;
            overflow: hidden;
            max-width: 92%;
            input {
                color: transparent;
                font-size: 1em;
            }
        }
    }


    .IZ-select__menu {
        position: absolute;
        z-index: 10;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
        overflow-y: auto;
        //transform: matrix(1, 0, 0, -1, 0, 0);
        &-items {
            .IZ-select__no-data {
                padding: 5px 10px;
            }

            .IZ-select__item {
                padding: 4px 15px;
                margin: 6px 0;
                cursor: pointer;
                text-align: left;
                border-left: 6px solid transparent;

                &:hover, &--selected {
                    padding: 4px 15px;
                    background: rgba(64, 146, 196, 0.1);
                    border-left: 6px solid $secondColor;
                    color: $secondColor;
                }
            }
        }

        &::-webkit-scrollbar {
            height: 8px;
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #B6B6B8;
            border-radius: 5px
        }

        &-items {
            div:first-child, div:last-child {
                //height: 0 !important;
            }
        }

        &:focus {
            outline: none;
        }
    }

    .IZ-select__input-wrap {
        .IZ-select__input {
            position: relative;
            border-radius: 4px;

            input {
                width: 100%;
                height: 50px;
                margin: 0 !important;
                color: #495057;
                padding: 5px 35px 5px 15px !important;
                background: $mainTransparent;
                border: none;
                border-radius: 4px;
                font-size: 16px;

                &:not(:disabled){
                    cursor: pointer;
                }

                &::placeholder {
                    opacity: 0.4;
                    font-size: 16px;
                }

                @include media(1500) {
                    font-size: 14px;

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                @include media(1200) {
                    height: 40px !important;
                }
            }
        }
    }
}

/*------- Vue Cool Select End -------*/

.multi-select {
    min-height: 50px;
    height: auto;
}

.v-select {
    cursor: pointer;
    border-radius: 4px;

    .vs__dropdown-toggle {
        width: 100%;
        height: 50px;
        margin: 0 !important;
        padding: 10px;
        background: $mainTransparent;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        input::placeholder {
            color: #495057;
            opacity: 0.4;
            font-size: 16px;
        }

        svg {
            path {
                fill: #4092c4;
            }
        }
    }

    ul {
        list-style-type: none !important;
        padding: 0 !important;

        li {
            font-weight: 600;
            line-height: 27px;
            color: #6d6e71;
            background: #fff !important;

            &:hover {
                padding: 4px 15px;
                background: rgba(64, 146, 196, 0.1);
                border-left: 6px solid $secondColor;
                color: $secondColor;
            }

            &::before, &::after {
                display: none;
            }
        }
    }
}

/*------- Select With icon start ------*/

.phone-code-select {
    .IZ-select__input {
        display: flex;
        align-items: center;
        background-color: $mainTransparent;
        height: 50px;
        width: 100%;

        span {
            display: inline-block;
        }

        .removeSelected{
            right: 33px;
            position: absolute;
            top: 12px;
            cursor: pointer;
        }

        div {
            white-space: nowrap;
            padding-left: 15px;

            & + input {
                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: transparent;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: transparent;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: transparent;
                }
            }
        }

        @include mediaDistance(1450, 1200) {
            & > div {
                span:not(:first-child) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 50px;
                    margin-bottom: -7px;
                }
            }
        }

        @include media(1200) {
            height: 40px;
        }
    }

    .IZ-select__item, .IZ-select__input {
        //height: 35px;

        div > span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            //height: 35px;
            font-weight: 400;
            margin-right: 8px;
        }
    }
}

/*------- Select With icon end ------*/
