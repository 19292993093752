.overview {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    width: 100%;

    &__item {
        background: #FFFFFF;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% / 3);

        h4 {
            font-weight: 600;
            color: #4092C4;
            @include responsive-size(20px, 1920, 1300, font-size);
            @include responsive-size(30px, 1920, 1300, line-height);

            .loactionsSvg, .prefilledRecordSvg, .myRecordSvg, .partnerSvg {
                width: 46px;
                height: 46px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                display: inline-block;
                background: linear-gradient(227.43deg, #82E3FF -24.58%, #27AEDE 114.16%);
            }

            .myRecordSvg {
                background: linear-gradient(227.43deg, #82E3FF -24.58%, #27AEDE 114.16%);
            }

            .prefilledRecordSvg {
                background: linear-gradient(223.32deg, #8ADAD5 -25.88%, #3ED7C9 116.27%);
            }

            .loactionsSvg {
                background: linear-gradient(90.44deg, #7F7FD5 -4.56%, #86A8E7 51.55%, #91EAE4 107.66%);
            }
        }

        ul {
            list-style-type: none;
            padding: 0px 15px;
            margin-top: 35px;

            li {
                font-weight: normal;
                color: #524F63;
                position: relative;
                @include responsive-size(16px, 1920, 1300, font-size);
                @include responsive-size(19px, 1920, 1300, line-height);

                span {
                    font-style: normal;
                    font-weight: 600;
                    color: #4092C4;
                    @include responsive-size(18px, 1920, 1300, font-size);
                    @include responsive-size(27px, 1920, 1300, line-height);
                }

                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    position: absolute;
                    left: -15px;
                    top: 50%;
                    transform: translate(0, -40%);
                    background: #D7E3E9;
                }

                &.myrecord::before {
                    background: linear-gradient(227.43deg, #82E3FF -24.58%, #27AEDE 114.16%);
                }

                &.prefilledrecord::before {
                    background: linear-gradient(223.32deg, #8ADAD5 -25.88%, #3ED7C9 116.27%);
                }

                &.locations::before {
                    background: linear-gradient(90.44deg, #7F7FD5 -4.56%, #86A8E7 51.55%, #91EAE4 107.66%);
                }
            }
        }

        .cdc-text h1 {
            font-weight: 600;
            font-size: 25px;
            line-height: 22px;
            text-align: center;
            letter-spacing: -0.04em;
            color: rgba(82, 79, 99, 0.8);
            margin-bottom: 0;
        }

    }

}
