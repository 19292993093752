.profile {
    @include flex();
    height: 100%;

    &-menu {
        width: 18%;
        //height: 100%;
        background: rgba(64, 146, 196, 0.1);
        margin: 24px 0 24px 24px;

        &__title {
            font-family: $mainFont, sans-serif;
            background: rgba(64, 146, 196, 0.1);
            padding: 15px 20px;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            color: #524F63;
            opacity: 0.9;
            margin-bottom: 0;

            @include media(1400) {
                font-size: 14px;
            }
            @include media(1300) {
                font-size: 13px;
            }
        }

        .settings {
            width: calc(100% - 40px);
            padding: 32px 0;
            margin: 0 auto;
            position: relative;
            ul {
                padding: 0;
            }

            &__title {
                font-family: $mainFont, sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
                color: #524F63;
                opacity: 0.5;
                margin-bottom: 34px;
                position: relative;

                &::before {
                    content: '';
                    width: 20px;
                    height: 2px;
                    background: rgba(64, 146, 196, 0.4);
                    position: absolute;
                    left: 0;
                    bottom: -16px;
                }

                @include media(1400) {
                    font-size: 14px;
                }
                @include media(1300) {
                    font-size: 13px;
                }
            }

            &-menu__item {
                font-family: $mainFont, sans-serif;
                font-size: 14px;
                line-height: 21px;
                color: $textColor !important;
                display: block;
                margin: 12px 0;
                cursor: pointer;

                &:hover, &.active {
                    color: #27AEDE;
                }

                @include media(1400) {
                    font-size: 12px;
                }
                @include media(1300) {
                    font-size: 11px;
                }
                a {
                    color: unset;
                }
            }

            &:first-of-type {
                &::after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: rgba(64, 146, 196, 0.25);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
        }

        @include media(1600) {
            width: 25%;
        }
        @include media(768) {
            width: 30%;
        }
    }

    &-content {
        width: 82%;
        padding: 5% 4%;

        @include media(1600) {
            width: 75%;
            padding: 3%;
        }
        @include media(768) {
            width: 70%;
        }
    }
}
