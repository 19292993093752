.main.newStyle {
    background: #F2F6FC;
    @include flex;

    *:disabled{
        cursor: not-allowed !important;
        background: #62B9D8;
        &:hover {
            box-shadow: unset;
        }
    }

    .content {
        width: calc(100% - #{$sidebarWidth});

        .main-content {
            width: 100%;
            box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1) inset;
            padding: 24px 24px 0;
            height: calc(100% - 100px);
            font-family: $mainFont, sans-serif;

            .parent_content {
                height: 90%;

                .content_body {
                    min-height: calc(100% - 145px);

                    &.content_body--small {
                        min-height: 100%;
                    }

                    &.content_body--big {
                        min-height: calc(100% - 250px);
                        @include media(1500) {
                            min-height: calc(100% - 265px);
                        }
                        @include media(1200) {
                            min-height: calc(100% - 300px);
                        }
                    }

                    &.with_pagination {
                        min-height: calc(100% - 270px);
                    }

                    @include media(1500) {
                        min-height: calc(100% - 130px);
                    }
                }
            }
        }

        //@include media(1200) {
        //    width: calc(100% - #{$mobSidebarWidth});
        //}

        @include media(1200) {
            width: 100%;
            min-height: 100vh;
        }
    }

    .h_95 {
        height: 95% !important;
    }

    .w-auto {
        width: auto;
    }
}


//.parent_content div:last-child{
//    animation: enter .8s;
//}

.fade-enter-active{
    animation: enter .8s;
}

.fade-leave-active {
    animation: leave .8s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0
}

@keyframes enter {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes leave {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
