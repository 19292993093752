/*------- Tooltip button -------*/
.tooltip-button {
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    font-size: 11px;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: unset !important;

    @include media(1200) {
        width: 16px;
        height: 16px;

        span {
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}

/*------- Tooltip button end -------*/

/*
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
    position: relative;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    left: 50%;
    bottom: calc(100% + 5px);
    pointer-events: none;
    transition: 0.2s;
    will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
    content: attr(data-tooltip);
    padding: 10px 18px;
    min-width: 50px;
    max-width: 300px;
    width: max-content;
    width: -moz-max-content;
    border-radius: 6px;
    font-size: 14px;
    background-color: $secondColor;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
    white-space: pre-wrap;
    transform: translate(-50%, -5px) scale(0.5);
    z-index: 100;
}

/* Tooltip arrow */
[data-tooltip]:after {
    content: " ";
    border-style: solid;
    border-width: 5px 5px 0px 5px;
    border-color: $secondColor transparent transparent transparent;
    transition-duration: 0s;
    /* If the mouse leaves the element,
                              the transition effects for the
                              tooltip arrow are "turned off" */
    transform-origin: top;
    /* Orientation setting for the
                              slide-down effect */
    transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
}

/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
    transition-delay: 0.3s;
    transform: translate(-50%, -5px) scale(1);
}

/* Slide down effect only on mouseenter (NOT on mouseleave) */
[data-tooltip]:hover:after {
    transition-delay: 0.3s; /* Starting after the grow effect */
    transition-duration: 0.2s;
    transform: translateX(-50%) scaleY(1);
}

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
    left: auto;
    right: calc(100% + 5px);
    bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
    transform: translate(-5px, 50%) scale(0.5);
}

[data-tooltip-location="left"]:hover:before {
    transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
    border-width: 5px 0px 5px 5px;
    border-color: transparent transparent transparent $secondColor;
    transform-origin: left;
    transform: translateY(50%) scaleX(0);
}

[data-tooltip-location="left"]:hover:after {
    transform: translateY(50%) scaleX(1);
}


/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
    left: calc(100% + 5px);
    bottom: 50%;
}

[data-tooltip-location="right"]:hover:before {
    transform: translate(5px, 50%) scale(1);
}

[data-tooltip-location="right"]:after {
    border-width: 5px 5px 5px 0px;
    border-color: transparent $secondColor transparent transparent;
    transform-origin: right;
    transform: translateY(50%) scaleX(0);
}

[data-tooltip-location="right"]:hover:after {
    transform: translateY(50%) scaleX(1);
}

/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
    top: calc(100% + 5px);
    bottom: auto;
}

[data-tooltip-location="bottom"]:before {
    transform: translate(-50%, 5px) scale(0.5);
}

[data-tooltip-location="bottom"]:hover:before {
    transform: translate(-50%, 5px) scale(1);
}

[data-tooltip-location="bottom"]:after {
    border-width: 0px 5px 5px 5px;
    border-color: transparent transparent $secondColor transparent;
    transform-origin: bottom;
}

.e-tooltip-wrap.e-popup.e-lib.e-control.e-popup-open {
    padding: 10px 18px;
    min-width: 50px;
    max-width: 300px;
    border-radius: 6px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    background-color: $secondColor;
    box-shadow: 0 0 24px rgb(0 0 0 / 20%);
    color: #fff;
    text-align: center;
    white-space: pre-wrap;
    margin-top: 2px
}

//.ejsTooltip {
//    position: relative;
//
//    &::before {
//        content: '';
//        position: absolute;
//        bottom: -7px;
//        left: 2px;
//        border-style: solid;
//        border-width: 0px 5px 5px 5px;
//        border-color: transparent transparent $secondColor transparent;
//        display: none;
//    }
//
//    &:hover {
//        &::before {
//           display: block;
//        }
//    }
//}
//
