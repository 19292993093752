html, body {
    max-width: 100%;
}

body {
    position: relative;
    height: 100%;
}

input, textarea {
    &:focus {
        outline: 1px solid rgba(64, 146, 196, 0.5) !important;
        box-shadow: unset!important;
    }
}

input, select {
    &:disabled {
        cursor: not-allowed;
    }
}

select {
    option {
        &:hover {
            background: $mainColor !important;
            color: #fff
        }
    }
}

a {
    &:hover {
        text-decoration: none!important;
    }
}
button {
    &:hover {
        text-decoration: none;
    }
}

button {
    &:hover,
    &:active,
    &:focus {
        outline: none !important;
    }
}
