.download-container {
    display: none;
    align-items: flex-end;
    position: fixed;
    left: 50%;
    top: 0;
    height: 100%;
    width: 100%;
    transform: translateX(-50%);
    background: rgba(229, 229, 229, 0.5);
    z-index: 222222;
}

.download-container span {
    position: relative;
    margin-left: 55px;
    -webkit-animation: bar ease-in-out  1s infinite;
    -moz-animation: bar ease-in-out  1s infinite;
    -ms-animation: bar ease-in-out  1s infinite;
    -o-animation: bar ease-in-out  1s infinite;
    animation: bar ease-in-out 1s infinite;
}

@-moz-keyframes bar {
    0% {bottom: 0;}
    50% {bottom: 19px;}
    100% {bottom: 0;}
}

@-webkit-keyframes bar {
    0% {bottom: 0;}
    50% {bottom: 19px;}
    100% {bottom: 0;}
}

@-o-keyframes bar {
    0% {bottom: 0;}
    50% {bottom: 19px;}
    100% {bottom: 0;}
}

@-ms-keyframes bar {
    0% {bottom: 0;}
    50% {bottom: 19px;}
    100% {bottom: 0;}
}

@keyframes bar {
    0% {bottom: 0;}
    50% {bottom: 19px;}
    100% {bottom: 0;}
}
