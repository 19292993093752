.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 27px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(21px);
        -ms-transform: translateX(21px);
        transform: translateX(21px);
        background: #27AEDE;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    }

    input:checked + .slider {
        background: rgba(39, 174, 222, 0.5);
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 5px;
        left: 9px;
        right: 10px;
        bottom: 8px;
        background: rgba(82, 79, 99, 0.35);
        -webkit-transition: .4s;
        transition: .4s;

        &::before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 0;
            bottom: 0;
            top: -3px;
            background: #F1F1F1;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
            -webkit-transition: .4s;
            transition: .4s;
        }
    }

    .round {
        border-radius: 35px;

        &::before {
            border-radius: 50%;
        }
    }
}
