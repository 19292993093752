.contact_us {
    height: auto;
    position: fixed;
    right: 23px;
    bottom: 0;
    background-color: #ffffff;
    width: 299px;
    border-radius: 6px;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.25);
    z-index: 1000;

    #contactForm {
        background: #fff;
        padding: 34px 24px 14px;
        position: relative;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .fields {
            span {
                position: absolute;
                top: 5px;
                right: 5px;
                font-size: 19px;
                width: 24px;
                height: 24px;
                color: $secondColor;
                text-align: center;
                cursor: pointer;
            }

            .form-control {
                background-color: $mainTransparent !important;
            }

            textarea {
                height: 249px;
                margin-bottom: 10px;
            }

            button {
                width: 100px;
                height: 36px;
                background-color: #48C89E;
                border-radius: 4px;
                padding: 0;

                &:hover {
                    background-color: #1fa579;
                }
            }
        }
    }

    button {
        width: 100%;
        background-color: $mainColor;
        padding-right: 200px;
        border: none;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        position: relative;

        i.fa.fa-envelope {
            position: absolute;
            top: 11px;
            left: 14px;
            font-size: 20px;
        }

        i {
            position: absolute;
            right: 10px;
            top: 10px;
        }

        &:hover {
            background-color: $secondColor;
        }

        @include media(576) {
            padding-right: 0;
            border: none;
            position: relative;
            width: 50px;
            height: 50px;
            right: -200px;
            border-radius: 50%;

            .txt {
                display: none !important;
            }
            .fa-envelope {
                display: block !important;
                margin-right: 5px;
                margin-top: 4px;
                font-size: 24px;
            }
        }
    }

    @include media(576) {
        box-shadow: none;
        background: none;
    }
}
