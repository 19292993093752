.privacy-and-security {
    width: 664px;
    //max-width: 45%;
    padding: 60px 62px 120px;
    margin: auto;
    background: #F2F6FC;
    border-radius: 4px;
    font-family: $mainFont, sans-serif;

    .two-factor-header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(64, 146, 196, 0.15);
        border-radius: 6px;

        p {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: #27AEDE;
        }
    }

    .two-factor-description {
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #524F63;
        }
    }

    .skyblue-line {
        width: 30px;
        height: 2px;
        margin: 32px auto;
        background-color: #27AEDE;
        box-sizing: border-box;
        border-radius: 5px;
    }

    .two-factor-methods {
        padding-bottom: 35px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid rgba(39, 174, 222, 0.3);

        &-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            background: rgba(64, 146, 196, 0.15);
            border-radius: 4px;
            padding: 10px 27px 10px 40px;

            span {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: rgba(82, 79, 99, 0.8);
            }

            .infoIcon {
                position: relative;

                .infoText {
                    left: -60% !important;
                    width: 356px;

                    &::after {
                        left: 5.5%;
                    }
                }
            }
        }

        &-items {
            padding-right: 17px;
            padding-left: 30px;
            margin-top: 15px;

            .disabled {
                margin: 20px 10px 0;
                background: rgba(82, 79, 99, 0.15);
                border-radius: 50px;
                border: none;

                .item {
                    color: #524F63;
                    opacity: 0.4;
                }

                .check-method {
                    display: none;
                }
            }

            .enable {
                background: rgba(255, 255, 255, 0.9);
                border: 1px solid rgba(64, 146, 196, 0.35);

                .item {
                    opacity: 0.7;
                }
            }

            .checked {
                background: #27AEDE;
                box-shadow: 0px 2px 4px rgba(39, 174, 222, 0.3);
                border: none;

                .item {
                    color: #fff;
                    opacity: unset;
                }

                .check-method {
                    display: inline-block;
                }
            }
        }
    }

    .two-factor-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
    }

    @include media(1600) {
        width: 70%;
    }
    @include media(1200) {
        width: 75%;
    }
    @include media(992) {
        width: 80%;
    }
    @include media(768) {
        width: 100%;
    }
}
