/*------- ABSTRACTS ------- */
@import "abstracts/_variables";
@import "abstracts/_extends";
@import "abstracts/_mixins";
/*------- BASE ------- */
@import "base/_reset";
/*------- COMPONENTS ------- */
@import "components/_alert";
@import "components/_buttons";
@import "components/_checkboxes";
@import "components/_filters";
@import "components/_fonts";
@import "components/_inputs";
@import "components/_modals";
@import "components/_pagination";
@import "components/_radios";
@import "components/_scroll";
@import "components/_select";
@import "components/_svgs";
@import "components/_switchs";
@import "components/_tables";
@import "components/_toast_messages";
@import "components/_tooltips";
@import "components/_transition";
/*------- LAYOUT ------- */
@import "layout/_sidebar";
@import "layout/_profileSidebar";
@import "layout/_navbar";
@import "layout/_home";
/*------- PAGES -------*/
@import "pages/_contact-us";
@import "pages/_domainSettings";
@import "pages/_emailSettings";
@import "pages/_gdpr";
@import "pages/_login";
@import "pages/_privacyAndSecurity";
@import "pages/_retentionPeriods";
@import "pages/_subscriptionAndBilling";
/*------- SHARED -------*/
@import "_shared";
/*------- ICONS FONTS -------*/
@import '~flag-icon-css/sass/flag-icon.scss';
//@import "node_modules/font-awesome/scss/font-awesome.scss";
//@import "node_modules/font-awesome/scss/font-awesome.scss";
@import url('https://fonts.googleapis.com/css?family=Audiowide');

.material {
    @import "~vue-snotify/styles/material";
}

.simple {
    @import "~vue-snotify/styles/simple";
}

.dark {
    @import "~vue-snotify/styles/dark";
}

.box_shadow {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
    //margin-top: 12px;
}

.main_records {
    padding-bottom: 133px;
    //height: inherit;
    .checkmark {
        margin: unset!important;
    }
}

.without_first_page {
    height: 100%;
}

.border-area {
    box-sizing: border-box;
    border: 2px solid #E6E8F1;
    border-radius: 6px;

    &.area_with_photo {
        padding: 3% 3% 5% !important;
    }
}

nav {
    .nav-item {
        a.nav-link {
            color: rgba(0, 0, 0, .5);
            font-size: 23px;
            padding: 5px 0 0;
        }
    }
}

.content {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
}

.tables_parent {
    height: 100% !important;
}

.table_and_pagination {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.parent_content {

    .second-lang {
        height: 50px;
        background-color: $mainTransparent;
        border: none;
        outline: none;

        @include media(992) {
            height: 40px;
        }
        @include media(768) {
            font-size: 16px;
        }
    }

    .special_check {
        top: 22px;
    }

    .new_standard_contract {
        svg {
            margin-top: 8px;
        }
    }

    .slides_for_tabs {
        .left_chevron {
            font-size: 30px;
            @include media(576) {
                font-size: 24px;
            }
        }

        .right_chevron {
            font-size: 30px;
            @include media(576) {
                font-size: 24px;
            }
        }
    }

    .cancel_continue {
        .cancel {
            border: 1px solid $mainColor;
            width: 184px;
            height: 48px;
            background-color: white;
            color: #524F63;
            border-radius: 4px;
            box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.25);
            font-size: 20px;
            font-weight: 400;
            @include media("xl") {
                height: 40px;
                width: 150px;
            }
            @include media("xs") {
                width: 100%;
            }
        }

        .save {
            border: 1px solid $mainColor;
            width: 184px;
            height: 48px;
            background-color: $mainColor;
            color: white;
            border-radius: 4px;
            font-size: 20px;
            font-weight: 400;
            @include media("xl") {
                height: 40px;
                width: 150px;
            }
            @include media("xs") {
                width: 100%;
            }
        }
    }

    .view_contract {
        a {
            &.active {
                span {
                    color: $mainColor !important;
                }
            }
        }

        .fa {
            cursor: pointer;
        }

        span {
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            font-size: 18px;
            color: #524F63;
            @include media(576) {
                font-size: 15px;
            }

            &:hover {
                color: $mainColor;
            }
        }
    }

    .slides_for_tab {
        height: auto;
    }

    .larg_area_1 {
        .view_of_contract {
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            font-size: 18px;
            color: #524F63;
        }

        .right_chevron {
            color: #d8cccc;

            &:hover {
                color: $mainColor;
            }
        }

        .left_chevron {
            color: #d8cccc;

            &:hover {
                color: $mainColor;
            }
        }

        .add_your_filds {
            font-size: 18px;
            margin-top: 22px;
        }

        width: 49%;
        @include media(992) {
            width: 100%;
        }
    }

    .viwe_contract {
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-size: 18px;
        color: #524F63;
    }

    .add_new_blue {
        width: 113px;
        height: 30px;
        color: white;
        background-color: $mainColor;
        border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        border: none;
        @include media(576) {
            height: 25px;
            width: 100px;
            font-size: 14px;
        }
    }

    .add_new_green {
        width: 113px;
        height: 30px;
        color: white;
        background-color: #09D190;
        border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        border: none;
        @include media(576) {
            height: 25px;
            width: 100px;
            font-size: 14px;
        }
    }

    .card {
        border: transparent;

        .card-header {
            border-bottom: transparent;

            h5 {
                font-size: 18px;
                @include media("xl") {
                    font-size: 16px;
                }
                @include media(576) {
                    font-size: 12px;
                }
                @include media("xs") {
                    font-size: 10px;
                }
            }
        }
    }

    .fill_details {
        &:hover {
            color: $mainColor;
            border: 1px solid #d2d7df;


        }

        span {
            &:hover {
                .trash {
                    path {
                        fill: $mainColor;
                    }

                }
            }

        }

        cursor: pointer;
        width: 100%;
        display: inline-block;
        background: $mainTransparent;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid transparent;

        @include media(992) {
            font-size: 14px;
        }
    }

    .click_collaps {
        transform: rotate(180deg);
    }

    .card-header {
        background-color: #F4F5F8;
    }

    .general_page {
        background-color: white;

        .empty_area {
            border: transparent;
            height: 50px;
        }

        p {
            @include media("xs") {
                padding-right: 30px;
                padding-left: 30px;
            }
        }

        .three_areas {
            width: 400px;
            margin: 0 auto;
            overflow-y: auto;
            overflow-x: hidden;

            img {
                width: auto;
                height: auto;

            }

            @include media("xs") {
                width: 90%;
            }
        }
    }

    .login_your_account {
        width: 460px;

        .go_back {
            a {
                color: $mainColor !important;
                cursor: pointer;
            }

        }

        .blue_login_button {
            width: 100%;
            font-size: 20px;
            margin-bottom: 10px;
            box-shadow: none;

            @include media(992) {
                font-size: 16px;
            }
        }

        .forget_pass {
            display: block;
            text-align: left;
            line-height: 10px;

            a {
                cursor: pointer;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: $mainColor;
            }

            small {
                font-size: 14px;
                line-height: 21px;
                color: #524F63;

                span {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #27AEDE;
                }
            }

            .go_back {
                font-size: 16px;
            }
        }

        label {
            font-weight: 500;
            font-size: 20px;
            line-height: normal;
            color: #524F63;
            display: block;
            @include media(576) {
                font-size: 17px;
            }
        }

        h1 {

            font-style: normal;
            font-weight: 600;
            line-height: normal;
            font-size: 23px;
            text-align: center;
            text-transform: uppercase;
            color: $mainColor;
            @include media(768) {
                font-size: 20px;
            }
            @include media(576) {
                font-size: 18px;
            }
        }

        .forgot__title {
            h2 {
                font-weight: 600;
                font-size: 26px;
                line-height: 39px;
                color: #27AEDE;
                margin-bottom: 60px;
                text-align: center;
            }
        }

        @include media(576) {
            width: 100%;
        }
    }

    .img_persons {
        .img_dgd {
            height: 100vh;
            background: $authOpacity;
            .login-img-wrapp {
                max-width: 400px;
                margin: 0 auto;
                position: relative;
                width: 100%;

                img {
                    max-width: 283px;
                    width: 100%;
                    margin-bottom: 31px;
                }

                p {
                    text-align: center;
                    margin: 5px 0 0;
                    color: #fff;
                    font-size: 18px;
                    line-height: 27px;
                    border-top: 1px solid #fff;
                    padding-top: 25px;
                }
            }
        }

        .center {
            display: block;
            width: 100%;
            margin: 0 auto;
        }

        background: url(../assets/images/rectangle-cover.png) no-repeat;
        background-size: 100% 100%;

    }

    .login_logo {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        img {
            //max-width: 100%;
            width: 100%;
            max-width: 260px;
            max-height: 180px;
            margin-bottom: 30px;
        }
    }

    .tools_3 {
        #showing {
            margin-top: 17px;
            width: 5%;
        }

        label {
            margin-top: 16px;
        }
    }

    .long_texts1 {
        width: 45%;
        @include media(992) {
            width: 75%;
        }
    }

    .cancel_save_processing_act_more {
        margin-right: 50px;
        @include media(768) {
            margin-right: 0;
        }

        .cancel {
            @include media(576) {
                width: 100%;
            }
        }

        .save {
            @include media(576) {
                width: 100%;
                margin-top: 30px;
            }
        }
    }

    .second_question {
        .cancel_save_processing_act1 {
            margin-right: 52px;
            @include media(992) {
                margin-right: 0;
            }
        }

        .cancel {
            width: 43%;
            @include media(992) {
                width: 100%;
            }
        }

        .save {
            width: 43%;

            @include media(992) {
                width: 100%;
                margin-top: 30px;
            }
        }
    }

    .company_location {
        .cancel_save_processing_act3 {
            .cancel {
                @include media(768) {
                    width: 100%;
                }
            }
        }
    }

    .manage_sort {
        padding-top: 18px;
    }

    .controler_exist {
        .cancel_save_processing_act4 {
            .previous {
                @include media(992) {
                    width: 150px;
                }
                @include media("xs") {
                    width: 80%;
                    margin-bottom: 30px;
                }
            }

            .next {
                @include media(992) {
                    width: 150px;
                }
                @include media("xs") {
                    width: 80%;
                }
            }

            margin-top: 80px;
        }

        margin-top: 30px;
        padding-top: 170px;
        padding-bottom: 37px;
    }

    .add_new_person {
        .previous {
            width: 48%;
        }

        .next {
            width: 48%;
        }

        .add_new_person2 {
            margin-top: 158px;
            @include media(576) {
                width: 100%;
                margin-top: 30px;
            }
        }

        .previous_next_processing_act3 {
            margin-top: 50px;

            .previous {
                @include media(576) {
                    width: 100%;

                }
            }

            .next {

                @include media(576) {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }

        margin-top: 30px;
        padding-top: 105px;
        padding-bottom: 45px;
    }

    .add_new_dpo2 {
        .previous_next_processing_act3 {
            @include media(768) {
                margin-left: 15px;
                margin-right: 10px;
            }

            .next {
                @include media(576) {
                    margin-top: 30px;
                }
            }
        }

        .three_selects {
            width: 386px;
            @include media(576) {
                width: 91%;
                padding-left: 30px;
            }
        }

        margin-top: 30px;
        padding: 45px 0;

        p {
            margin-bottom: 80px;
        }
    }

    .add_new_dpo {
        .procsing_gdpres {
            padding-right: 30px;
            padding-left: 30px;
        }


        .form-control {
            width: 375px;
        }

        .cancel_save_processing_act3 {
            .cancel {
                margin: 0;
            }

            margin-top: 10px;
            margin-right: 120px;
        }

        padding-top: 70px;
        padding-bottom: 45px;
        margin-top: 30px;
    }

    .edit_location {
        .software_ru {
            color: #706F75;
        }

        a {
            font-size: 24px;
        }
    }

    .add_or_edit {
        .previous {
            width: 48%;
            margin-top: 40px;
            @include media("xs") {
                width: 100%;

            }
        }

        .next {
            width: 49%;
            @include media("xs") {
                width: 100%;

                margin-top: 30px;
            }
        }

        margin-top: 30px;
        padding: 80px 0;
    }

    .new_users2 {
        .cancel {
            width: 48%;
        }

        .save {
            width: 48%;
        }

        margin-top: 30px;
        padding-top: 77px;
        padding-bottom: 104px;
    }

    p {
        font-size: 18px;
    }

    .create-edit-persons {
     padding: 60px 200px;
        .row {
            margin: 0;
            justify-content: space-between;
        }
        .edit_or_create_boxes {
            width: 48.6%;
        }
        .form-group {
            margin-bottom: 20px;
        }
        .next_inputs {
            border-top: 1px solid rgba(64, 146, 196, 0.3);
            margin-top: 45px;
            padding-top: 45px;
            .addressComponent {
               div {
                   padding: 0;
               }
                .col-6 {
                    max-width: 48.6%;
                }
            }
        }
        .phone-fax-section {
            .form-group {
                width: 48.6%;
            }
        }
    }

    .information {
        width: 49%;
        height: 700px;
        padding-top: 30px;
        margin-top: 30px;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: white;
        border-radius: 6px;

        .short_discraption {
            width: 75%;
            border: none;
            margin: 30px auto 60px;
            @media (max-width: 1600px) {
                width: 90%;
            }

            h5 {
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #524F63;

                @include media(1200) {
                    font-size: 16px; //18
                }
            }

            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #524F63;

                &.selected-risk__title {
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: bold;
                    color: $mainColor;

                    @include media(1200) {
                        font-size: 16px; //18
                    }
                }

                @include media(1200) {
                    font-size: 14px; //16
                }
            }

            .option p {
                font-weight: normal;
            }

            a {
                color: $mainColor;
                font-family: $mainFont, sans-serif;
            }

            input, textarea {
                background-color: $mainTransparent;
                width: 100%;
                border: none;
                padding: 10px 15px;
                border-radius: 4px;
                @include media(768) {
                    margin-top: 70px;
                }
            }
            .form-group {
                margin-bottom: 20px;
                .salutation {
                    height: 50px;
                    @include media(1200) {
                        height: 40px;
                    }
                }
            }

            input {
                height: 50px;
            }

            textarea {
                height: 100px;
                min-height: 100px;
                margin-top: 40px;
                font-size: 16px;

                @include media(1200) {
                    font-size: 14px;
                }
            }

            .language_1_name, .language_2_name {
                color: #a1abba;
            }
        }

        @include media(768) {
            width: 100%;
        }

        @include media(576) {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .edit_record {

        label {
            margin-left: 10%;
            @include media(992) {
                margin-left: 0;
                @include media(768) {
                    font-size: 14px;
                }
            }
        }

        .cancel {
            @include media("xs") {
                width: 100%;

            }
        }

        .save {
            @include media("xs") {
                width: 100%;
                margin-top: 30px;
            }
        }

        .form-group {
            @include media(992) {
                padding-left: 40px;
                padding-right: 40px;
                @include media(992) {
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            @include media("xs") {
                padding: 0;
            }
        }

        margin-top: 30px;

        p {
            margin-left: 35px;
            color: $mainColor;
            @include media(992) {
                padding-left: 65px;
                @include media(992) {
                    margin-left: 0;
                    padding-left: 5px;
                }
                @include media("xs") {
                    padding-left: 0;
                }
            }
        }

        min-height: 700px;

        .form-control {
            margin: 0 auto;
            width: 82%;
            @include media(992) {
                width: 100%;
            }
        }
    }

    .name_and_contacts {

        .processing_gdpr {
            .cancel {
                width: 49%;
                @include media(768) {
                    width: 100%;
                }
            }

            .save {
                width: 48%;
                @include media(768) {
                    width: 100%;
                    margin-top: 30px;
                }
            }

            .cancel_save_processing_act3 {
                .previous {
                    margin-bottom: 30px;
                }

                padding: 0;
                margin-right: 18%;
                @include media(576) {
                    margin-right: 45px;
                }
            }

            .form-control {
                width: 83%;
                margin: 0;
                @include media(576) {
                    width: 92%;
                }
            }
        }

        padding-top: 38px;
        padding-left: 33px;
        padding-bottom: 23px;
        margin-top: 30px;
    }

    .cancel_save_processing_act {
        .cancel {
            @include media(576) {
                width: 100%;
            }
        }

        .save {
            margin-right: 100px;
            @include media(992) {
                margin-right: 0;

            }
            @include media(576) {
                width: 100%;
                margin-top: 30px;
            }
        }

        padding-top: 55px;
        margin-right: 80px;
        @include media(992) {
            margin-right: 0;
            padding-right: 40px;
        }

    }

    .cancel_save_processing {
        padding-right: 100px;
    }

    .standard_record {
        .software_ru {
            width: 310px;
            margin-right: 140px;
            @include media("xs") {
                width: 100%;
            }
        }
    }

    .software_ru {
        width: 44%;
        height: 50px;
        margin-top: 5px;
        background-color: $mainTransparent;
        border: none;
        @include media(768) {
            margin-top: 20px;
            width: 100%;
        }

    }

    .category {
        label {
            margin-right: 0;
            font-size: 18px;
            @include media("xl") {
                font-size: 15px;
            }

        }

        width: 100%;

        select {
            color: #706F75;
            //@include media("xl") {
            //  width: 70%;
            //}
        }

        label {
            @include media("xl") {
                display: block;
            }
        }
    }

    .microsoft_word {
        p {
            font-size: 24px;
            color: $mainColor;
            font-family: 'Poppins';

            @include media(576) {
                font-size: 20px;
            }
        }
    }

    .larg_area {
        .photo_area {
            @include media("xs") {
                padding: 20px;
            }
        }

        .note_book {
            width: 160px;
            height: 160px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FAFAFC;
            border-radius: 6px;

            @include media("xs") {
                width: 100%;
                height: auto;
            }
        }

        .browses_fils {

            text-align: center;
            font-size: 30px;
            @include media(576) {
                font-size: 20px;
            }
        }

        .drop_content {
            padding-top: 160px;
            @include media(576) {
                font-size: 30px;
            }

        }

        .drop_fils {
            margin: 35px auto;
            width: 90%;
            border: 1px dashed $mainColor;

            h3 {
                width: 67%;
                font-weight: normal;
                font-style: normal;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: rgba(82, 79, 99, 0.5);
                letter-spacing: 0.02em;
            }

        }

        margin: 30px 0;


        width: 47%;
        min-height: 490px;
        @include media(992) {
            width: 100%;
        }
    }


    .area_with_forms {
        .account_settings {
            .save {
                //width: 49%;
                margin-top: 20px;
                height: 48px;
                @include media("xs") {
                    width: 100%;

                }
            }

            .cancel {
                width: 48%;
                height: 47px;
                margin-top: 55px;
                @include media("xs") {
                    width: 100%;

                }
            }

            .log_out_device {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .log-out-other {
                    margin: 0 5px 0;
                }
            }

            .changePassword {
                .form-group {
                    position: relative;

                    .form-control {
                        padding-right: 40px;
                    }
                }

                .cancel_save_lng_translation {
                    display: flex;
                    justify-content: center;

                    .cancel, .save {
                        border-radius: 4px;
                        padding: 7px 15px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        border: none;
                        background: $mainColor;
                        color: #fff;
                        margin: 10px;
                        width: 50%
                    }

                    .cancel {
                        background: #F4F5F8;
                        border: 1px solid rgba(82, 79, 99, 0.3);
                        color: #524F63;
                        opacity: 0.4;
                    }
                }
            }
        }

        .cancel {
            margin-top: 25px;
        }

        [type="date"] {
            background: $mainTransparent url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 97% 50% no-repeat;
        }

        [type="date"]::-webkit-inner-spin-button {
            display: none;
        }

        [type="date"]::-webkit-calendar-picker-indicator {
            opacity: 0;
        }

        /* custom styles */

        label {
            display: block;
        }


        .tab-content > .tab-pane {
            padding: 50px 60px;
            min-height: 650px;

            @include media(992) {
                padding-left: 20px;
                padding-right: 20px;
            }
            @include media(768) {
                padding-left: 20px;
                padding-right: 20px;
                height: unset;
            }

            .translation_table {
                .cancel {
                    width: 49%;
                }

                .save {
                    width: 48%;
                }
            }

            &.active.show.d_flex {
                display: flex;
            }
        }

        .tab-pills {
            li {

                a {
                    cursor: pointer;

                    &:hover {
                        color: $mainColor;
                    }
                }

                .active {
                    color: $mainColor;
                }
            }

        }

        .nav-pills {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            position: relative;
            z-index: 1;
            background-color: $secondTransparent;

            li {
                width: 20%;
                padding: 0;

                a {
                    cursor: pointer;
                    padding: 20px;
                    height: 100%;
                    text-align: center !important;
                    display: block;
                    width: 100%;
                    outline: none;
                    font-weight: bold;
                    @include responsive-size(18px, 1920, 1200, font-size);
                    @include responsive-size(27px, 1920, 1200, line-height);
                    color: $textTransparent;

                    &.active, &.show {
                        background-color: #fff;
                        opacity: 1;
                        border-radius: 0 8px 0 0;
                    }

                    &:hover {
                        color: $mainColor;
                    }

                    @include media(992) {
                        padding: 10px;
                        align-items: center;
                        display: flex;
                    }
                }

                .active {
                    color: $mainColor;
                }

                &.comp_settings {
                    display: flex;
                    width: 80%;
                }
            }

            &.bigTab {
                background: #fff;

                .singleTab {
                    background-color: $secondTransparent;
                }
            }
        }


        .clearfix {
            z-index: 3;
            position: relative;
            background: #fff;
            border-radius: 0 0 6px 6px;
        }

        .form-control {
            width: 100%;
        }

        a {
            color: #524F63;
            font-size: 18px;
            line-height: 27px;
        }

        margin-top: 40px;
    }


    .area_with_photo {

        .account-info {
            div {
                &:last-of-type {
                    p {
                        margin-bottom: 0;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 10px;

                    &:first-of-type {
                        font-weight: 600;
                    }

                    &:last-of-type {
                        word-break: break-word;
                    }
                }
            }
        }

        .profile_type {
            font-size: 16px;
            line-height: 30px;
            width: 90%;
            margin: 0 auto;

            @media(992) {
                font-size: 13px;
                padding-right: 10px;
                padding-left: 10px;
            }

            @media screen and (max-width: 1400px) {
                width: 100%;
            }
        }

        p {
            padding-left: 30px;
            @include media("xl") {
                padding-left: 15px;
            }
        }

        .camera {
            width: 100%;
            text-align: center;
            padding: 15px 0 30px;
            color: $mainColor;
            display: flex;
            font-size: 33px;
            align-items: center;
            justify-content: center;
            .line {
                display: inline-flex;
                background-color: #E6E8F1;
                width: 2px;
                height: 27px;
                margin: 0 15px;
            }
            span {
                display: flex;
            }

            label {
                font-size: 33px;
                line-height: normal;
                color: unset;

                &:hover {
                    cursor: pointer;
                }
            }

            @include media(1500) {
                padding: 10px 0;
                font-size: 28px;

                label {
                    font-size: 28px;
                }
            }
            svg {
                cursor: pointer;
            }
        }

        .photo {
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            width: 148px;
            height: 148px;
            margin: 0 auto;
            @include media(1500) {
                width: 100px;
                height: 100px;
            }
            @include media(768) {
                width: 170px;
                height: 170px;
            }
        }

        .avatar {
            border: 2px solid rgba(82, 79, 99, 0.15);
        }

        padding-top: 120px;
        padding-bottom: 23%;
        background-color: white;
        @include media(992) {
            padding-bottom: 0;
        }
    }

    .add_system_lng {
        select#translate {
            font-family: FontAwesome, sans-serif;
            color: #524F63;
        }

        a {
            padding-left: 4px;
        }

        .edit_cancel_save_lng {
            margin-top: 40px;
            width: 89%;
            @include media(768) {
                width: 100%;
            }
        }

        .add_lng {
            display: inline-block;
        }

        .form-control {
            display: inline-block;
            width: 88%;
            @include media(992) {
                width: 100%;
                margin-bottom: 30px;
            }
        }

        width: 50%;
        margin: auto;
        padding-top: 128px;
        padding-bottom: 128px;
        @include media(992) {
            width: 70%;
        }
        @include media(576) {
            width: 100%;
        }
    }

    .add_auth_rep {
        .previous_next {
            @include media(992) {
                width: 80%;
            }
            @include media(576) {
                padding-right: 79px;
            }
            @include media("xs") {
                padding: 50px 0;
            }

            .previous {
                @include media(576) {
                    width: 82%;

                }
                @include media("xs") {
                    width: 100%;

                }
            }

            .next {
                @include media(576) {
                    width: 82%;

                    margin-top: 20px;
                }
                @include media("xs") {
                    width: 100%;

                }
            }

            padding-top: 170px;

            @include media(576) {
                padding-top: 120px;
            }
        }

        .select_number {
            margin: 0 auto;
            width: 39%;
            @include media(768) {
                width: 77%;
            }
            @include media(576) {
                width: 100%;
            }
        }

        .auth_title p {
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            margin-bottom: 70px;

            @include media(576) {
                font-size: 18px;
            }
        }

        .form-control {
            width: 89%;
            @include media(768) {
                width: 100%;
            }
        }

    }

    .add_comp {
        .colons {
            @include media(576) {
                padding: 0;
            }
        }

        .previous {
            @include media("xs") {
                width: 99%;

                margin-top: 25px;
            }
        }

        .next {
            @include media("xs") {
                width: 99%;

            }
        }

        .previous_next {
            padding-right: 110px;
            @include media(576) {
                padding: 0;

            }

            @include media("xs") {
                padding: 0;
            }
        }

        .form-control {
            width: 80%;

            @include media(992) {
                width: 100%;
            }
        }

        .select2-container {
            width: 80% !important;
            display: block;

            @include media(992) {
                width: 100% !important;
            }
        }

        padding-top: 50px;
        padding-bottom: 60px;
    }

    .add_user {
        padding-top: 115px;
        padding-bottom: 50px;

        .form-control {
            @include media(992) {
                width: 100%;
            }
        }
    }

    label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        font-style: normal;
        color: $textColor;
        margin-bottom: 8px;

        @include media(1500) {
            font-size: 14px !important;
        }
    }

    select.form-control:not([size]):not([multiple]) {
        height: 50px;
    }

    .right-tools_2 {
        display: inline-block;
        float: right;
        padding-top: 43px;
        align-items: center;

        @include media(992) {

            padding-left: 40px;
            padding-top: -1px;
            padding-bottom: 20px;

        }

        .manage_sort {
            padding: 0;
        }

        .sort_by {
            cursor: pointer;
            margin-right: 20px;
            padding-left: 20px;
            @include media("xl") {
                margin-right: 1px;
            }

            &:hover {
                color: $mainColor;

            }

        }

        .sort_by {
            padding-top: 18px;
            padding-right: 10px;
            cursor: pointer;
        }

        select {
            background-color: $mainTransparent;
            border: none;
            margin-right: 20px;
            outline: none;
            cursor: pointer;
            padding-bottom: 10px;
            @include media("xl") {
                margin-right: 0;
            }
        }
    }

    .editable-record-icon {
        position: absolute;
        top: 16px;
        right: 0;
        padding: 7px 10px;
        background: #E5E8E9;
        border-radius: 0px 3px 0px 0px;
        cursor: pointer;
    }

    .content_body {
        padding-left: 33px;
        padding-top: 90px;
        padding-bottom: 23px;

        .source_text {
            input {
                min-height: 50px;
                background-color: $mainTransparent;
                border-radius: 4px;
                border: none;
                outline: none;
                padding-left: 10px;

            }

            min-height: 50px;
            background-color: $mainTransparent;
            border-radius: 4px;
            border: none;
            outline: none;
            padding: 13px;
            font-size: 16px;
            text-align: left;
            word-break: break-word;
        }

        .processing_activity {
            .form-control {
                width: 382px;
            }

            p {
                color: $mainColor;
            }
        }

        .languages {
            .lng_child {
                line-height: 18px;
                border-right: 1px solid #BDBDBD;
                color: #524F63;
            }
        }

        .basic_table {
            .lang_two {
                font-size: 18px;
                @include media(768) {
                    font-size: 16px;
                }
            }

            span {
                padding-left: 5px;
                padding-right: 10px;
            }
        }

        border: none;
        width: 100%;
        margin-bottom: 0;
    }

    .record_content {
        padding-left: 0;
        padding-top: 0;
        margin-bottom: 0;
        margin-left: 0px;
        background-color: white;
    }

    .period_content {
        padding-left: 0;
        padding-top: 0;
        margin-bottom: 0;
        margin-left: 0px;
        background-color: white;
    }

    .left {
        position: relative;

        .filter {
            padding-left: 60px;
            position: absolute;
            margin-top: 42px;
            color: $mainColor;
            width: 20px;
            top: 0;
            @include media("xl") {
                padding-left: 35px;
            }

            @include media(992) {
                padding-left: 25px;

            }
        }
    }

    .search {
        margin-top: 42px;
        border-right: none;
        border-left: none;
        border-top: 0;
        border-bottom: 1px solid #706F75;
        background-color: $mainTransparent;
        margin-left: 50px;
        padding-left: 40px;
        color: #524F63;
        outline: none;
        @include media("xl") {
            margin-left: 20px;
        }
        @include media(992) {
            margin-left: 5px;
        }
        @include media("xs") {
            width: 150px;
        }
    }

    #showing {
        padding: 0;
        padding-left: 10px;
        margin-left: 0;
        background-color: white;
        border: 1px solid #C4C4C4;
        color: $mainColor;
        border-radius: 4px;
        outline: none;
        width: 50px;
    }

    .content_header {
        .form-group {
            font-family: sans-serif;
        }

        label {
            font-family: sans-serif;
        }

        h1 {
            @include media(576) {
                font-size: 30px;
            }
        }

        h3 {
            font-size: 40px;
            @include media(768) {
                font-size: 30px;
            }
            @include media("xs") {
                font-size: 20px;
            }

        }

        p {
            font-size: 24px;
            @include media(768) {
                font-size: 15px;

            }
            @include media("xs") {
                font-size: 10px;
            }


        }

        .update_record {

            .form-control {
                display: inline-block;
                width: 27%;
            }

            a {
                font-size: 18px;
                padding-left: 34px;
            }
        }

        padding-left: 31px;
        padding-bottom: 40px;
        padding-top: 41px;
        margin-top: 41px;
        font-size: 40px;
        font-family: "Poppins", sans-serif;
        color: #524F63;
    }

    .suggested {
        .creat_category_select {
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            font-size: 16px;
            color: #524F63;
            @include media(1200) {
                font-size: 14px;
            }
        }

        .lang_selects {
            width: 300px;
            height: 50px;
            border: none;
            background: $mainTransparent;
            border-radius: 4px;
            @include media(992) {
                height: 40px;
            }
            @include media(768) {
                width: 90%;
            }
            @include media("xs") {
                width: 100%;
            }

        }

        .standard_contract_tool {
            width: 100%;
            min-height: 50px;
            background-color: $mainTransparent;
            border-radius: 4px;
            border: none;
            outline: none;
        }

        h3 {
            color: #524F63;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            @include media(992) {
                font-size: 35px;
            }

            @include media(768) {
                font-size: 30px;
            }
            @include media("xs") {
                font-size: 23px;
            }
        }

        p {
            font-size: 24px;
            line-height: 36px;
            //font-family: Poppins;
            //font-weight: bold;
            @include media(576) {
                font-size: 18px;
            }
        }

        //padding: 2% 5%;
        //margin-top: 41px;
        color: #524F63;
        @include media(768) {
            padding: 20px 20px;
        }
    }

    .content {
        margin-top: 43px;
        height: 700px;
        background-color: white;
        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.25);;
        border-radius: 6px;

        @include media(sm) {
            box-shadow: 0 0 9px rgba(0, 0, 0, 0.25);
        }

        .welcome_msg {
            width: 570px;
            margin: 60px auto;

            @include media(sm) {
                max-width: 90%;


            }

            h3 {
                font-size: 32px;
                line-height: 48px;
                color: #524F63;

                @include media(sm) {
                    font-size: 20px;

                }

            }
        }

        .imgdgd {
            img {
                @include media(576) {
                    max-width: 90%;
                    height: 67px;
                }
            }

            padding-top: 200px;
            text-align: center;
            margin: 0 auto;

            @include media(576) {
                padding-top: 100px;
            }
        }


    }

    .parent_record {
        p {
            padding-bottom: 40px;
        }

    }

    .parent_period {
        p {
            padding-bottom: 40px;
        }

    }

    .empty_record {
        padding: 3% 0;

        img {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .empty-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 0;
            color: rgba(82, 79, 99, 0.5);
            width: 100%;
            margin: 15px auto 0;
            max-width: 350px;
            @include media(1200) {
                width: 100%;
            }
        }

        //background-color: $mainTransparent;;
    }

    .empty_period {
        padding: 3% 0;

        img {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .empty-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 0;
            color: rgba(82, 79, 99, 0.5);
            width: 100%;
            margin: 15px auto 0;
            max-width: 350px;
            @include media(1200) {
                width: 100%;
            }
        }

        //background-color: $mainTransparent;;
    }

    .record_content {
        nav {
            .nav-tabs {
                background-color: #E6EBF2;
                border-bottom: none;

                .nav-link {
                    color: rgba(82, 79, 99, 0.6);
                    font-weight: 600;
                    //@include responsive-size(18px, 1920, 1850, font-size);
                    //@include responsive-size(27px, 1920, 1850, line-height);
                    font-size: 18px;
                    width: 20%;
                    border: none;

                    &:hover {
                        text-decoration: none!important;
                    }

                    &.active, &.show {
                        color: $secondColor;

                    }

                    @include media(1300) {
                        padding-top: 10px !important;
                        padding-bottom: 10px !important;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }

                @include media(576) {
                    display: block !important;
                    .nav-link {
                        width: 100%
                    }
                }
            }

        }

        .tab-content {
            z-index: 2;
            position: relative;
            background: #fff;
        }
    }

    .directory-header {
        margin-bottom: 24px;
        .category {
            label[data-v-1f6df1a5] {
                margin-bottom: 15px;
            }
        }
    }

    &.login {
        background: transparent;
        box-shadow: none;
    }

    .area_with_forms {
        .tab-content > .tab-pane {
            .translation_buttons {
                margin-top: 29px;

                .cancel {
                    width: 49%;
                    @include media("xs") {
                        width: 100%;

                    }
                }

                .save {
                    width: 48%;
                    @include media("xs") {
                        width: 100%;

                    }
                }
            }
        }
    }

    .add-user {
        .users_buttons {
            text-align: right;

            .main-button.main-button__save {
                @include media(576) {
                    width: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding-bottom: 15% !important;
    }
}

.link_modal_footer {
    margin-top: 45px;
    padding: 0 45px;
    justify-content: flex-end;
    min-height: 80px;
    background: #F2F6FC;
    display: flex;
    align-items: center;
    border-radius: 0 0 6px 6px;

    .footer_buttons {
        display: flex;

        button {
            min-width: 140px;
            width: unset;
            justify-content: center;
            height: 40px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            display: flex;
            align-items: center;
            padding: 0 15px;
        }

        .next_button {
            background: #27AEDE;
            border: none;
            color: #FFFFFF;

            &:hover {
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
            }
        }

        .back_button {
            background: #FFFFFF;
            border: 1px solid rgba(82, 79, 99, 0.3);
            color: rgba(82, 79, 99, 0.7);
            margin-right: 20px;

            &:hover {
                border: 1px solid rgba(82, 79, 99, 0.4);
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
            }
        }
    }
}

.persons-location {
    color: #524F63;
}

.table {
    width: 100%;
    margin-bottom: 0;
}

.form_area {
    .cancel {
        @include media(768) {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    .save {
        @include media(768) {
            width: 99%;
        }
    }

    //margin-top: 30px;
    margin-bottom: 30px;

    @include media(576) {
        padding-right: 20px;
        padding-left: 20px;
    }

    .previous_next {
        @include media(576) {
            padding: 0 30px;
            @include media("xs") {
                padding: 0;
            }
        }
    }

    .add_form_area {
        padding-bottom: 60px;
    }

    .translate_st_rec {
        label {
            display: block;
            color: #524F63;
        }

        .cancel_save_lng {
            width: 100%;
        }

        .form-control {
            display: inline-block;
            color: #524F63;
            width: 91%;
            @include media(768) {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        padding: 90px 0;
        margin: 0 auto;
        width: 50%;
        @include media(992) {
            width: 77%;
        }
        @include media(576) {
            width: 90%;
        }
    }

    .next {
        width: 184px;
        height: 50px;

        @include media(576) {
            width: 100%;

            margin-top: 30px;
        }
    }

    .previous {
        height: 50px;
        width: 184px;

        @include media(576) {
            width: 100%;
        }
    }

}

.bordered {
    width: 100%;
    border-bottom: 2px solid #dfdfdf;
}

.table-blog {
    padding: 0 15px 15px;
}

.remove_group {
    color: #ff2109 !important;
}

.previous {
    cursor: pointer;
    border: 1px solid #524F63;
    width: 184px;
    height: 50px;
    background-color: white;
    color: #524F63;
    border-radius: 4px;

    &:hover {
        border: 1px solid #524F63 !important;
    }

    @include media("xs") {
        width: 100%;
    }
}

.next {
    width: 184px;
    height: 50px;
    background-color: $mainColor;
    color: white;
    border-radius: 4px;
    border: 1px solid $mainColor;
    cursor: pointer;
    @include media("xs") {
        width: 100%;
    }
}

.previous_save {
    padding-top: 330px;
    padding-bottom: 40px;
    @include media(576) {
        padding-top: 150px;
    }
}

.active {
    .profile_icon {
        background-color: $mainColor;
    }
}

.profile_icon {
    height: 40px;
    width: 50px;
    background-color: #706F75;
}

.icon2 {
    -webkit-mask: url('../../public/images/company_admin/placeholder.svg') no-repeat center;
    mask: url('../../public/images/company_admin/placeholder.svg') no-repeat center;
}

.icon1 {
    -webkit-mask: url('../assets/images/user-edit-solid.svg') no-repeat center;
    mask: url('../assets/images/user-edit-solid.svg') no-repeat center;
}

//.icon3 {
//    -webkit-mask: url(' ../assets/images/settings.svg') no-repeat center;
//    mask: url('../assets/images/settings.svg') no-repeat center;
//}

.force-overflow {
    //min-height: 450px;
    background-color: #ffffff;
    margin-bottom: 30px;
}

.emailVerified {
    .verify {
        display: flex;
        align-items: center;
        margin-top: 30px;
        position: relative;
        min-height: 40px;
        background: #F6F0E5;
        border-radius: 4px;
        padding: 8px 15px;

        span {
            position: relative;
            display: flex;
            path {
                fill: #F0A92D;
            }
        }

        &-title {
            font-weight: 600!important;
            font-size: 12px;
            line-height: 18px;
            color: #6D6E71;
        }
        p {
            font-weight: 500;
            font-size: 14px!important;
            line-height: 21px!important;
            color: rgba(82, 79, 99, 0.85) !important;
            margin-bottom: 0;
        }
        hr {
            width: 1px;
            height: 36px;
            background: rgba(114, 112, 130, 0.2);
            border: none;
            margin: 0 10px 0 0;
        }
        div {
            p {
                &:last-child {
                    margin-top: 4px;
                    font-weight: 500;
                }
            }
        }
    }

    .verifyText {
        display: none;
        position: absolute;
        top: 30px;
        left: -5px;
        background: $secondColor;
        color: #fff;
        padding: 2px 30px;
        border-radius: 2px;

        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
        }

        &:after {
            content: '';
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $secondColor;
            position: absolute;
            top: -5px;
            left: 3%;
        }
    }

    span {
        margin-right: 8px;

        &:hover {
            & ~ .verifyText {
                display: block;
            }
        }
    }

    h5 {
        font-size: 15px;
        line-height: 22px;
        font-weight: 300;
        background: $mainTransparent;
        border-radius: 4px;
        text-align: center;
    }
}

.company-admin .file-uploads-drop {
    position: absolute;
    right: 3%;
    top: 18%;
    height: 59%;
    width: 118px;
    opacity: 0;
}
.msgBoxConfirm.modal-dialog {
    .modal-content{
        .modal-footer {
            padding: 0 0 20px !important;
        }
    }
}

.company_settings_tab {
    margin: auto;

    .company_logo {
        background: $mainTransparent;
        display: flex;
        //width: 460px;
        width: 100%;
        height: 135px;
        border-radius: 4px;
        align-items: stretch;
        justify-content: space-between;

        .add_logo, .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            label {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }

        .add_logo {
            width: 150px;
            background: $secondColor;
            opacity: 0.25;
            border-radius: 0 4px 4px 0;
            flex-direction: column;

            input {
                opacity: 0;
                position: absolute;
                width: 60px;
            }

            label {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #fff;
                cursor: pointer;
            }
        }

        .logo {
            width: calc(100% - 150px);
            padding: 15px;
        }
    }

    .company_name {
        label {
            font-weight: 600;
            margin-top: 7%;
        }
    }
}

.company_settings_tab, .emailSetting, .domainSetting {
    label {
        display: flex !important;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #524F63;
        position: relative;
    }
}

.infoIcon {
    .infoText {
        position: absolute;
        background: $secondColor;
        border-radius: 2px;
        display: none;
        z-index: 10;
        padding: 15px;
        left: -2%;
        margin-top: 10px;

        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #FFFFFF;
            margin-bottom: 0;
        }

        &:after {
            content: '';
            position: absolute;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid $secondColor;
            top: -7px;
            left: 13px;
        }
    }

    span {
        &:hover ~ .infoText {
            display: block;
        }
    }
}

i.fa.fa-refresh.toggle_status {
    font-style: normal;
    font-weight: initial;
    color: #007bff;
}

.dropdown-toggle {
    &:after {
        margin-left: 17px;
        vertical-align: 5px;
    }
}

.login.parent_content {
    .login_your_account {
        p {
            font-size: 22px;
            color: $mainColor;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .form-group {
            position: relative;
        }
    }

    p {
        font-size: 20px;
        @include media(576) {
            font-size: 16px;
        }
    }

    .all_page {
        height: 100%;
    }

    height: 100vh;
    padding: 0 !important;
}

.passwordIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    @include media(1500) {
        top: 7px;

        span {
            svg {
                width: 20px !important;
            }
        }
    }
}

#edit_title {
    cursor: pointer;
}

.c-validation {
    text-align: left;
    color: #FF1B1B;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
}

.border_bottom {
    border-bottom: 2px solid #dfdfdf;
}

.edit__translation {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $secondColor;
        bottom: -7px;
        display: none;
    }

    &::before {
        content: (attr(data-title));
        background: $secondColor;
        position: absolute;
        bottom: -45px;
        right: -100%;
        padding: 5px 10px;
        color: #fff;
        z-index: 2;
        display: none;
    }

    &:hover {
        &::after, &::before {
            display: block;
        }
    }
}

.additional-info {
    color: $textTransparent;
}

.admin__company {
    .actions a::after {
        bottom: -8px !important;
    }
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid $secondColor;
            bottom: -2px;
            display: none;
        }

        &::before {
            content: (attr(data-title));
            background: $secondColor;
            position: absolute;
            bottom: -35px;
            left: -100%;
            padding: 5px 10px;
            color: #fff;
            z-index: 2;
            white-space: nowrap;
            display: none;
        }

        &:hover {
            &::before, &::after {
                display: block;
            }
        }

    }

    &-last {
        &:hover {
            &::before, &::after {
                left: auto;
            }
        }
    }

    .toggle_can_see {
        position: relative;

        &::before {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23C4C4C4'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3C/g%3E%3Cpath d='M3.03604 13.8599C2.74209 10.6959 4.09342 7.8299 6.33475 6.00908C7.20842 5.29871 8.51484 5.90702 8.51484 7.02972C8.51484 7.44206 8.31888 7.82174 8.00044 8.07894C6.41232 9.37311 5.457 11.4144 5.6897 13.668C5.99181 16.6033 8.34337 18.9794 11.2747 19.306C15.1 19.7347 18.3497 16.7381 18.3497 12.9984C18.3497 11.0143 17.4312 9.23839 15.9982 8.07486C15.6797 7.81766 15.4879 7.43798 15.4879 7.02972C15.4879 5.91926 16.7739 5.29055 17.6394 5.98458C19.6888 7.63802 21.0034 10.1651 21.0034 12.9984C21.0034 18.1792 16.6024 22.3557 11.34 21.976C6.98796 21.6698 3.44021 18.2037 3.03604 13.8599ZM11.9973 2C11.2624 2 10.6704 2.59605 10.6704 3.32683V9.43844C10.6704 10.1733 11.2665 10.7653 11.9973 10.7653C12.728 10.7653 13.3241 10.1692 13.3241 9.43844V3.32683C13.3241 2.59605 12.7321 2 11.9973 2Z' fill='%23706F75'/%3E%3C/svg%3E");
            width: 24px;
            height: 24px;
            background-size: cover;
            position: absolute;
        }

        &:hover {
            &::before {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23C4C4C4'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3C/g%3E%3Cpath d='M3.03604 13.8599C2.74209 10.6959 4.09342 7.8299 6.33475 6.00908C7.20842 5.29871 8.51484 5.90702 8.51484 7.02972C8.51484 7.44206 8.31888 7.82174 8.00044 8.07894C6.41232 9.37311 5.457 11.4144 5.6897 13.668C5.99181 16.6033 8.34337 18.9794 11.2747 19.306C15.1 19.7347 18.3497 16.7381 18.3497 12.9984C18.3497 11.0143 17.4312 9.23839 15.9982 8.07486C15.6797 7.81766 15.4879 7.43798 15.4879 7.02972C15.4879 5.91926 16.7739 5.29055 17.6394 5.98458C19.6888 7.63802 21.0034 10.1651 21.0034 12.9984C21.0034 18.1792 16.6024 22.3557 11.34 21.976C6.98796 21.6698 3.44021 18.2037 3.03604 13.8599ZM11.9973 2C11.2624 2 10.6704 2.59605 10.6704 3.32683V9.43844C10.6704 10.1733 11.2665 10.7653 11.9973 10.7653C12.728 10.7653 13.3241 10.1692 13.3241 9.43844V3.32683C13.3241 2.59605 12.7321 2 11.9973 2Z' fill='%2348C89E'/%3E%3C/svg%3E");
                cursor: pointer;
            }
        }
    }

    .fa {
        padding-left: 5px;
        padding-right: 10px;
        color: #007bff;

        &:hover {
            color: #0056b3;
        }
    }

    .more_actions {
        display: none;
        position: absolute;
        width: 100px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        bottom: -50%;
        background: #fff;
        z-index: 2;
        padding: 3px 7px;

        a {
            justify-content: flex-start;

            svg {
                margin-right: 5px;
            }
        }

        &::before {
            content: '';
            width: 7px;
            height: 7px;
            border-bottom: 7px solid #fff;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            position: absolute;
            top: -7px;
            left: 50%;
            z-index: 3;
        }

        &::after {
            content: '';
            width: 8px;
            height: 8px;
            border-bottom: 8px solid #d7d7d7;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            position: absolute;
            top: -8px;
            left: 49%;
        }
    }

    a.show_action {
        &:hover {
            &::before, &::after {
                display: none !important;
            }

            & ~ .more_actions {
                display: block;
            }
        }
    }

    .comp_admin_action {
        .dropdown-toggle {
            display: flex;
            align-items: center;
            padding: 0;

            &:focus {
                box-shadow: none !important;
            }

            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            min-width: 6rem;
            background: #FFFFFF;
            border-radius: 0;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            left: -100% !important;

            &:after {
                content: '';
                width: 7px;
                height: 7px;
                border-bottom: 7px solid #fff;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                position: absolute;
                top: -5px;
                left: 55%;
            }

            a {
                justify-content: flex-start;
                padding: 2px 7px;
                margin: 5px 0;
                font-weight: 600;
                font-style: normal;
                font-size: 14px;
                line-height: 21px;
                color: #524F63;

                &:active {
                    background: #f8f9fa;
                }

                &:hover {
                    &:after, &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .comp_admin_export {
        p {
            background: $secondColor;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #FFFFFF;
            text-align: center;
            padding: 6px;
        }

        .dropdown-menu {
            padding: 0;
            left: -45px !important;

            a {
                flex-direction: column;
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                color: $secondColor;
                padding: 5px 15px;
                margin: 10px 0;
            }

            &:after {
                border-bottom: 7px solid $secondColor;
                right: 30%;
                left: auto !important;
            }
        }
    }
}

.tools {
    i {
        display: block;
    }

    .drag {
        border: 1px solid #d1d1d1;
        border-right: none;
        width: 30px;
        height: 24px;
        cursor: pointer;
        text-align: center;
        color: #000;
        background: #f8f8f8;
        padding: 2px;
    }
}

.limited_select {
    select {
        max-width: 150px;
    }
}

#holder {
    canvas {
        max-width: 80%;
        margin-top: 20px;
    }

    background: #525659;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 98vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.strip {
    width: 90%;
    border: 1px solid #dfd6d6;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.add-record {
    border: none;
    background: #FFFFFF;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
    will-change: transform;
    top: 50px !important;
    left: 15px;

    &:before {
        position: absolute;
        right: 10px;
        top: 0;
        content: '';
        width: 15px;
        height: 15px;
        background-color: white;
        transform: rotate(135deg);
        transform-origin: 0 0;
        box-shadow: -3px 3px 3px -3px rgba(0, 0, 0, 0.35);
    }

    .some_recordes {
        border-left: 5px solid transparent;
        margin: 5px 0;

        &:hover {
            background: $mainTransparent;
            border-left: 5px solid $mainColor;
        }
        &:active {
           color: inherit;
        }
    }

    img {
        margin-right: 10px;

    }
}

.info_desc {
    position: relative;
    cursor: pointer;

    &::after {
        content: attr(data-title);
        background: $secondColor;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        padding: 15px 25px;
        width: 270px;
        height: 120px;
        border-radius: 3px;
        position: absolute;
        bottom: 50%;
        transform: translate(10px, 45%);
        display: none;
        left: 100%;
        z-index: 3;
    }

    &::before {
        content: '';
        position: absolute;
        border-top: 8px solid transparent;
        border-right: 8px solid $secondColor;
        border-bottom: 8px solid transparent;
        right: -0.5px;
        bottom: 50%;
        transform: translate(10px, 45%);
        display: none;
        z-index: 3;
    }

    &:hover {
        &::after, &::before {
            display: block;
        }
    }
}

.header_part {
    .dropdown-item {
        &:hover {
            background-color: $mainTransparent;
        }
    }
}

.message {
    position: fixed;
    top: 20px;
    right: 0;
    display: flex;
    align-items: center;
    min-width: 200px;
    padding: 20px;
    color: white;
    z-index: 999999999;

    i {
        margin-right: 5px;
    }
}

.message-success {
    background-color: rgb(113, 221, 140);
}

.message-danger {
    background-color: rgba(255, 93, 85, 0.8);
}

.risk-category {
    background-color: $mainTransparent !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
}

.error {
    border: 1px solid red !important;
    outline-color: red;
}

.warning {
    border: 1px solid #F2C94C !important;
    outline-color: #F2C94C;
}

.translation-group {
    .source_text {
        width: 45%;

        @media (max-width: 991px) {
            width: 100%;
        }
    }

    .translation-checkbox {
        .source_text {
            width: 100%;
        }
    }
}

.home-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &_logo {
        width: 250px;
        height: auto;
    }

    &_text {
        min-width: 250px;
        width: 60%;
        color: #6d6e71;
        margin-top: 30px;

        h3 {
            text-align: center;
        }
    }
}

.dropdown-wrapper {
    position: relative;

    &:hover {
        .view-actions {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.info-title{
    text-align: right;
    h5{
        display: inline-block;
        padding: 15px;
        background: rgba(64, 146, 196, 0.1);
        border-radius: 6px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        opacity: 0.8;
        margin-bottom: 0;
        span{
            margin-right: 10px;
        }
    }
}
.toggle-actions-list {
    width: 15px;
}

.view-actions {
    position: absolute;
    right: -9px;
    top: 29px;
    display: none;
    min-width: 70px;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.35);
    padding: 5px 7px;
    background: #FFFFFF;
    z-index: 10;

    &.show {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:before {
        position: absolute;
        right: -4px;
        top: 0px;
        content: '';
        width: 15px;
        height: 15px;
        background-color: white;
        transform: rotate(135deg);
        transform-origin: 0 0;
        box-shadow: -3px 3px 3px -3px rgba(0, 0, 0, 0.35);
    }
}

a {
    color: $mainColor;

    &:hover {
        color: $secondColor;
    }
}

.message-area {
    display: flex;
    align-items: center;
    border: 1px solid $mainColor;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 15px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #524F63;

    & > img {
        margin-right: 15px;
    }

    @media (max-width: 767px) {
        width: 100%;
    }

}

.height-50 {
    height: 50px;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.phone-code {
    .flag-icon {
        margin-right: 10px;
    }
}

.trans-actions {
    span:hover {
        cursor: pointer;
    }
}

.translated {
    position: absolute;
    top: 15px;
    right: 30px;
}

.pr-50 {
    padding-right: 50px;
}

.trans-warning-sign {
    position: absolute;
    top: 12px;
    right: 13px;

    img {
        width: 25px;
    }
}

.z-1 {
    z-index: 1 !important;
}

.edit-translation {
    position: absolute;
    right: 25px;

    &:hover {
        cursor: pointer;
    }

    .checked-trans {
        rect {
            fill: $mainColor;
        }
    }
}

.nav-wrapper {
    .main-select.IZ-select.IZ-select--with-value {
        &:focus {
            outline: none !important;
        }

        .IZ-select__input-wrap {
            .IZ-select__input {
                width: auto;

                input {
                    width: 165px;
                    height: 40px !important;
                    padding: 0 0 0 10px !important;
                    border: 1px solid rgba(64, 146, 196, 0.5);
                    color: #4092C4;
                }

                span {
                    position: absolute;
                    top: 8px;
                    right: 10px;
                }
            }
        }

        .IZ-select__menu {
            min-width: 193px;
            top: 45px !important;
            right: 0 !important;
            left: unset !important;
            text-align: left;
            z-index: 100;

            &-items {
                .IZ-select__item {
                    padding-left: 6px !important;
                    margin-top: 5px;

                    &:nth-last-child(2) {
                        margin-bottom: 5px !important;
                    }
                }
            }
        }
    }
}

.main-width {
    .form-group {
        .period-label {
            .tooltip-button {
                padding: 0;
                margin-right: 5px;
                margin-top: 2px;
                background: #fff;
            }

            .z-tooltip {
                z-index: 1;
            }
        }

        .main-select.IZ-select {
            .IZ-select__input-wrap {
                .IZ-select__input {
                    input::placeholder {
                        color: rgba(82, 79, 99, 0.4);
                    }
                }
            }

            .IZ-select__menu {
                text-align: left;

                &-items {
                    .IZ-select__item {
                        margin-top: 5px;

                        &:last-of-type {
                            margin-bottom: 5px !important;
                        }
                    }
                }
            }
        }
    }

    .tree-select {
        .vue-treeselect.vue-treeselect--multi.vue-treeselect--searchable.vue-treeselect--open-below {
            .vue-treeselect__control {
                padding-right: 10px;

                .vue-treeselect__value-container {
                    .vue-treeselect__multi-value {
                        .vue-treeselect__placeholder {
                            margin-top: 7px;
                        }
                    }
                }

                .vue-treeselect__control-arrow-container {
                    .vue-treeselect__control-arrow {
                        width: 12px;
                        height: 11px;
                    }
                }
            }
        }
    }
}



.span-link {
    cursor: pointer
}

.login_your_account .alert-success {
    color: $mainColor !important;
    background-color: rgba(#27AEDE, 0.15) !important;
    border-color: transparent !important;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    padding: 15px 60px;
}

.login .alert-success {
    color: $mainColor !important;
    background-color: $secondTransparent !important;
    border-color: transparent !important;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    padding: 15px 60px;
}

.alert-message {
    border-radius: 5px;
    padding: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;

    &_error-area {
        //background: rgba(235, 87, 87, 0.15);

        span {
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #EB5757;

            //&:last-of-type {
            //    margin-left: 15px;
            //}

            @include media(1200) {
                font-size: 16px;
                line-height: 24px;

                svg {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }

    &_success-area {
        background: rgba(72, 200, 158, 0.15);

        span {
            margin-left: 15px;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: $success_green;

            @include media(1200) {
                font-size: 16px;
                line-height: 24px;

                svg {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }
}

.h-auto {
    height: auto !important;
}

.language-name {
    color: $mainColor;
    font-weight: 600;
    font-size: 16px;
}

.mx-input-wrapper {
    input {
        margin: 0 !important;
        box-shadow: none;
    }
}

.container {
    position: relative;
    max-width: 100%;
}

@media (max-width: 460px) {
    .col-xs-6 {
        margin: 0 auto;
    }
}

.jq-toast-wrap {
    width: auto;

    .jq-toast-single {
        padding: 18px 20px;

        & > div {
            display: flex;
            align-items: center;
        }

        .toast-image {
            margin-right: 18px;
        }

        .toast-message {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.dpo-select {
    .select2-results {
        .select2-results__options {
            .select2-results__option {
                &:first-child {
                    &[aria-selected=false] {
                        color: $buttons_blue !important;
                    }

                    &:hover {
                        color: white !important;
                    }
                }
            }
        }
    }
}

.info-small {
    position: absolute;
    bottom: 10px;
    color: $mainColor;
}

.allowText {
    background: #FBFCFF;
    border: 1px solid rgba(82, 79, 99, 0.1);
    padding: 10px;
    position: absolute;
    min-width: 130px;
    text-align: center;
    top: 30px;

    &:hover {
        cursor: pointer;
    }

    p {
        font-size: 14px;
        line-height: 21px;
        color: rgba(82, 79, 99, 0.5);
        margin-bottom: 0;
    }
}

.moreDots {
    color: rgba(112, 111, 117, 0.2);
    font-size: 30px;
    letter-spacing: -3px;
    font-weight: bold;
    cursor: pointer;
    line-height: 1px;
}

.cursor-pointer {
    cursor: pointer;

    &:hover {
        cursor: pointer;
    }
}

.deactivate-page {
    .login_your_account {
        max-width: 430px;
        width: 100%;

        .deactivate-msg {
            padding: 25px 20%;

            h5 {
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: rgba(#524F63, 0.5);
            }
        }

        .deactivate-account {
            margin-top: 0;
        }
    }
}

.deactivate-account {
    margin-top: 7%;

    .deactivate-msg {
        background: $mainTransparent;
        border-radius: 4px;
        padding: 25px 15%;
        text-align: center;

        h5 {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 0;
            color: rgba(#524F63, 0.5);
        }
    }

    .deactivate-info {
        list-style-type: none;
        margin: 5% 0 0;

        li {
            font-size: 16px;
            line-height: 24px;
            position: relative;
            margin: 3% 0;

            &::after {
                content: '.';
                font-size: 50px;
                color: $secondColor;
                position: absolute;
                font-weight: bold;
                left: -25px;
                top: -15px;
            }
        }
    }
}

.text-green {
    color: #2DC996 !important;
}

.text-red {
    color: #FF7676 !important;
}

.IZ-select__no-data {
    padding: 2% 5%;
    color: #27aedf;
}

.translation-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(64, 146, 196, 0.5);

    .mainFilter.trans-filter {
        width: 60% !important;

        .mainFilter-action.mainFilter-filter {
            justify-content: flex-end;

            .filter-search {
                margin: 0;
            }
        }
    }
}

.translation__blog {
    width: 40%;

    .tran-fr {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $mainColor;

        @include media(1400) {
            font-size: 14px;
        }
    }

    .translate_from {
        width: auto;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 4px;
        outline: none;
        background-color: $mainTransparent;

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #6D6E71;

            @include media(1400) {
                font-size: 14px;
            }
        }
    }
}

.not-verified-email-container {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    border-radius: 4px;
    margin-top: 15px;
    padding-left: 7px;
    background: $mainTransparent;

    h5 {
        margin-bottom: 0;
        margin-left: 8px;
    }

    div {
        height: 100%;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 4px 4px 0;
        background: rgba(39, 174, 222, 0.1);

        span {
            svg {
                path {
                    fill: #27AEDE;
                }
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.tabContent {
    background: #FFFFFF;
    border-radius: 6px;
    height: 100%;

    nav {
        background: #E6EBF2;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        z-index: 1;
        position: relative;

        .nav-tabs {
            border-bottom: none !important;
        }

        .nav-link {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: rgba(82, 79, 99, 0.6);
            padding: 15px 40px;

            &.active {
                color: #4092C4 !important;
                border: none;
                position: relative;
                box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);

                &:after {
                    content: '';
                    width: 100%;
                    height: 10px;
                    background: #fff;
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                }
            }

            &:hover {
                border-color: transparent;
            }

            &:not(.active):not(:first-child):not(.active + .nav-link) {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 30%;
                    width: 1px;
                    height: 25px;
                    background: rgba(82, 79, 99, 0.3);
                    border: none;
                }
            }
        }
    }

    & > div {
        padding: 25px;
        box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
        height: calc(100% - 58px);
    }

    .tabs {
        height: calc(100% - 80px);

        & > div {
            display: flex;
            flex-direction: column;
            height: 100%;

            .tab-pane, .tab-pane > div, .small-tb {
                height: 100%;

            }
        }
    }

    .table-blog {
        height: calc(100% - 150px);

        .tab-pane {
            margin-bottom: 27px;
        }
    }
}

.tableContent {
    background: #fff;
    box-shadow: 0 2px 9px rgb(0 0 0 / 10%);
    border-radius: 6px;
    padding: 25px 25px 25px !important;
    min-height: calc(100% - 135px);
    position: relative;

    .generateChild {
        height: calc(100% - 100px);
    }
    @include media(1400){
        height: calc(100% - 95px);
    }
}

.parent_content .suggested {
    margin-top: 0;
    padding: 19px 25px;

    h3 {
        color: $secondColor;
        display: flex;
        align-items: center;
        a {
            color: rgba(82, 79, 99, 0.3);
            &:hover {
                text-decoration: none!important;
            }
        }
        span{
            margin: 0 5px;
        }
    }
}

.info-small-new {
    display: flex;
    align-items: center;
    height: 50px;
    color: $secondColor;
}

.header-arrow {
    @include responsive-size(15px, 1920, 1200, font-size);
    color: $labelTextColor;
    opacity: 0.3;
    margin-right: 5px;
}

.bottom-label {
    display: flex;
    //justify-content: flex-end;
    flex-grow: 1;
    margin-top: 22px;

    &__text {
        margin-left: 10px;
        span {
            font-size: 14px;
            color: $labelTextColor;

            &:hover {
                color: $labelTextColor;
            }
        }
    }
}

.fs-10 {
    font-size: 10px;
}

.header-company-name {
    display: inline-block;
    margin: 0;
    font-size: 18px;
    line-height: 27px;
    color: $labelTextColor;
}

.toggle_status {
    .active {
        path {
            fill: #48C89E;
        }
    }

    .active_stroke {
        path {
            stroke: #48C89E;
        }
    }
}

.success-color {
    color: #48C89E;
    font-weight: 500;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p0 {
    padding: 0 !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.fullscreen {
    overflow-y: scroll;
}

.parent_content {
    .box_shadow.suggested {
        .page-header-wrapper {
            div {
                h3 {
                    .tooltip-button.tooltip-line-height {
                        span {
                            @include media(1500) {

                                svg {
                                    width: 17.5px;
                                    height: 17.5px;
                                }
                            }

                            @include media(1200) {

                                svg {
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.log-out {
    cursor: pointer;
    background: #F2F6FC;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 50px;
    margin-left: auto;
    padding: 10px 15px;
    border: 1px solid transparent;

    span {
        display: flex;
        align-items: center;
    }

    p {
        margin-bottom: 0 !important;
        font-weight: 500!important;
        font-size: 14px !important;
        line-height: 21px;
        color: #524F63!important;
        margin-left: 10px;
    }

    .log_out_hover {
        display: none;
    }
    &:hover {
        border: 1px solid rgba(64, 146, 196, 0.7);
        .log_out_hover {
            display: block;
        }
        .log_out_icon {
            display: none;
        }
    }
}

.agreement {
    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #524F63;
    }

    .company_agreement {
        padding: 10px 25px;

        label {
            display: flex;

            p {
                font-size: 14px !important;
                line-height: 21px;
                text-align: justify;
                color: rgba(#524F63, 0.5);
                @include media(1200) {
                    text-align: left;
                }
                a {
                    color: $secondColor;
                    &:hover {
                        text-decoration: underline!important;
                    }
                }
            }
        }

    }

    .container1 input ~ .checkmark {
        bottom: unset;
        top: 3px;
    }
}

.regContent {
    display: flex;
    justify-content: center;
    background: #fff;
    gap: 120px;
    padding: 90px 200px;

    .reg-form {
        width: 70%;

        .conitinue-btn {
            display: block;
            min-width: 200px;
            margin-left: auto;
            margin-top: auto;
            font-size: 16px;
            line-height: 24px;
        }
    }

    @include media(1400) {
        padding: 20px 0;

        .reg-form {
            .agreement .company_agreement {
                padding: 10px 0 25px 25px;
            }

            .main__button--big {
                font-size: 16px;
            }
        }

        .reg-plan {

            &__item {
                .plans {
                    padding: 10px 20px;

                    p {
                        font-size: 16px;
                    }
                }

                h4 {
                    font-size: 18px;
                }
            }
        }
    }
}

.radio-blue-wrapper {
    margin-right: 19px;
    position: relative;
     .radio-blue-label {
        cursor: pointer;
    }
    label {
        &:last-child {
            margin-left: 30px;
            margin-bottom: 6px!important;
        }
    }
}

.reg-form, .reg-plan {

    h5 {

        color: $secondColor;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        border-bottom: 2px solid rgba(#4092c4, 0.2);
        width: 97.5%;
        margin: 0 auto 45px;
        padding-bottom: 15px;
    }

    .form-group {
        margin-bottom: 20px;
    }
}

.plan-header {
    background: #fff;
    padding: 1% 2%;
    margin: 10px 0;
    display: flex;
    align-items: center;

    h3 {
        font-weight: bold;
        font-size: 26px;
        line-height: 39px;
        color: #4092C4;
    }

    a {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #524F63;
        border-right: 1px solid;
        margin-right: 10px;
        padding-right: 20px;

        &, span {
            display: flex;
            align-items: center;
        }

        span {
            margin-right: 5px;
        }
    }
}

.warningText {
    background: rgba(242, 201, 76, 0.1);
    border: 1px solid rgba(242, 201, 76, 0.3);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 2%;
    color: #524F63;
    margin-bottom: 45px;

    h3 {
        font-weight: bold;
        font-size: 20px;
        line-height: 31px;
        margin-bottom: 2%;
        display: flex;
        align-items: flex-end;
        color: $textColor;
    }

    p {
        font-size: 14px;
        line-height: 21px;
        text-align: justify;
        color: $textColor;
    }

    @include media(1500) {
        padding: 4%;

        h3 {
            font-size: 18px;
            line-height: 28px;
        }

        svg {
            width: 28px;
            height: 28px;
        }
    }

    @include media(992) {
        h3 {
            font-size: 16px;
            line-height: 24px;
        }

        svg {
            width: 24px;
            height: 24px;
        }

        p {
            font-size: 12px;
        }
    }
}

@include media(1200) {
    .warningText {
        width: 80%;
        margin: 0 auto 8%;
    }
}

@include media(992) {
    .warningText {
        width: 90%;
    }
}

@include media(768) {
    .warningText {
        width: 100%;
    }
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loading-overlay-block {
    content: " ";
    z-index: 10;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
}

.disabled-button {
    pointer-events: none;
    background: #62B9D8;
    color: rgba(255, 255, 255, 0.8);
}

.disable_input {
    cursor: not-allowed;
    opacity: 0.6!important;
    &:focus {
        outline: none!important;
    }
}

.account_settings {
    .access-level-select {
        input:hover {
            cursor: pointer;
        }
    }
}

#scroll, #tabsContent{
    height: calc(100% - 40px);
}

    .right-tools__language {
        .languageIcon {

        }

        .langCode {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            margin: 0 20px 0 10px;
        }

        .switchLnaguage {
            width: 140px;
            background: #FFFFFF;
            box-shadow: 0px 1px 10px rgb(64 146 196 / 16%);
            border-radius: 4px;
            border: none;
            padding: 15px 0;
            margin-top: -15px;
            margin-left: -15px;
            display: none;
            position: absolute;
            top: 88px;
            z-index: 99;

            &::before {
                content: '';
                width: 10px;
                height: 10px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 9px solid rgba(148, 148, 148, 0.05);
                position: absolute;
                top: -10px;
                right: 22px;
            }

            &::after {
                content: '';
                width: 10px;
                height: 10px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid #fff;
                position: absolute;
                top: -10px;
                right: 22px;
            }
            a{
                color: $mainColor;
            }
            .dropdown-item.active {
                background: rgba(64, 146, 196, 0.1);
                border-left: 5px solid #4092C4;
                text-decoration: none!important;
            }
        }

        .transform {
            transform: rotate(180deg);
            display: flex;
        }

        .change_language {
            display: flex;
            align-items: center;
            padding: 8px 15px !important;
            border-left: 5px solid #fff;

            .menuFlag {
                width: 24px;
                height: 24px;
                margin-right: 7px;
                border-radius: 50%;
                overflow: hidden;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

