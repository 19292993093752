$sm: 576px;
$md: 767px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;

@function translate-media-condition($c) {
  $condMap: (
          "screen": "only screen",
          "print": "only print",
          "retina": "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: calc(3/2)), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
          "xs": "(max-width: #{$sm})",
          "sm": "(max-width: #{$md})",
          "md": "(max-width: #{$lg})",
          "lg": "(max-width: #{$xl})",
          "xl": "(max-width: #{$xxl})",
          "xxl": "(min-width: #{$xxl + 1 })",
  );
  @return map-get( $condMap, $c );
}

// The mdia mixin
@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ( $query != "" ) {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query}  { @content; }
}


@mixin media($value) {
    @media only screen and (max-width: $value + 'px') {
        @content;
    }
}

@mixin mediaDistance($maxValue, $minValue) {
    @media only screen and (max-width: $maxValue + 'px') and ( min-width: $minValue + 'px') {
        @content;
    }
}

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: #{$min-value} + calc(#{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }

    @media (min-width: $max-vw ) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}

@function strip-unit($number) {
    @return $number;
}

@mixin responsive-size($value, $max-vw, $min-vh: 320, $property: height) {
    //$coefMax: $max-vw / 1920;
    //$minFontSize: calc(strip-unit($value) * $min-vh / 1920) + 0px;
    //$maxFontSize: strip-unit($value) * $coefMax;
    //@include fluid-type($property, $min-vh + 0px, $max-vw, $minFontSize, $maxFontSize)

    $coefMax: $max-vw / 1920;
    $minFontSize: strip-unit($value) + 0px;
    $maxFontSize: strip-unit($value) * $coefMax;
    @include fluid-type($property, $min-vh + 0px, $max-vw, $minFontSize, $maxFontSize)
}

@mixin flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin align-items($value) {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
    align-items: $value;
}

@mixin justify-content($value) {
    @if $value == space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    }@else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }
    justify-content: $value;
}

@mixin flex-direction($value) {
    @if $value == column {
        -webkit-box-orient: vertical;
    } @else if $value == row {
        -webkit-box-orient: horizontal;
    }
    -webkit-box-direction: normal;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin width($value){
    width: calc(#{$value} - 1.5%) !important;
}

@for $i from 10 through 100 {
    .width--#{$i} {
        @include width($i * 1%);
    }
    .h-#{$i}{
        height: #{$i * 1%};
    }
}
