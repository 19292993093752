.parent_content {
    .form-control {
        height: 50px;
        padding: 0 15px;
        font-family: $mainFont, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        border-radius: 4px;
        border: transparent;
        background-color: $mainTransparent!important;
        color: $textColor;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            //box-shadow: 0 0 0 0.1rem $secondTransparent !important;
            box-shadow: unset!important;
            //border: 1px solid rgba(64, 146, 196, 0.5);
            background-color: $mainTransparent;
        }

        &::placeholder {
            opacity: 0.4;
        }

        @include media(1200) {
            height: 40px !important;
            font-size: 14px;
        }
    }

    .form-control.errorBorder {
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.08) !important;
            outline: none;
        }
    }

    .form-control:disabled, .form-control[readonly] {
        background-color: $mainTransparent;
        opacity: 1;
        border: none;
    }
}


.errorBorder input {
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.08) !important;
        outline: none!important;
    }
}

.errorBorder {
    border: 1px solid rgba(255, 27, 27, 0.6) !important;

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.08) !important;
        outline: none!important;
    }
}

/* Turn Off Number Input Spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* ===================== FILE INPUT START ===================== */

.file-area {
    width: 100%;
    position: relative;
}

.file-area input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}

.file-area .file-dummy {
    width: 100%;
    padding: 15px;
    background: rgba(255, 255, 255, 0.2);
    border: 2px dashed rgba(255, 255, 255, 0.2);
    text-align: center;
    transition: background 0.3s ease-in-out;
    color: $mainColor;
    text-decoration: underline;
    cursor: pointer;

    .default {
        color: $mainColor;
        text-align: center;
        font-size: 30px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.browses_fils {
    &:hover {
        .default {
            text-decoration: underline;
        }
    }
}

.file-area .file-dummy .success {
    display: none;
}

.file-area:hover .file-dummy {
    background: rgba(255, 255, 255, 0.1);
}

.file-area input[type="file"]:focus + .file-dummy {
    /*outline: 2px solid rgba(255, 255, 255, 0.5);*/
    /*outline: -webkit-focus-ring-color auto 5px;*/
}

.file-area input[type="file"]:valid + .file-dummy {
    /*border-color: rgba(0, 255, 0, 0.4);*/
    /*background-color: rgba(0, 255, 0, 0.3);*/
}

.file-area input[type="file"]:valid + .file-dummy .success {
    display: inline-block;
}

.file-area input[type="file"]:valid + .file-dummy .default {
    display: none;
    color: $mainColor;
    text-decoration: underline;
    cursor: pointer;
}

/* ===================== FILE INPUT END ===================== */

.h-40 {
    height: 40px !important;
}

.h-44 {
    height: 44px !important;
}
