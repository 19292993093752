.period-default-container {

    &.period-main-container {
        width: 25%;
        margin: 0 auto;
        min-width: 320px;

        @include media(1200) {
            width: 35%;
        }

        .add-period-heading {
            margin: 60px auto 0;
            width: 350px;
        }

        .form-group {
            //color: rgba(82, 79, 99, 0.4);
            //
            //.form-control::placeholder {
            //    color: rgba(82, 79, 99, 0.4);
            //}

            .period-label {
                display: flex;
                text-align: left;
                line-height: 24px;
            }
        }

        .line-span {
            width: 35%;
            height: 0;
            border: 1px solid rgba(39, 174, 222, 0.3);
        }

        .period-total-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 15px 0;

            .period-total {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.4em;
                color: #524F63;
            }
        }

        .period-sum {
            width: 100%;
            background: rgba(39, 174, 222, 0.1);
            border-radius: 4px;
            padding: 10px 0;
            margin-bottom: 40px;

            &-text {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: rgba(39, 174, 222, 0.7);
            }
        }

        .info-icon-wrapper {
            bottom: 3px;
            left: 8px;
        }

        .period-btn {
            width: 180px;
            max-width: calc(50% - 10px);
        }
    }

    .days-period {
        padding: 15px 0;
    }

    .add-period-heading {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        padding-bottom: 0;
        color: #524F63;
        width: 100%;
        margin: 50px auto 0;

        .add-period-heading-svg {
            margin-bottom: 4px;
        }
    }

    .heading-size {
        font-size: 14px;
        line-height: 21px;
    }

    .period-heading-sm {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #524F63;
        width: 100%;
    }

    .heading-sm {
        margin: 0 auto;
        max-width: 244px;
    }

    .add-period-default-bg {
        width: 100%;
        border-radius: 4px;
        background: #f2f6fc;
        margin: 33px auto 0;
        max-width: 570px;

        .add-period-heading-sm {
            margin: 25px auto 0;
            padding-bottom: 20px;
            max-width: 350px;
        }

        .period-heading-list {
            padding-bottom: 20px;
        }

        .point {
            color: #27AEDE;

            &:not(:last-child) {
                margin-bottom: 1px;
            }

            &::before {
                content: "";
                display: block;
                width: 5px;
                height: 5px;
                margin-right: 7px;
                border-radius: 50%;
                background: rgba(39, 174, 222, 0.4);
            }
        }
    }

    .period-line {
        width: 15px;
        margin: 34px auto;
        border: 1px solid #27AEDE;
    }

    .period-note {
        width: 100%;
        max-width: 656px;
        padding: 15px 0 12px 0;
        margin: 0 auto 50px;
        border-top: 1px solid rgba(82, 79, 99, 0.2);
        border-bottom: 1px solid rgba(82, 79, 99, 0.2);

        .period-note-info {
            font-size: 14px;
            line-height: 21px;
            margin: 0;
            color: #524F63;
        }

        .period-sub-note {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            padding: 11px 0 0 12px;
        }
    }

    .period-btn {
        padding: 0;
        height: 40px;
        font-weight: 500;
    }

    .days-period-btn {
        width: 210px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        border: none;
        box-shadow: inset -2px -2px 4px #E4EDF2;
        opacity: 1 !important;
    }

    .edit-period-btn {
        width: 100px;
        margin-bottom: 30px;
    }

    .add-period-btn {
        margin: 0 auto 53px;
        width: 235px;
    }
}

.users-period {
    .form-main {
        @include media(992) {
            max-width: unset !important;
        }
    }
}

.countries-period {
    .form-main {
        @include media(992) {
            max-width: unset !important;
        }
    }
}
