/*------- Blue Radio Start ------*/

.radio-blue {
    &-wrapper {
        display: flex;
        align-items: center;
    }

    &-label {
        width: 20px;
        height: 20px;
        border: 2px solid rgba(112, 111, 117, 0.6);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        border-radius: 50%;
        &:hover {
            border: 2px solid rgba(112, 111, 117, 1);
        }
    }

    &:checked {
        + .radio-blue-label {
            border: 2px solid #27AEDE;
            &:after {
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                border-radius: 50%;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background-color: #27AEDE;
            }

            &:hover {
                border: 2px solid #1F9AC7;
                &:after {
                    background-color: #1F9AC7;
                }
            }
        }
    }
}

.radio-blue-label.error_checkbox {
    border: 2px solid rgba(255, 27, 27, 0.6);
}

/*------- Blue Radio End ------*/
