.content {
    header {
        //position: static;
        width: 100%;
        min-height: 100px;
        background: #fff;
        padding: 14px 2%;
        z-index: 10;
        @include flex;
        @include justify-content(space-between);

        .user-burger {
            @include flex;
            @include align-items(center);

            .user-tools {
                @include flex;
                @include align-items(center);
                background: #F2F6FC;
                border-radius: 5px;
                padding: 6px 16px;
                min-width: 280px;
                border: 1px solid transparent;
                &:hover {
                    border: 1px solid rgba(64, 146, 196, 0.7);
                }

                .user-info {
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                    }

                    .avatars_border {
                        border: 1px solid rgba(82, 79, 99, 0.15);
                    }
                }

                .notification {
                    @include flex;
                    @include align-items(center);
                    width: 100%;

                    &-dpr {
                        margin: 0 11px;

                        .dropdown {
                            .btn {
                                box-shadow: none;
                                background: transparent;
                                font-weight: 500;
                                font-size: 14px;
                                border: none;
                            }

                            &-toggle {
                                display: flex;
                                align-items: center;

                                &::after {
                                    margin-left: 15px;
                                    border-top: 8px solid $mainColor;
                                    border-right: 8px solid transparent;
                                    border-left: 8px solid transparent;
                                }
                            }

                            &-menu {
                                min-width: 310px;
                                background: #FFFFFF;
                                box-shadow: 0px 1px 10px rgb(64 146 196 / 16%);
                                border-radius: 4px;
                                margin-top: 20px;
                                border: none;
                                padding: 15px 15px 0;
                                position: relative;
                                transform: translate3d(-80px, 32px, 0px) !important;

                                .profile__type {
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 18px;
                                    color: $mainColor;

                                    &.dropdown-item {
                                        &:hover, &:focus {
                                            background: none;
                                        }
                                    }

                                    span {
                                        font-size: 14px;
                                        color: #524F63;
                                        margin-left: 5px;
                                    }
                                }


                                li {
                                    svg {
                                        fill: $mainColor;
                                    }

                                     a {
                                        &.dropdown-item {
                                            color: #524F63;
                                            font-weight: 500;
                                            font-size: 14px;

                                            &:active {
                                                color: #000000;
                                                background-color: #ffffff;
                                            }

                                            &:hover {
                                                padding-left: 19px;
                                                background-color: rgba(64, 146, 196, 0.1);
                                                border-left: 5px solid #4092C4;
                                                text-decoration: none!important;
                                            }
                                        }
                                    }

                                    &.logOut {
                                        a {
                                            display: flex;
                                            justify-content: space-between;
                                        }
                                    }
                                }

                                &::before {
                                    content: '';
                                    width: 10px;
                                    height: 10px;
                                    border-left: 10px solid transparent;
                                    border-right: 10px solid transparent;
                                    border-bottom: 9px solid rgba(148, 148, 148, 0.05);
                                    position: absolute;
                                    top: -10px;
                                    right: 22px;
                                }

                                &::after {
                                    content: '';
                                    width: 10px;
                                    height: 10px;
                                    border-left: 8px solid transparent;
                                    border-right: 8px solid transparent;
                                    border-bottom: 8px solid #fff;
                                    position: absolute;
                                    top: -10px;
                                    right: 22px;
                                }
                            }
                        }
                    }

                    .notifications {
                        display: inline;
                        position: relative;
                        user-select: none;

                        &-icon {
                            display: inline-block;
                            vertical-align: top;
                            position: relative;

                            @include media(1200) {
                                margin-top: 0;
                                margin-left: 0;
                            }
                            @include media(576) {
                                margin-top: 0;
                                margin-left: 0;
                            }

                            .num-count {
                                position: absolute;
                                top: -9px;
                                right: -13px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 20px;
                                height: 20px;
                                border: 1px solid $secondColor;
                                border-radius: 100%;
                                font-size: 12px;
                                line-height: 14px;
                                text-align: center;
                                color: #EB5757;

                                @include media(576) {
                                    top: 4px;
                                    right: -8px;
                                    width: 15px;
                                    height: 15px;
                                    font-size: 10px;
                                    line-height: 11px;
                                }
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        &-list {
                            position: absolute;
                            width: 325px;
                            right: -40px;
                            top: 57px;
                            background: #FFFFFF;
                            z-index: 1000;
                            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.35);
                            @include media(576) {
                                width: 300px;
                                right: 10px;
                                top: 50px;
                            }

                            &:before {
                                position: absolute;
                                right: 28px;
                                top: 0px;
                                content: '';
                                width: 15px;
                                height: 15px;
                                background-color: white;
                                transform: rotate(135deg);
                                transform-origin: 0 0;
                                box-shadow: -3px 3px 3px -3px rgba(148, 148, 148, 0.05);

                                @include media(576) {
                                    right: -7px;
                                }
                            }

                            &__control {
                                padding: 10px 20px;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 21px;
                                color: $secondColor;

                                &.header, &.footer {
                                    span {
                                        color: #4092C4 !important;
                                        //cursor: pointer;
                                    }
                                }

                                &.header {
                                    border-bottom: 1px solid rgba(109, 110, 113, 0.3);
                                }

                                &.footer {
                                    border-top: 1px solid rgba(109, 110, 113, 0.3);
                                }
                            }

                            &__items {

                                list-style-type: none;
                                padding: 0 !important;
                                margin: 0 !important;
                                max-height: 400px;
                                height: auto;
                                overflow-y: auto;
                                width: 100%;

                                //scrollbar

                                &::-webkit-scrollbar-track {
                                    background: rgba(109, 110, 113, 0.2);
                                    border-radius: 6px;
                                }

                                &::-webkit-scrollbar {
                                    width: 7px;
                                    background: rgba(109, 110, 113, 0.2);
                                }

                                &::-webkit-scrollbar-thumb {
                                    background: #9E9E9E;
                                    border-radius: 6px;
                                }

                                li {
                                    width: 100%;
                                    min-height: 75px;
                                    background: rgba(64, 146, 196, 0.1);
                                    padding: 10px 15px;
                                    position: relative;
                                    /*overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;*/

                                    &.seen {
                                        background: white;
                                    }

                                    &:not(:first-child) {
                                        border-top: 1px solid rgba(109, 110, 113, 0.3);
                                    }

                                    .main-text {
                                        width: 265px;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 21px;
                                        color: #524F63;
                                    }

                                    .time {
                                        margin-top: 10px;
                                        color: $secondColor;
                                        font-size: 12px;
                                    }

                                    .dots {
                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }

                                    .remove-notification {
                                        min-width: 65px;
                                    }
                                }
                            }
                        }

                        .right-tools{
                            .dropdown-menu{
                                border: none !important;
                            }
                        }
                    }
                }
            }

            .burger-icon {
                display: none;
                margin: 0 10px;
                @include flex-direction(column);
                @include align-items(center);
                @include justify-content(center);
                position: relative;
                cursor: pointer;

                &__item {
                    display: block;
                    width: 30px;
                    height: 0.8px;
                    border-radius: 50px;
                    background: $textColor;

                    &:nth-of-type(2) {
                        margin-top: 10px;
                    }

                    &:nth-of-type(3) {
                        margin-top: 12px;
                    }
                }

                @include media(1200) {
                    display: block;
                }
            }
        }

        .right-tools {
            @include flex;
            @include align-items(center);

            .company__logo {
                border-radius: 6px;
                max-width: 200px;
                width: 100%;
                height: 55px;
                padding: 5px 15px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__language {
                cursor: pointer;
                margin: 0 15px 0 30px;

                .dropdown-menu {
                    transform: translate(-55%, 35px) !important;

                    .dropdown-item {
                        font-family: $mainFont, sans-serif;

                        &:hover, &.active {
                            padding-left: 19px;
                            background: rgba(64, 146, 196, 0.1);
                            border-left: 5px solid #4092C4;
                        }
                    }
                }
            }
            &__content {
                padding: 10px 15px;
                border-radius: 5px;
                background: #F2F6FC;
                border: 1px solid transparent;

                &:hover {
                    border: 1px solid rgba(64, 146, 196, 0.7);

                    .languageIcon {
                        svg {
                            background-image: url(/images/navbarLanguageIconHover.svg);

                            path {
                                fill: none;
                            }
                        }
                    }
                }
            }

            &__zoom {
                cursor: pointer;
            }

            .notifications {
                display: inline;
                position: relative;
                user-select: none;

                &-icon {
                    display: flex;
                    position: relative;
                    width: 50px;
                    height: 50px;
                    background: #F2F6FC;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    border: 1px solid transparent;

                    @include media(1200) {
                        margin-top: 0;
                        margin-left: 0;
                    }
                    @include media(576) {
                        margin-top: 0;
                        margin-left: 0;
                    }

                    .num-count {
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        font-size: 12px;
                        line-height: 14px;
                        text-align: center;
                        top: 4px;
                        right: 3px;
                        background-color: #FD6074;
                        color: #fff;
                        padding: 0 5px;

                        @include media(576) {
                            top: 4px;
                            right: -8px;
                            width: 15px;
                            height: 15px;
                            font-size: 10px;
                            line-height: 11px;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                        border: 1px solid rgba(64, 146, 196, 0.7);
                        svg {
                            background-image: url(/images/bell_blue.svg);
                            path {
                                fill: none;
                            }
                        }
                    }
                }

                &-list {
                    position: absolute;
                    width: 325px;
                    right: -27px;
                    top: 70px;
                    background: #FFFFFF;
                    z-index: 1000;
                    box-shadow: 0 1px 10px rgb(64 146 196 / 16%);
                    border-radius: 4px;
                    @include media(576) {
                        width: 300px;
                        right: 10px;
                        top: 50px;
                    }

                    &:before {
                        position: absolute;
                        right: 28px;
                        top: 0px;
                        content: '';
                        width: 15px;
                        height: 15px;
                        background-color: white;
                        transform: rotate(135deg);
                        transform-origin: 0 0;
                        box-shadow: -3px 3px 3px -3px #94949430;

                        @include media(576) {
                            right: -7px;
                        }
                    }

                    &__control {
                        padding: 10px 20px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: $secondColor;

                        &.header, &.footer {
                            span {
                                color: #4092C4 !important;
                                //cursor: pointer;
                            }
                        }

                        &.header {
                            border-bottom: 1px solid rgba(109, 110, 113, 0.3);
                        }

                        &.footer {
                            border-top: 1px solid rgba(109, 110, 113, 0.3);
                        }
                    }

                    &__items {
                        list-style-type: none;
                        padding: 0 !important;
                        margin: 0 !important;
                        max-height: 400px;
                        height: auto;
                        overflow-y: scroll;
                        width: 100%;

                        //scrollbar

                        &::-webkit-scrollbar-track {
                            background: rgba(109, 110, 113, 0.2);
                            border-radius: 6px;
                        }

                        &::-webkit-scrollbar {
                            width: 7px;
                            background: rgba(109, 110, 113, 0.2);
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #9E9E9E;
                            border-radius: 6px;
                        }

                        li {
                            width: 100%;
                            min-height: 75px;
                            background: rgba(64, 146, 196, 0.1);
                            padding: 10px 15px;
                            position: relative;
                            /*overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;*/

                            &.seen {
                                background: white;
                            }

                            &:not(:first-child) {
                                border-top: 1px solid rgba(109, 110, 113, 0.3);
                            }

                            .main-text {
                                width: 265px;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                color: #524F63;
                            }

                            .time {
                                margin-top: 10px;
                                color: $secondColor;
                                font-size: 12px;
                            }

                            .dots {
                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            .remove-notification {
                                min-width: 65px;
                            }
                        }
                    }
                }

                .right-tools{
                    .dropdown-menu{
                        border: none !important;
                    }
                }
            }

            @include media(576) {
                display: none;
            }
        }

        @include media(1200) {
            width: 100%;
        }

        @include media(768) {
            .burger-icon {
                @include flex;
            }
        }
    }
}
