@import "_mixins";

%text-dec-none{
  text-decoration: none;
}

%sidebar-links{
  color: $headingFont;
  font-family: Poppins;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    white-space: normal;
}

%flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

%form-field {
    background: $greyBg;
    border: none;
    border-radius: 4px;
    @include responsive-size(45px, 1920, 1600, height);
    @include responsive-size(16px, 1920, 1600, font-size);
    line-height: 24px;
    width: 100%;
    padding: 0 15px;
    margin: 0;
    outline: none;

    &::placeholder {
        color: rgba($textColor, 0.3);
    }
}

%passive-btn{
    border: 1px solid rgba($textColor, 0.3);
    color: rgba($textColor, 0.25);
    background: #F4F5F8;
}

%main-btn {
    background: $mainColor;
    border-radius: 4px;
    border: none;
    color: #fff;
    line-height: 24px;
    cursor: pointer;
    @include responsive-size(16px, 1920, 1600, font-size);
    @include responsive-size(40px, 1920, 1600, height);
    outline: none;
    min-width: 100px;
    &.passive{
        @extend %passive-btn;
    }
}

%main-big-btn {
    @extend %main-btn;
    line-height: 30px;
    @include responsive-size(20px, 1920, 1600, font-size);
    @include responsive-size(50px, 1920, 1600, height);
    &.passive{
        @extend %passive-btn;
    }
}

%radioBtn {
    label {
        cursor: pointer;

        input {
            position: absolute;
            opacity: 0;

            &:checked ~ span {
                border-color: $mainColor;

                &::after {
                    display: block;
                }
            }
        }

        span {
            min-width: 16px;
            height: 16px;
            border: 2px solid;
            display: inline-block;
            border-radius: 50%;
            position: relative;

            &::after {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $mainColor;
                position: absolute;
                left: 2px;
                top: 2px;
                display: none;
            }
        }
    }
}

%checkbox {
    input {
        position: absolute;
        opacity: 0;

        &:checked ~ span {
            border-color: $mainColor;
            background: $mainColor;

            &:after {
                display: block;
            }
        }
    }

    span {
        min-width: 18px;
        height: 18px;
        border: 2px solid rgba($textColor, 0.5);
        display: inline-block;
        border-radius: 3px;
        position: relative;

        &::after {
            content: '';
            width: 5px;
            height: 11px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            left: 5px;
            top: 0px;
            display: none;
        }
    }
}
