.table_pagination {
    display: flex;

    li {
        &:focus {
            outline: none;
        }

        a {
            &:focus {
                outline: none;
            }
        }

        @include media('sm') {
            &:nth-child(1) {
                margin-right: 5px;
            }
        }
    }

    .blue_click {
        box-shadow: none;
        background-color: $mainColor;
        color: white !important;
    }

    .pagination-input {
        width: 60px;
        height: 40px;
        border: 1px solid $mainColor;
        border-radius: 4px;
        padding-left: 7px;
        outline: none;
        background: transparent;
        color: #524F63;
        border-color: rgba(64, 146, 196, 0.5);
        min-width: 84px;
        display: flex;
        justify-content: space-between;
        align-items: center;


        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
        }

        &:hover, &:focus, &:active {
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none !important;
                margin: 0 !important;
            }
        }


        @include media('sm') {
            width: 40px;

        }
        @include media('xs') {
            font-size: 12px;
        }
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin: 15px 0;

        ul {
            list-style: none;
            display: flex;
            align-items: center;
            margin: 0;
        }

        .page-input {
            display: flex;
            align-items: center;
            label{
                border-left: 1px solid rgba(64, 146, 196, 0.5);
                padding-left:22px;
                margin-left:15px !important;
            }
        }

        .page_pre {
            &:focus {
                outline: none;
            }

            a {
                color: #524F63;

                &:hover {
                    color: $mainColor;
                }
            }

            &.active {
                svg {
                    path {
                        //fill: $mainColor;
                        //stroke: $mainColor;
                    }

                    circle {
                        stroke: $mainColor;
                    }
                }
            }
        }

        .page-link {
            border: none;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            box-shadow: none;
            color: rgba(82, 79, 99, 0.8);
            background: transparent;
            padding: 8px;
        }

        .page-item {
            display: flex;
            align-items: center;

            .page-input.active {
                .page-link {
                    background: transparent;
                    color: $mainColor;
                    opacity: unset;
                }
            }
        }
    }
}
