@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    src: url("../../../public/fonts/poppins/Poppins-Bold.otf") format('opentype');
    font-display: swap;
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    src: url("../../../public/fonts/poppins/Poppins-Medium.otf") format('opentype');
    font-display: swap;
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    src: url("../../../public/fonts/poppins/Poppins-Regular.otf") format('opentype');
    font-display: swap;
}

@font-face {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    src: url("../../../public/fonts/poppins/Poppins-SemiBold.otf") format('opentype');
    font-display: swap;
}

@font-face {
    font-family: Poppins-Regular;
    src: url("../../../public/fonts/poppins/Poppins-Regular.otf") format('opentype');
    font-display: swap;
}
