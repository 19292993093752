
.custom-table {
    width: 100%;
    margin: 0 auto;
    font-style: normal;
    font-weight: 500;
    @include responsive-size(16px, 1920, 1500, font-size);
    @include responsive-size(24px, 1920, 1200, line-height);
    border-top: 1px solid rgba(64, 146, 196, 0.4);
    border-bottom: 1px solid rgba(64, 146, 196, 0.4);
    background: #fff;
    color: $textColor;

    &.small-tb {
        //height: 440px;
        //height: calc(100vh - 490px);
        //@include media(1400) {
        //    height: 350px;
        //}
    }

    &.big {
        //height: calc(100vh - 460px);
        //height: 480px;
        @include media(1400) {
            //height: 400px;
        }
    }

    .thead {
        box-shadow: 0 4px 4px rgb(0 0 0 / 7%);
        position: relative;
        z-index: 7;

        .tr {
            display: flex;

            .th {
                display: inline-flex;
                font-style: normal;
                font-weight: 700;
                width: 15%;
                text-align: left;
                padding: 13px 24px;
                border-right: 1px solid rgba(82, 79, 99, 0.1);

                &.grow-1 {
                    flex-grow: 1;
                }

                .checkmark.isDisable {
                    background: #C3C6D4;
                }

                $percentage: (
                    //"1": 4%,
                    //"2": 17%,
                    //"3": 17%,
                    //"4": 17%,
                    //"5": 17%,
                    //"6": 14%,
                    //"7": 14%,
                    //"8": 13%
                );

                @each $key, $width in $percentage {
                    &:nth-child( #{$key}) {
                       min-width: $width;
                    }
                }

                &.num {
                    width: 66px;
                }

                &.hideMobile{
                    @include media(1350){
                        display: none
                    }
                }

                span {
                    .sort_by {
                        color: #6A6A6A;
                        opacity: 0.5;

                        &:hover {
                            cursor: pointer;
                            color: var(--second-color);
                            opacity: 1;
                        }
                    }

                    svg {
                        @include responsive-size(20px, 1920, 1000, width);
                        @include responsive-size(20px, 1920, 1000, height);
                    }
                }

                .container1 {
                    margin: 3px auto 18px;
                }
            }
        }
    }

    .table-body-wrapper {
        height: calc(100% - 50px);

        .tbody {
            height: 100%;
            //overflow-y: auto;
            //overflow-x: auto;

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #C3C6D4;
                border-radius: 10px;
            }

            .tr {
                display: flex;

                &.text-green {
                    .td { color: #43B48F; }
                }

                &:not(:last-of-type) {
                    border-bottom: 1px solid #E2E2E2;
                }

                &:hover {
                    box-shadow: -9px 0 var(--second-color);
                    background-color: var(--main-transparent);
                    cursor: pointer;
                    .checkmark {
                        border: 2px solid rgba(112, 111, 117, 0.8);
                    }
                    input:checked ~ .checkmark {
                        background: #1F9AC7 url(/images/check.svg) no-repeat center;
                        border: none;
                    }
                }

                .td {
                    padding: 22px 24px 21px;
                    font-style: normal;
                    font-weight: 500;
                    width: 15%;
                    text-align: left;
                    color: #524F63;
                    display: flex;
                    align-items: center;
                    gap: 14px;

                    &.grow-1 {
                        flex-grow: 1;
                    }

                    $percentage: (
                        //"1": 4%,
                        //"2": 17%,
                        //"3": 17%,
                        //"4": 17%,
                        //"5": 17%,
                        //"6": 14%,
                        //"7": 14%,
                        //"8": 13%
                    );

                    @each $key, $width in $percentage {
                        &:nth-child( #{$key}) {
                            min-width: $width;
                        }
                    }

                    &.num {
                        width: 66px;
                        display: flex;
                        justify-content: center;
                    }

                    &.hideMobile{
                        @include media(1350){
                            display: none
                        }
                    }

                    .table-button {
                        background: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;
                    }

                    hr {
                        width: 24px;
                        margin: 0 0 0 25px;
                    }

                    a {
                        position: relative;
                        &::after{
                            content: attr(data-title);
                            background: $secondColor;
                            font-size: 14px;
                            line-height: 20px;
                            color: #FFFFFF;
                            padding: 5px 10px;
                            border-radius: 2px;
                            position: absolute;
                            width: max-content;
                            right: -15px;
                            z-index: 3;
                            @include responsive-size(35px, 1920, 1600, top);
                            display: none;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            border-right: 7px solid transparent;
                            border-bottom: 7px solid var(--second-color);
                            border-left: 7px solid transparent;
                            left: 6px;
                            bottom: -12px;
                            display: none;
                            z-index: 3;
                        }

                        &:hover {
                            &::after, &::before {
                                display: block;
                            }
                        }
                    }

                    span {
                        &.deleteIcon{
                            //svg {
                            //    @include responsive-size(25px, 1920, 1300, width);
                            //    @include responsive-size(26px, 1920, 1300, height);
                            //}
                        }
                    }

                    .btn-secondary:focus, .btn-secondary:focus {
                        box-shadow: none;
                    }

                    h5 {
                        font-style: normal;
                        font-weight: 500;
                    }

                    .warning-icon {
                        position: relative;
                        margin-left: -40px;

                        .showActive {
                            display: none;
                            width: 210px;
                            @include responsive-size(15px, 1920, 1500, padding);
                            position: absolute;
                            top: 40px;
                            left: -40%;
                            z-index: 2;
                            text-align: center;
                            background: $secondColor;
                            border-radius: 2px;

                            &.active {
                                display: block;
                            }

                            p {
                                font-weight: normal;
                                @include responsive-size(14px, 1920, 1500, font-size);
                                @include responsive-size(21px, 1920, 1500, line-height);
                                color: #FFFFFF;
                                margin-bottom: 0;
                            }

                            a {
                                font-weight: bold;
                                @include responsive-size(14px, 1920, 1500, font-size);
                                @include responsive-size(21px, 1920, 1500, line-height);
                                text-decoration: underline;
                                color: #FFFFFF;

                                &:hover {
                                    &::before, &::after {
                                        display: none;
                                    }
                                }
                            }

                            &:before {
                                content: '';
                                width: 7px;
                                height: 7px;
                                border-bottom: 7px solid $secondColor;
                                border-left: 7px solid transparent;
                                border-right: 7px solid transparent;
                                position: absolute;
                                top: -7px;
                                left: 8%;
                            }
                        }
                    }

                    .edit-rec {
                        display: flex;
                        align-items: center;
                        color: rgba(82, 79, 99, 0.7);

                        span {
                            width: auto;
                            height: auto;
                            @include responsive-size(16px, 1920, 1200, font-size);
                            @include responsive-size(24px, 1920, 1200, line-height);
                            border-radius: 0;

                            svg {
                                width: auto;
                                height: auto;
                            }
                        }

                        a {
                            font-weight: normal;
                            @include responsive-size(14px, 1920, 1200, font-size);
                            @include responsive-size(24px, 1920, 1200, line-height);
                            text-decoration: underline;
                            color: $secondColor;

                            &:hover {
                                &::before, &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                    p, h5 {
                       display: flex;
                        align-items: center;
                        margin: 0;
                        text-align: left;

                        &.waiting {
                            font-style: italic;
                            color: rgba(82, 79, 99, 0.5);
                        }

                        span {
                            width: 13px;
                            height: 13px;
                            padding: 0;
                            margin-right: 9.5px;
                            border-radius: 50%;
                            display: inline-block;
                        }

                        /*------------ status classes --------------*/
                        .paid, .active {
                            background: #48C89E;
                            border: 2px solid #c4ebcf;
                        }

                        .canceled {
                            background-color: #FD6074;
                        }

                        .pending, .inactive {
                            background-color: rgba(109, 110, 113, 0.3);
                            border: 2px solid #e8e8e8;
                        }

                        .overdue {
                            background: #FF1B1B;
                            opacity: 0.6;
                        }
                    }
                    .actionsComponent{
                        display: flex;
                        gap: 14px;
                    }
                    .payment_status {
                        display: flex;
                        align-items: center;
                        svg {
                            margin-right: 8px;
                        }
                    }
                }
                .languages {
                    .languages_list {
                        display: none;

                        p {
                            font-weight: 400;
                            font-size: 14px !important;
                            line-height: 21px !important;
                            color: #FFFFFF;
                        }
                    }
                    .lang_title {
                        position: relative;
                        width: fit-content;
                        display: flex;
                    }

                    &:hover {
                        .languages_list_content {
                            position: absolute;
                            background: $secondColor;
                            padding: 5px 2px 5px 0;
                            top: 34px;
                            right: 0;
                            z-index: 9;
                            border-radius: 2px;

                            .languages_list {
                                display: block;
                                padding: 8px 16px;
                                cursor: default;
                                max-height: 152px;
                                overflow: auto;

                                &::-webkit-scrollbar-thumb {
                                    background: #FFFFFF;
                                    opacity: 0.7;
                                    border-radius: 4px;
                                }

                                &::-webkit-scrollbar {
                                    width: 4px;
                                }

                                &:before {
                                    content: "";
                                    position: absolute;
                                    border-right: 7px solid transparent;
                                    border-bottom: 7px solid $secondColor;
                                    border-left: 7px solid transparent;
                                    right: 5px;
                                    top: -5px;
                                }
                            }
                        }
                    }

                    button {
                        background: #fff;
                        border-radius: 2px;
                        border: 1px solid #E2E2E2;
                        padding: 2px 4px;
                        margin-left: 6px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: #524F63;
                    }
                }

                .files_count {
                    a {
                        text-decoration: underline;
                        text-transform: lowercase;
                    }

                    p {
                        color: rgba(82, 79, 99, 0.7);
                    }
                }
            }
        }
    }

    .actions_column {
        width: 122px!important;
        justify-content: center;
    }
    .actions_large_column {
        width: 140px!important;
    }
    .reject_accept_action {
        width: 218px!important;
    }
    .with_checkbox {
        width: auto!important;
    }
}
