/*------- Image Cropper modal start -------*/

#image-cropper, #article-16 {
    .modal-dialog {
        max-width: 700px;

        .modal-content {
            .modal-body {
                padding: 0;

                .change-img {
                    font-family: "Poppins", sans-serif;

                    .modal_header {
                        height: 70px;
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid rgba(82, 79, 99, 0.2);

                        .modal-heading {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            color: #524F63;
                            padding: 20px 30px;
                        }

                        .close-icon {
                            position: absolute;
                            border: none;
                            background: none;
                            right: 10px;
                            top: 10px;
                            width: 30px;
                            height: 30px;

                            span {
                                font-size: 30px;
                                color: rgba(255, 255, 255, 0.6);
                            }
                        }
                    }

                    .modal_body {

                        @include media(850) {
                            padding: 40px 30px;
                        }
                    }
                }
            }
        }
    }
}

/*------- Image Cropper modal end-------*/

/*------- GDPR modals start -------*/

.consent-request-area,
.consent-reg-area,
.rights-area {
    font-family: $mainFont, sans-serif;

    .modal-dialog {
        .modal-content {
            .modal-body {
                padding: 0;

                .submit-req,
                .withdraw-consent,
                .verify-modal,
                .assign-successor,
                .change-admin-modal,
                .deleted-modal,
                .rights-modal {
                    .modal_header {
                        padding: 35px 0 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;

                        .modal-heading {
                            padding: 0 90px;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #524F63;
                        }

                        .p-heading {
                            padding: 0 105px !important;
                            font-size: 14px;
                            line-height: 21px;
                        }

                        .close-icon {
                            width: 30px;
                            height: 30px;
                            position: absolute;
                            top: 15px;
                            right: 15px;
                            border: none;
                            background: none;

                            span {
                                font-size: 30px;
                                color: rgba(82, 79, 99, 0.6);
                            }
                        }
                    }

                    .modal_body {
                        padding: 0;

                        .change-role-title {
                            margin-top: 17px;

                            p {
                                margin: 0;

                                span {
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 21px;
                                    text-transform: capitalize;
                                    color: #524F63;

                                    &:first-of-type {
                                        font-weight: 600 !important;
                                    }
                                }
                            }
                        }

                        .skyblue-line {
                            width: 40px;
                            height: 2px;
                            margin: 25px auto;
                            background-color: #27AEDE;
                            box-sizing: border-box;
                            border-radius: 5px;
                        }

                        .submit-req-info {
                            //padding: 26px 68px 13px;
                            padding: 15px 68px 5px;
                            display: flex;
                            justify-content: space-between;
                            font-size: 14px;
                            font-style: normal;
                            line-height: 21px;
                            text-align: left;
                            color: #524F63;

                            &_wrapper {
                                div {
                                    &:first-of-type {
                                        padding-bottom: 5px;
                                        margin-bottom: 5px;
                                        font-weight: 600;
                                        border-bottom: 1px solid rgba(82, 79, 99, 0.25);
                                    }

                                    &:last-of-type {
                                        font-weight: normal;
                                    }
                                }
                            }
                        }

                        &-title {
                            margin: 0 40px 0;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: center;
                            color: #524F63;
                        }

                        &-subtitle {
                            margin: 9px 90px 0;
                            font-style: italic;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: center;
                            color: #524F63;
                        }

                        .form-group {
                            margin: 0;
                            padding: 35px 80px 0;
                            text-align: left;

                            .form-control {
                                width: 100%;
                                height: 50px;
                                padding-bottom: 15px;
                                padding-top: 15px;
                                font-family: $mainFont, sans-serif;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 24px;
                                border-radius: 4px;
                                border: none;
                                background-color: $mainTransparent;
                                color: $textColor;
                                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

                                //&:focus {
                                //    box-shadow: 0 0 0 0.1rem $secondTransparent !important;
                                //    background-color: $mainTransparent;
                                //}
                            }
                        }

                        .group {
                            padding-top: 0;
                        }

                        .select-group {
                            padding-bottom: 20px;

                            .IZ-select__menu {
                                //height: 145px !important;
                                min-height: 50px;
                                &-items {
                                    max-height: 145px !important;

                                    .IZ-select__item {
                                        margin: 5px 0;
                                        min-height: 40px;
                                        height: 100%;
                                        span {
                                            font-weight: normal !important;
                                        }
                                        div{
                                            overflow-wrap: break-word;
                                            height: auto !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .modal_footer {
                        padding: 40px 0 46px;
                        justify-content: center;

                        button {
                            padding: 5px 25px;
                            min-width: 190px;
                            width: auto;
                            height: 40px;

                            &:first-of-type {
                                margin-right: 7px;
                            }

                            &:last-of-type {
                                margin-left: 7px;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 768px) {
            max-width: 560px !important;
            min-height: 365px !important;
        }
    }
}

.rights-area {
    .modal-dialog {
        .modal-content {
            .modal-body {
                .rights-modal {
                    font-family: $mainFont, sans-serif;

                    &_header {
                        background: #27AEDE;
                        color: #fff;
                        padding: 23px 0 !important;
                        justify-content: flex-start !important;

                        .article-item {
                            width: 134px;
                            height: 44px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 0px 4px 4px 0px;
                            background: rgba(242, 246, 252, 0.25);

                            p {
                                margin: 0 !important;
                                color: #FFFFFF;
                            }

                            @include media(767) {
                                width: 100px;
                            }
                        }

                        .modal-heading {
                            padding: 0 30px !important;
                            margin: 0;
                            width: calc(100% - 268px);
                            height: 54px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 18px !important;
                            line-height: 27px !important;
                            color: #fff !important;

                            h5 {
                                margin: 0;
                            }

                            @include media(767) {
                                width: calc(100% - 200px);
                                font-size: 15px !important;
                            }
                        }

                        .close-icon {
                            span {
                                color: rgba(255, 255, 255, 0.6) !important;
                            }
                        }
                    }

                    .body {
                        &-title {
                            background: #F2F6FC;
                            padding: 18px 35px;
                            margin: 0 !important;

                            p {
                                margin: 0;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 21px;
                                display: flex;
                                align-items: center;
                                color: #524F63;
                            }
                        }

                        .doc-wrapper {
                            margin-top: 38px;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            border-radius: 0 6px 0 0;

                            .doc-item {
                                width: 220px;
                                height: 40px;
                                display: flex;
                                justify-content: space-between;
                                background: #FFFFFF;
                                align-items: center;
                                border: 1px solid rgba(169, 167, 175, 0.2);
                                box-shadow: 0 3px 3px rgba(169, 167, 175, 0.2);
                                border-radius: 4px;

                                &_text {
                                    margin: 0 15px;
                                    padding: 10px 0;
                                    width: calc(100% - 80px);
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #524F63;
                                }

                                &_icons {
                                    height: 100%;
                                    width: 80px;
                                    padding: 8px 0;
                                    display: flex;
                                    align-items: center;
                                    border-radius: 0 4px 4px 0;
                                    background: #E8E7E7;

                                    span {
                                        &:first-of-type {
                                            margin-left: 18px;
                                        }
                                    }
                                }
                            }
                        }

                        .modal_body-text-between {
                            margin: 28px 0 29px;

                            span {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                text-transform: lowercase;
                                color: #524F63;
                            }
                        }

                        .form-group_wrapper {
                            width: 57.31%;
                            margin: 0 auto;

                            .form-group {
                                position: relative;

                                input {
                                    width: 100%;
                                    height: 49px;
                                    padding: 5px 40px 5px 15px;
                                    border: none;
                                    border-radius: 4px;
                                    background: #F2F6FC;

                                    &::placeholder {
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: #524F63;
                                        opacity: 0.4;
                                    }
                                }

                                .passwordIcon {
                                    position: absolute;
                                    top: 10px;
                                    right: 15px;
                                }
                            }
                        }

                        .modal_body-button {
                            margin: 38px 0 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            button {
                                width: 150px;
                            }
                        }

                        .user-info {
                            //padding: 35px 35px 85px;
                            padding: 35px 30px 50px;
                            flex-direction: column;

                            &_wrapper {
                                display: flex;
                                justify-content: space-between;

                                .img-wrapper {
                                    width: 211px;
                                    min-height: 211px;
                                    position: relative;
                                    display: flex;
                                    padding: 31.5px;
                                    background: rgba(242, 246, 252, 0.7);

                                    .img-upload {
                                        width: 100%;
                                        height: 40px;
                                        border: none;
                                        position: absolute;
                                        bottom: 0;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        color: #fff;
                                        background: rgba(82, 79, 99, 0.3);

                                        p {
                                            margin: 0;
                                        }
                                        .line {
                                            display: inline-flex;
                                            background-color: #E6E8F1;
                                            width: 2px;
                                            height: 27px;
                                            margin: 0 15px;
                                        }
                                    }

                                    .user-info__img {
                                        max-width: 100%;
                                        max-height: 100%;
                                        border-radius: 50%;
                                    }

                                    @include media(768) {
                                        width: 45%;
                                    }
                                }

                                .user-info-items {
                                    width: 399px;
                                    display: flex;
                                    flex-direction: column;
                                    font-size: 14px;
                                    font-style: normal;
                                    line-height: 21px;
                                    text-align: left;
                                    color: #524F63;

                                    .user-info-item {
                                        &:not(:first-of-type) {
                                            margin-top: 26px;
                                        }

                                        div {
                                            &:first-of-type {
                                                padding-bottom: 5px;
                                                margin-bottom: 5px;
                                                font-weight: 600;
                                                border-bottom: 1px solid rgba(82, 79, 99, 0.25);
                                            }

                                            &:last-of-type {
                                                font-weight: normal;
                                            }
                                        }
                                    }

                                    @include media(768) {
                                        width: 55%;
                                    }
                                }

                                .form-group-wrapper {
                                    width: 100%;

                                    .form-group {
                                        padding: 0;

                                        &:not(:first-of-type) {
                                            margin-top: 16px;
                                        }

                                        .form-control {
                                            border: none;
                                            background: #F2F6FC;

                                            &:not(:first-of-type) {
                                                margin-top: 5px;
                                            }

                                            &::placeholder {
                                                color: rgba(82, 79, 99, 0.5);
                                            }
                                        }
                                    }
                                }

                                textarea {
                                    margin-top: 10px;
                                    margin-bottom: 0px;
                                    background: #F2F6FC;
                                    border-radius: 4px;
                                    border: none;
                                    padding: 8px;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 21px;
                                    height: 125px;
                                    overflow-y: auto;
                                    color: $textColor !important;
                                    resize: none;
                                }

                                textarea::-webkit-input-placeholder {
                                    color: rgba(82, 79, 99, 0.5);
                                }

                                textarea:-moz-placeholder { /* Firefox 18- */
                                    color: rgba(82, 79, 99, 0.5);
                                }

                                textarea::-moz-placeholder { /* Firefox 19+ */
                                    color: rgba(82, 79, 99, 0.5);
                                }

                                textarea:-ms-input-placeholder {
                                    color: rgba(82, 79, 99, 0.5);
                                }

                                textarea::placeholder {
                                    color: rgba(82, 79, 99, 0.5);
                                }
                            }
                        }
                    }

                    .modal_body {
                        .title {
                            margin-top: 58px !important;
                        }
                    }

                    &_footer {
                        padding: 50px 0 !important;

                        a {
                            background: #F2F6FC;
                            border-radius: 6px;

                            span {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                text-decoration-line: underline;
                                color: #4092C4;
                            }
                        }
                    }

                    .footer {
                        padding: 0 !important;

                        &_wrapper {
                            padding: 22px 35px;
                            width: 100%;
                            background: #F2F6FC;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .footer-title {
                                margin: 0;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 18px;
                                color: #524F63;
                            }

                            button {
                                margin-right: 0 !important;
                                margin-left: 100px !important;
                                min-width: 160px;
                            }

                            button.footer_wrapper-btn {
                                margin-left: 16px !important;
                            }
                        }

                        &-without-btn {
                            padding-right: 95px;
                            padding-left: 95px;
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 768px) {
            max-width: 700px !important;
        }

        @include media(767) {
            max-width: 550px !important;
        }
    }

    @media only screen and (min-width: 768px) {
        .changeWidth {
            max-width: 560px !important;
        }
    }
}

/*------- GDPR modals end-------*/

/*------- Set Email settings start ---------*/

.configure-modal-area {
    .modal-dialog {
        .modal-content {
            .modal-body {
                padding: 5% !important;

                .email-settings-main {
                    font-family: 'Poppins', sans-serif;

                    .modal_header {
                        padding: 20px 0 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;

                        .modal-title {
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #524F63;
                        }

                        .close-icon {
                            width: 30px;
                            height: 30px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            border: none;
                            background: none;

                            span {
                                font-size: 30px;
                                color: rgba(82, 79, 99, 0.6);
                            }
                        }
                    }

                    .modal_body {
                        padding: 0;

                        .skyblue-line {
                            width: 40px;
                            height: 2px;
                            //margin: 25px auto;
                            margin: 15px auto 25px auto;
                            background-color: #27AEDE;
                            box-sizing: border-box;
                            border-radius: 5px;
                        }

                        p {
                            margin: 0 90px 0;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 18px;
                            line-height: 27px;
                            text-align: center;
                            color: #524F63;
                        }
                    }

                    .modal_footer {
                        padding: 40px 0 20px;
                        justify-content: center;

                        button {
                            padding: 5px 25px;
                            width: 140px;
                            height: 40px;

                            &:first-of-type {
                                margin-right: 7px;
                            }

                            &:last-of-type {
                                margin-left: 7px;
                                text-transform: capitalize;
                            }

                            a {
                                color: #ffffff;
                                &:hover {
                                    text-decoration: none!important;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (min-width: 768px) {
            max-width: 560px !important;
            min-height: 365px !important;
        }
    }
}

/*------- Set Email settings end ---------*/

#categoryModal {
    top: 15%;

    .modal-footer {
        margin: 50px auto 0;
    }

    .bt_with_blue {
        width: 184px;
        height: 50px;
        margin-left: 14px;
        background-color: $mainColor;
        border: 0;
        @include media("xs") {
            width: 100px;
        }
    }

    .first_modal_close {
        background-color: white;
        border: 1px solid $mainColor;
        color: #524F63;
    }

    .modal-dialog {
        max-width: 982px;
    }

    .first_modal {
        padding: 100px 0;
        margin: 0 auto;
        @include media(992) {
            padding: 80px 0;
            margin: 0;
        }

        .first_modal_inp {
            width: 384px;
            display: inline-block;
            background-color: #F4F5F8;
            border: none;
            margin-bottom: 20px;
            padding: 0;
            height: 50px;
            padding-left: 15px;
            @include media("xs") {
                width: 100%;
            }
        }
    }
}

#lng_modal {
    top: 4%;
    overflow: hidden;

    .bt_with_blue {
        width: 184px;
        height: 50px;
        background-color: $mainColor;
        border: 0;
        @include media(576) {
            width: 100%;
        }
    }

    .first_modal_close {
        background-color: white;
        border: 1px solid $mainColor;
        color: #524F63;
    }

    .modal-dialog {
        max-width: 575px
    }

    .first_modal {
        margin-top: 30px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media(576) {
            padding: 50px 100px;
        }
        @include media("xs") {
            padding: 30px;
        }

        .first_modal_inp {
            width: 382px;
            display: inline-block;
            background-color: #f4f5f8;
            border: none;
            margin-bottom: 20px;
            padding: 0;
            height: 50px;
            padding-left: 15px;
            @include media(576) {
                width: 100%;
            }
        }
    }
}

#translate_rec_modal {
    top: 15%;

    .modal-footer {
        margin: 50px auto 0;
    }


    .bt_with_blue {
        width: 184px;
        height: 50px;
        //margin-left: 14px;
        background-color: $mainColor;
        border: 0;
        @include media(992) {
            width: 100%;
        }

    }

    .first_modal_close {
        background-color: white;
        border: 1px solid $mainColor;
        color: #524F63;
    }

    .modal-dialog {
        max-width: 982px
    }

    .first_modal {
        padding: 109px 252px;

        .first_modal_inp {
            width: 382px;
            display: inline-block;
            background-color: #F4F5F8;
            border: none;
            margin-bottom: 20px;
            padding: 0;
            height: 50px;
            padding-left: 15px;
        }
    }
}

#languageModal {
    #language {
        border: 1px solid #80808066;
        border-radius: 3px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: url(https://inovit2.followprogress.com/wp-content/themes/inovit/images/arrow.png) no-repeat right #fff;
        background-position-x: 93%;
        padding: 5px 20px;
        box-sizing: border-box;
        font-size: 20px;
        cursor: pointer;
        width: 100%;
        margin: 0;
        color: #524F63;

        option {
            color: #000000;
            font-weight: 600;
            border-left: 9px solid transparent;
        }
    }

    #languageModal1 {

        .modal-header,
        .modal-footer {
            border-bottom: 1px solid #e9ece;
        }

        .modal-content {
            background-color: #f4f5f8;

            .langModal-wrapp {
                //margin: 34px 0;
            }

            @include media('xs') {
                #language {
                    font-size: 18px;
                }
            }
        }
    }

    @include media('sm') {
        top: 20%;
    }
}

.modal.fade.new__modal {
    .modal-dialog {
      min-width: 960px;

        .modal-header {
            width: 100%;
            background: #fff;
            min-height: 80px;
            padding: 0 30px 0 40px;
            border-bottom: 1px solid rgba(114, 112, 130, 0.2);
            @include flex;
            @include align-items(center);

            h5 {
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                letter-spacing: -0.01em;
                color: rgba(82, 79, 99, 0.8);
                text-align: center;
                width: unset;
                margin: 0;
            }

            button {
                right: 40px;
                color: transparent !important;

                span {
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 30px;
                }
            }
            .close_icon {
                align-items: center;
                cursor: pointer;
                display: flex;
                height: 36px;
                justify-content: center;
                opacity: 60%;
                width: 36px;
                &:hover {
                    background: #F4F5F8;
                    border-radius: 4px;
                    opacity: 85%;
                }
            }
        }

        .container_content {
            padding: 60px 40px 62px;
            .for_admin_roles {
                flex: 100%;
                max-width: 100%;
                padding: 0!important;
            }
        }

        .modal-body {
            padding: 0;
            @include flex;
            @include justify-content(space-between);
            flex-wrap: wrap;
            text-align: left;

            .form-group {
                width: 100%;

                label {
                    font-weight: 600;
                    @include responsive-size(16px, 1920, 1800, font-size);
                    @include responsive-size(24px, 1920, 1200, line-height);
                    color: #524F63;
                }

                .form-control {
                    height: 50px !important;
                    @include responsive-size(16px, 1920, 1800, font-size);

                    &:focus {
                        box-shadow: 0 0 0 0.1rem $secondTransparent;
                        background-color: $mainTransparent;
                    }
                    @include media(1200){
                        height: 40px !important;
                    }
                }
            }
        }
        .modal-footer {
            width: 100%;
            padding: 20px 40px !important;
            background: #F2F6FC;
            display: flex;
            justify-content: flex-end !important;
            @include justify-content(space-between);
            @include align-items(center);

            label {
                margin-bottom: 0;

                span {
                    top: 5px;
                }

                p {
                    font-weight: 600;
                    @include responsive-size(16px, 1920, 1800, font-size);
                    @include responsive-size(24px, 1920, 1800, line-height);
                    color: #524F63;
                    margin-bottom: 0;
                    margin-left: 10px;
                }
            }
            button {
                width: auto;
                min-width: 160px;
                height: 40px;
                margin: 0;
            }
            .main-button__disabled {
                background-color: #fff;
            }
        }

        @include media(768) {
            min-width: 95%;
            text-align: center;
            .modal-body {
                display: block;

                .form-group {
                    width: 100%;
                }
            }
            .modal-footer {
                display: block;

                .add_pass {
                    label {
                        justify-content: center;
                        display: inline-flex;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

.bulk_invite {
    .modal-dialog {
        max-width: 560px!important;
        .modal-header {
            padding: 0 15px 0 30px!important;
            min-height: 70px!important;
        }
    }
    .info_text {
            height: 51px;
            background: rgba(64, 146, 196, 0.15);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px;
    }
}

.bulk_invite_with_xlsx {
    .modal_body {
        padding: 60px 30px;
    }
    .modal-header {
        padding: 0 15px 0 30px!important;
        min-height: 70px!important;
        div {
            display: flex;
            align-items: center;
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                color: rgba(82, 79, 99, 0.85);
                margin: 0 15px;
            }
        }
    }
}


.modal.fade {
    .modal-dialog {
        .modal-content {
            .modal-body {
                .header__icon {
                    margin: 0 auto;
                }

                h4 {
                    width: 75%;
                    margin: 10px auto;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #524F63;
                }

                .skyblue-line {
                    width: 30px;
                    height: 2px;
                    margin: 20px auto;
                    background-color: #27AEDE;
                    box-sizing: border-box;
                    border-radius: 5px;
                }

                .form-control {
                    width: 100%;
                    height: 50px;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    font-family: $mainFont, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    border-radius: 4px;
                    border: none;
                    background-color: $mainTransparent;
                    color: $textColor;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

                    &:focus {
                        box-shadow: 0 0 0 0.1rem $secondTransparent;
                        background-color: $mainTransparent;
                    }
                }
            }

            .modal-footer {
                justify-content: center;
                padding: 0 0 35px;
            }
        }

        &.blue-header {
            .modal-content {
                .modal-header {
                    background: $mainColor;

                    h5 {
                        color: #fff;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.modal-body.emailWarning, .modal-body.emailCheck, .modal-body.deactivateContent {
    hr {
        height: 2px;
        background: $mainColor;
        width: 20%;
    }

    p {
        font-weight: 400;
    }
}

.modal-body.emailWarning {
    h3 {
        font-size: 20px;
        margin-bottom: 5%;
    }

    p {
        margin-bottom: 0;
    }
}

.modal-body.emailCheck, .modal-body.deactivateContent {
    padding: 3% 15%;

    h2 {
        font-size: 22px !important;
        color: $mainColor !important;
        margin: 3% !important;
    }

    h3 {
        font-size: 18px;
        line-height: 25px;
    }
}

.modal-body.deactivateContent {
    padding-bottom: 8%;

    hr {
        background: rgba(82, 79, 99, 0.5);
        height: 0;
        opacity: 0.5;
    }

    ul {
        text-align: left;
        list-style-type: none;
        padding: 0 6%;

        li {
            font-size: 14px;
            position: relative;

            &::before {
                content: ".";
                font-size: 55px;
                color: $secondColor;
                position: absolute;
                top: -20px;
                left: -20px;
            }
        }
    }

    label {
        font-size: 14px;
        line-height: 21px;
        color: #524F63;
        font-weight: 400;
    }
}

.modal-content {
    margin-top: 60px;
    border: none;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    .sort_part {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 18px;
        color: #524F63;
        margin-top: 30px;
        @include media("xs") {
            font-size: 14px;
        }
    }

}

.modal.fade.new__modal.invite {
    @media (min-width: 700px) {
        .modal-dialog {
            max-width: 700px;
            min-width: auto !important;
        }
    }

    .modal-body {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .invite_type {
            display: flex;
            align-items: center;
            margin: 30px auto 45px;
            flex-direction: column;
            gap: 25px;

            & > div {
                cursor: pointer;
            }
        }

        .steps {
            display: flex;
            width: 100%;
            margin-bottom: 30px;

            &_count {
                min-width: 70px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $mainColor;
            }

            &>div {

                .uploadFile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #FFFFFF;
                    border: 1px solid rgba(39, 174, 222, 0.5);
                    border-radius: 20px;
                    padding: 0 8px;
                    margin: 15px 0 5px;
                    width: fit-content;

                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: #524F63;
                        margin-right: 10px;
                        margin-bottom: 0;
                    }

                    span {
                        margin-top: -2px;
                        cursor: pointer;
                        height: 28px;
                    }
                }
            }

            p:not(.steps_count) {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #524F63;
                margin-bottom: 0;
            }

            .bulkUpload {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                left: 0;
            }
        }

        .steps {
            div {
                button {
                    background: rgba(39, 174, 222, 0.05);
                    border: 1px solid rgba(39, 174, 222, 0.5);
                    box-sizing: border-box;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #27AEDE;
                    height: 40px;
                    padding: 10px 20px;
                    margin-top: 15px;

                    &.disabled {
                        color: rgba(82, 79, 99, 0.4);
                        pointer-events: auto;
                    }

                    span {
                        display: flex;
                    }
                    svg {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .modal-footer.withFiles {
        flex-direction: column;
        align-items: flex-end;
        padding-top: 0;

        .line {
            width: 100%;
            height: 1px;
            background: $mainColor;
            opacity: 0.2;
            margin-bottom: 30px;
        }

        a:hover {
            color: #fff;
        }
    }
}

.modal-header {
    padding: 20px;
    position: relative;
    border-bottom: none;

    h5 {
        width: 100%;
        display: block;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        text-align: center;
        color: $mainColor;
    }
}

.modal-footer {
    border-top: none;
}

.modal_footer_content.with_big_button {
    button {
        min-width: 160px;
    }
}

.modal_footer_content {
        height: 80px;
        padding: 0 45px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        background-color: #F2F6FC;

    button {
        height: 40px;
        min-width: 140px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    .delete_button {
        background: #FD6074;
        border: none;
        color: #fff;
        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        }
        .loadingBtn {
            background: #FD6074;
        }
    }

    .activate_button.activated, .next_button {
        background-color: $mainColor;
    }

    .activate_button, .next_button {
        border: none;
        color: #fff;

        &:hover {
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        }
    }

    .activate_button {
        background-color: #3DBC5F;
    }

    .back_button {
        background: #FFFFFF;
        border: 1px solid rgba(82, 79, 99, 0.3);
        color: rgba(82, 79, 99, 0.7);

        &:hover {
            border: 1px solid rgba(82, 79, 99, 0.4);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
        }
    }
}

.modal-body {
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: $headingFont;
    padding: 10px 20px 20px;

    h4 {
        font-size: 17px;
    }
}

.langModal-wrapp {
    //margin: 34px 0;

    .form-group:last-child {
        margin-bottom: 0;
    }

    .category_check {
        color: $mainColor;
        margin: 0 5px 0;
    }
}

.modal-dialog.allowLogOut {
    ul {
        padding: 0;
        list-style-type: none;

        li {
            &:before {
                content: '.';
                font-size: 45px;
                font-weight: bold;
                color: $secondColor;
                margin-right: 5px;
            }
        }
    }
}

.modal-dialog.allowLogin, .modal-dialog.allowLogOut {
    .modal-header {
        border-bottom: none;
    }

    .modal-footer {
        border-top: none
    }

    h1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #524F63;
    }

    p {
        text-align: center;
    }

    hr {
        height: 2px;
        background: $mainColor;
        width: 15%;
    }
}

.modal-dialog {
    .modal-header {
        border-bottom: none;
    }

    .modal-footer {
        border-top: none;
    }
}

.modal-dialog {
    .modal-content {
        .langModal-wrapp {
            .select2-hidden-accessible, .select2-container--default, .select2-selection--single {
                max-height: 36px;
                min-height: 36px;
            }

            .select2-selection--single {
                padding: 6px 0;

                .select2-selection__arrow {
                    top: 6px !important;
                }
            }
        }
    }
}

.create-user-modal {
    .password-wrapper {
        .one-time-password {
            font-size: 14px;
            font-weight: normal;
        }

        .generate-password {
            font-size: 14px;
        }

        .checkmark {
            left: 0 !important;
        }
    }

    .one-time-password {
        svg {
            path {
                fill: $mainColor;
            }
        }
    }
}

.delete-modal {
    .modal__body {
        &-line {
            width: 40px;
            margin: 24px auto 0;
            border: 1px solid #27AEDE;
        }

        &-info {
            font-weight: normal;
            line-height: 24px;
            margin-top: 24px;
            padding: 0 10%;
        }
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        &.img_crop {
            max-width: 800px;
        }
    }
}

.modal-dialog {
    &.img_crop {
        .auth-status {
            padding: 15px 0;
        }
    }
}

/*------ Invoice History Start ------*/

.invoice-modal-area {
    padding: 0 !important;
    overflow-y: hidden !important;

    .modal-dialog {
        margin: 0;
        max-width: 100%;
        height: 100%;

        .modal-content {
            margin: 0;
            height: 100%;
            font-family: "Popins", sans-serif;

            .modal-header {
                width: 100%;
                height: 107px;
                align-items: center;
                background: #27AEDE;

                h5 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 26px;
                    line-height: 39px;
                    text-align: center;
                    color: #fff;
                }

                button {
                    padding: 0;
                    top: 50px;
                    right: 45px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 35px;
                    color: rgba(255, 255, 255, 0.6);
                }
            }

            .modal-body {
                .invoice-main-filter {
                    display: flex;
                    margin-top: 25px;
                    margin-bottom: 40px;
                    margin-left: 50px;

                    .filter-search {
                        position: relative;
                        width: 20.23%;
                        min-width: 388px;
                        margin-right: 25px;

                        .search__icon {
                            position: absolute;
                            top: 8px;
                            left: 16px;

                        }

                        .search__fields {
                            width: 100%;
                            height: 49px;
                            padding: 5px 5px 5px 55px;
                            border: none;
                            border-radius: 4px;
                            background: #F2F6FC;

                            &::placeholder {
                                font-style: normal;
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba(82, 79, 99, 0.5);
                            }

                            &:focus {
                                outline: none;
                            }
                        }
                    }

                    .filter-select-area {
                        position: relative;

                        .filter-icon {
                            position: absolute;
                            top: 5px;
                            left: 0;
                            cursor: pointer;
                        }

                        .vue-treeselect.main-filter-tree {
                            max-width: 42px;
                            width: 100%;

                            &.vue-treeselect--focused:not(.vue-treeselect--open) {
                                .vue-treeselect__control {
                                    box-shadow: none !important;
                                }
                            }

                            &.vue-treeselect--open {

                                &.vue-treeselect--open-above {
                                    position: relative;

                                    .vue-treeselect__menu-container {
                                        &:after {
                                            content: '';
                                            border-top: 7px solid #fff;
                                            border-left: 7px solid transparent;
                                            border-right: 7px solid transparent;
                                            width: 7px;
                                            height: 7px;
                                            position: absolute;
                                            bottom: -8px;
                                            right: 30px;
                                        }
                                    }
                                }

                                &.vue-treeselect--open-below {
                                    position: relative;

                                    .vue-treeselect__menu-container {
                                        &:after {
                                            content: '';
                                            border-bottom: 7px solid $mainOpacity;
                                            border-left: 7px solid transparent;
                                            border-right: 7px solid transparent;
                                            width: 7px;
                                            height: 7px;
                                            position: absolute;
                                            top: -8px;
                                            right: 30px;
                                        }
                                    }
                                }
                            }


                            .vue-treeselect__multi-value-item-container {
                                display: none;
                            }

                            & > div.vue-treeselect__control {
                                padding: 0;
                                border: none;
                                background: transparent;
                            }

                            .vue-treeselect__menu-container {
                                width: 236px;
                                margin-top: 15px;

                                .vue-treeselect__menu {
                                    border: none;
                                    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
                                    padding: 0;
                                    border-radius: 0;

                                    /* Handle */
                                    &::-webkit-scrollbar-thumb {
                                        background: #27AEDE;
                                    }

                                    .vue-treeselect__option {
                                        padding: 10px !important;
                                        padding-left: 15px;

                                        &--highlight {
                                            background: $mainOpacity;
                                        }

                                        &--selected {
                                            background: $mainOpacity;
                                        }
                                    }

                                    .vue-treeselect__list-item {
                                        &.vue-treeselect__indent-level-0 {
                                            & > .vue-treeselect__option {

                                                & > .vue-treeselect__option-arrow-container {
                                                    svg {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }

                                        .vue-treeselect__label {
                                            margin-bottom: 0;
                                            font-weight: 500;
                                            font-size: 14px;
                                            line-height: 21px;
                                            color: #524F63;
                                        }

                                        &.vue-treeselect__indent-level-1 {
                                            .vue-treeselect__list {
                                                background: $mainOpacity;
                                            }
                                        }

                                        &.vue-treeselect__indent-level-2 {
                                            .vue-treeselect__label {
                                                font-weight: normal;
                                            }

                                        }

                                        &.vue-treeselect__indent-level-0 {
                                            padding: 0;

                                            & > .vue-treeselect__option {

                                                .vue-treeselect__label {
                                                    color: $secondColor;
                                                    margin: 0;
                                                    font-weight: 600;
                                                    font-size: 14px;
                                                    line-height: 21px;
                                                }
                                            }
                                        }
                                    }

                                    .vue-treeselect__checkbox {
                                        width: 15px;
                                        height: 15px;

                                        .vue-treeselect__check-mark {
                                            width: 10px;
                                            height: 10px;
                                            background-size: 10px 10px;
                                        }

                                        .vue-treeselect__check-mark, .vue-treeselect__minus-mark {
                                            left: 2px;
                                            top: 2px;
                                        }

                                        &--unchecked {
                                            border-color: rgba(82, 79, 99, 0.3);
                                            background: transparent;
                                            border-width: 1.5px;
                                            width: 15px;
                                            height: 15px;
                                        }
                                    }
                                }

                                &:after {
                                    left: 20px;
                                }
                            }

                            .vue-treeselect__label-container {
                                @include flex;
                                @include align-items(center);
                                @include justify-content(space-between);
                                flex-direction: row-reverse;

                            }
                        }
                    }
                }

                .invoice-table {
                    margin-bottom: 0;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 24px;

                    .thead {
                        padding: 0 55px 0 50px;

                        .tr {
                            display: flex;
                            border-bottom: 1px solid #E2E2E2;

                            .th {
                                display: inline-block;
                                padding-bottom: 15px;
                                padding-left: 0;
                                font-weight: bold;
                                background: #ffffff;

                                $percentage: (
                                    "1": 7%,
                                    "2": 15%,
                                    "3": 12%,
                                    "4": 9%,
                                    "5": 8%,
                                    "6": 7%,
                                    "7": 13%,
                                    "8": 12%,
                                    "9": 13%,
                                    "10": 4%,
                                );

                                @each $key, $width in $percentage {
                                    &:nth-child( #{$key}) {
                                        width: $width;
                                    }
                                }

                                span {
                                    &:first-of-type {
                                        color: #27AEDE;
                                    }

                                    .sort_by {
                                        color: #6A6A6A;
                                        opacity: 0.5;
                                    }
                                }

                                &:not(:last-of-type) {
                                    text-align: left;
                                }
                            }
                        }

                        @include mediaDistance(1550, 1350) {
                            font-size: 12px;
                        }

                        @include mediaDistance(1350, 1250) {
                            font-size: 11px;
                        }

                        @include mediaDistance(1250, 1200) {
                            font-size: 10.5px;
                        }
                    }

                    .table-body-wrapper {
                        padding: 0 0 40px;
                        height: calc(100vh - 300px);

                        .tbody {
                            height: 100%;
                            overflow-y: auto;
                            padding: 0 50px;

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #27AEDE;
                            }

                            .tr {
                                display: flex;
                                border-bottom: 1px solid #E2E2E2;

                                &:hover {
                                    box-shadow: -9px 0 var(--second-color);
                                    background-color: var(--main-transparent);
                                    cursor: pointer;
                                }

                                .td {
                                    display: inline-block;
                                    padding: 40px 0;
                                    font-weight: 500;
                                    color: #524F63;

                                    $percentage: (
                                        "1": 7%,
                                        "2": 15%,
                                        "3": 12%,
                                        "4": 9%,
                                        "5": 8%,
                                        "6": 7%,
                                        "7": 13%,
                                        "8": 12%,
                                        "9": 13%,
                                        "10": 4%,
                                    );

                                    @each $key, $width in $percentage {
                                        &:nth-child( #{$key}) {
                                            width: $width;
                                        }
                                    }

                                    &:not(:last-of-type) {
                                        text-align: left;
                                    }

                                    p {
                                        span {
                                            width: calc(4.65px + 4.35 * (100vw - 992px) / 928);
                                            height: calc(4.65px + 4.35 * (100vw - 992px) / 928);
                                            padding: 0;
                                            margin-right: 5px;
                                            border-radius: 50%;
                                            display: inline-block;
                                        }

                                        .paid {
                                            background: #48C89E;
                                        }

                                        .overdue {
                                            background: #FF1B1B;
                                            opacity: 0.6;
                                        }
                                    }
                                }
                            }

                            @include mediaDistance(1550, 1200) {
                                font-size: 13px;
                            }
                        }
                    }

                    @include mediaDistance(1750, 1550) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

/*------ Invoice History End ------*/

/*------ Modals buttons -------*/
.modal_area.pulbish {
    .modal-footer {
        .btn {
            &.btn-primary {
                background: #48C89E;
                border-color: #48C89E;
            }
        }
    }
}

.modal-footer {
    .btn {
        @extend .main-button;

        height: 40px !important;

        &.btn-secondary, .btn-secondary:hover, .btn-secondary:active {
            border: 1px solid rgba(82, 79, 99, 0.5) !important;
            color: $textColor !important;
            background: #F4F5F8 !important;
            opacity: 0.4;
        }

        &.btn-primary, &.btn-danger {
            background-color: $mainColor;
            color: white;
            border: 1px solid $mainColor;

            &:hover, &:active {
                border-color: $mainColor !important;
                background: $mainColor !important;
            }

            &:active {
                color: #fff !important;
            }
        }
    }
}

.modal-dialog {
    .modal-content {
        .modal-footer {
            button {
                min-width: 170px;
                height: 50px;
                padding: 10px 30px;
                line-height: 18px;
            }
        }
    }

    &.blue-header {
        .modal-content {
            .modal-header {
                button {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
        }
    }
}

.modal-header {
    button {
        position: absolute;
        top: 12px;
        right: 28px;
        width: 14px;
        height: 14px;
    }
}

/*------- Modals buttons end -------*/



/*------- Disable two fa Authentication -------*/


.disable_two_fa_authentication {
    .modal-body {
        padding: 0;
        .new-role-assign {
            padding: 60px 45px 45px;
            .close_icon {
                position: absolute;
                right: 15px;
                top: 15px;
                width: 32px;
                height: 32px;
                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        .modal-header {
            justify-content: left;
            align-items: center;
            margin-bottom: 30px;
            h4 {
                margin: 0 0 0 18px!important;
                text-align: left;
            }
        }

        .modal-body {
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
                margin: 0;
                text-align: left;
            }
        }
        .footer_buttons {
            button {
                justify-content: center;
                min-width: 140px;
            }
        }
    }
}


/*------- modal footer section -------*/

.modal_footer_section {
    background: #F2F6FC;
    padding: 20px 45px 20px 0;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    button {
        min-width: 140px;
        height: 40px;
        border-radius: 4px;
    }
}
