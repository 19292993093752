.scrollbar {
    background-color: #ffffff;
    //float: left;
    //height: 400px;
    margin-bottom: 25px;
    //margin-left: 22px;
    @include responsive-size(40px, 1920, 1850, margin-top);
    @include media(1920) {
        margin-top: 9px !important;
    }
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.scrollbar_1 {
    background-color: #ffffff;
    margin-bottom: 25px;

    margin-top: 40px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

#scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    &:hover {
        height: 10px;
    }

    background-color: #F5F5F5;
}

#scroll::-webkit-scrollbar-thumb {
    background-color: $mainColor;

}

#scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

/*----- Blue-Scroll start ------*/

.blue-scroll::-webkit-scrollbar {
    //width: 5px;
    height: 5px;
    transition: all 0.5s ease;
    background-color: #F5F5F5;
    cursor: pointer;
}

.blue-scroll::-webkit-scrollbar {
    cursor: pointer !important;
}

.blue-scroll::-webkit-scrollbar-thumb {
    background-color: $mainColor;

}

.blue-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.scrollbar:hover .blue-scroll::-webkit-scrollbar {
    background-color: red;
}

.scrollbar {
    &.scroll {
        background-color: #ffffff;
    }
}

.scroll::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    transition: all 0.5s ease;
    background-color: #F5F5F5;
    cursor: pointer;
}

.scroll::-webkit-scrollbar {
    cursor: pointer !important;
}

.scroll::-webkit-scrollbar-thumb {
    background-color: $mainColor;

}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

/*----- Blue-Scroll end ------*/

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(82, 79, 99, 0.2);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(82, 79, 99, 0.3);
    border-radius: 40px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #F2F6FC;
}

.media_scrollbar {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    height: 490px !important;
}
