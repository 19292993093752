.GDPR_content {
    display: flex;
    font-family: $mainFont, sans-serif;

    .GDPR-main {
        padding: 19px 21px 26px;
        margin: 0;

        .first-col {
            display: flex;
            justify-content: space-between;
            padding: 3% 5%;
            background: #F2F6FC;

            .article_wrapper {
                max-width: 50%;
                flex: 1;

                .article-container {
                    position: relative;

                    &:not(:first-of-type) {
                        margin-top: 20px;
                    }

                    &:not(:last-of-type) {
                        border-bottom: 1px solid rgba(115, 106, 170, 0.2);
                        padding-bottom: 20px;
                    }

                    h6 {
                        margin: 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #524F63;
                    }

                    hr {
                        width: 10px;
                        height: 1.5px;
                        margin: 5px 0;
                        background: #4092C4;
                        border-radius: 4px;
                    }

                    button {
                        max-width: 100%;
                        padding: 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        cursor: pointer;
                        text-decoration: underline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        text-align: start;
                        border: none;
                        background: none;
                        color: #4092C4;
                    }

                    /*.itemText {
                        position: absolute;
                        background: $secondColor;
                        border-radius: 2px;
                        display: none;
                        z-index: 10;
                        padding: 10px;
                        left: 0;
                        margin-top: 10px;

                        p {
                            font-size: 14px;
                            line-height: 20px;
                            color: #FFFFFF;
                            margin-bottom: 0;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            border-bottom: 7px solid $secondColor;
                            top: -7px;
                            left: 13px;
                        }
                    }

                    button {
                        &:hover ~ .itemText {
                            display: block;
                        }
                    }*/
                }

                &:first-of-type {
                    position: relative;
                    padding-right: 57px;

                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 1px;
                        height: 100%;
                        top: 0;
                        right: 0;
                        background: rgba(115, 106, 170, 0.2);

                        @include media(1200) {
                            top: 100%;
                            height: 1px;
                            width: 100%;

                        }
                    }
                }

                &:last-of-type {
                    padding-left: 57px;
                }

                @include media(1500) {
                    &:first-of-type {
                        padding-right: 30px;
                    }

                    &:last-of-type {
                        padding-left: 30px;
                    }
                }

                @include media(1200) {
                    padding: 0 !important;
                    max-width: 100% !important;

                }

                &:last-of-type {
                    @include media(1200) {
                        margin-top: 20px;
                    }
                }
            }

            @include media(1200) {
                flex-direction: column;
            }
        }

        .second-col {
            padding: 3% 5%;
            background: #F2F6FC;

            .doc-items {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                .doc-item {
                    width: 48%;
                    background: #FFFFFF;
                    border: 1px solid rgba(169, 167, 175, 0.2);
                    box-shadow: 0 3px 3px rgba(169, 167, 175, 0.2);
                    border-radius: 4px;

                    &:not(:last-of-type) {
                        margin-bottom: 25px;
                    }

                    &_list {
                        display: flex;
                        justify-content: space-between;
                    }

                    &_text {
                        margin: 0 15px;
                        padding: 10px 0;
                        display: flex;
                        align-items: center;
                        flex: 1 1 calc(100% - 80px);
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        color: #524F63;


                        @include mediaDistance(1600, 1200) {
                            font-size: 13px;
                            line-height: 18px;
                        }

                        @include mediaDistance(1199, 992) {
                            font-size: 12.5px;
                            line-height: 16px;
                        }
                    }

                    &_icons {
                        padding: 10px 0;
                        display: flex;
                        flex: 1 1 80px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0 4px 4px 0;
                        background: #E8E7E7;
                    }
                }

                @include media(1200) {
                    flex-direction: column;

                    .doc-item {
                        width: 100%;
                    }
                }
            }

            @include media(1200) {
                padding: 5%;
            }
        }

        .consent {
            margin-top: 28px;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 6px rgba(64, 146, 196, 0.3);
            border-radius: 4px;

            &-header {
                padding: 13px 0 13px 57px;
                background: #F2F6FC;
                border-radius: 4px 4px 0 0;

                h4 {
                    margin: 0;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #524F63;
                }
            }

            &-body {
                padding: 22px 0 22px 57px;
                border-radius: 0 0 4px 4px;
                background: #FFFFFF;

                p {
                    margin: 0;
                    width: fit-content;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    text-decoration: underline;
                    cursor: pointer;
                    color: rgba(82, 79, 99, 0.8);

                    &:first-of-type {
                        margin-bottom: 6px;
                    }

                    &::before {
                        content: "";
                        display: inline-block;
                        margin-right: 5px;
                        margin-bottom: 2px;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: rgba(64, 146, 196, 0.5);
                    }
                }
            }
        }
    }
}
