.verifiedText {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    border: 1px solid rgba(82, 79, 99, 0.15);
    box-sizing: border-box;
    border-radius: 5px;

    span {
        svg {
            width: 29px;
            height: 29px;

            @include media(1200) {
                width: 25px;
                height: 25px;
            }
        }
    }

    p {
        font-size: 14px !important;
        line-height: 21px;
        text-align: justify;
        color: $textColor;

        @include media(992) {
            font-size: 12px !important;
        }
    }

    @include media(1200) {
        padding: 3%;
    }
}

.verifiedBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 20px 0 0 0;

    span {
        font-size: 14px;
        line-height: 24px;
        color: $textColor;
    }

    .main-button {
        font-size: 14px;
        min-width: 28% !important;
        margin-left: 0!important;

        @include media(576) {
            width: 80% !important;
        }
    }

    @include media(1200) {
        flex-direction: row;
    }

    @include media(992) {
        span {
            font-size: 12px;
        }

        .main-button {
            font-size: 12px;
        }
    }

    @include media(800) {
        flex-direction: column;
    }
}

.verifiedBtn_1 {
    button {
        width: 250px;
    }
}

.editTrustedDomain {
    position: relative;

    .form-control {
        border-radius: 4px 0 0 4px;
    }

    .table_line {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(39, 174, 222, 0.1);
        border-radius: 0 4px 4px 0;
        min-width: 80px;
        hr {
            width: 1px;
            height: 24px;
            background: rgba(82, 79, 99, 0.3);
            border: none;
            margin: 0 6px;
        }
    }

    .confIcon {
        position: absolute;
        top: 26%;
        left: 3%;

        @include media(1500) {
            top: 20%;
            width: 20px;
        }
    }
}

.add-trusted-domain {

    .actions {
        display: flex;
        justify-content: flex-end;

        button {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            min-width: 200px!important;
            padding: 9px 30px;
            margin: 20px 0 0 20px;
        }
        .main-button__disabled {
            background-color: #fff;
        }
    }
}

@include media(1500) {
    .trustedDomain {
        .plusInfo {
            .add-trusted-domain-button {
                span {
                    svg {
                        width: 35px;
                    }
                }
            }
        }
    }

    .editTrustedDomain {
        .table_line {
            .table-button {
                span {
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

@include media(1200) {
    .trustedDomain {
        .plusInfo {
            .add-trusted-domain-button {
                span {
                    svg {
                        width: 25px;
                    }
                }
            }
        }
    }
}
