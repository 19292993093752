.blue_stroke {
    &:hover {
        path {
            fill: $mainColor;
            stroke: $mainColor;
        }
    }
}

.blue_without_stroke {
    &:hover {
        path {
            fill: $mainColor;
        }
    }
}

.blue-stroke {
    &:hover {
        path {
            stroke: $mainColor;
        }
    }
}

.active_without_stroke {
    path {
        fill: $mainColor;
    }
}

.fill_svg {
    path {
        fill: $mainColor;
    }
}

.white {
    path {
        fill: #fff;
    }
}
