:root {
    --red: 39;
    --green: 174;
    --blue: 222;
    //--red: 109;
    //--green: 222;
    //--blue: 38;
    --main-color-rgb: var(--red), var(--green), var(--blue);

    --main-color: rgb(var(--main-color-rgb));
    --second-color: rgb(calc(var(--red) + 25), calc(var(--green) - 28), calc(var(--blue) - 26));
    --main-opacity: rgb(var(--main-color-rgb), 0.2);
    --auth-opacity: rgba(calc(var(--red) - 31), calc(var(--green) - 53), calc(var(--blue) - 37), 0.6);
    --main-transparent: rgb(calc(var(--red) + 203), calc(var(--green) + 72), calc(var(--blue) + 30));
    --second-color-transparent: rgba(calc(var(--red) + 25), calc(var(--green) - 28), calc(var(--blue) - 26), 0.2);
    --text-transparent: rgba(calc(var(--red) + 25), calc(var(--green) - 28), calc(var(--blue) - 26), 0.4);
    --label-text-color: rgb(calc(var(--red) + 43), calc(var(--green) - 95), calc(var(--blue) - 123));
    --cancel-button-background-color: calc(var(--red) + 205), calc(var(--green) + 71), calc(var(--blue) + 26);

}

$mainColor: var(--main-color);
$secondColor: var(--second-color);
$mainOpacity: var(--main-opacity);
$authOpacity: var(--auth-opacity);
$mainTransparent: var(--main-transparent);
$secondTransparent: var(--second-color-transparent);
$textTransparent: var(--text-transparent);

$labelTextColor: var(--label-text-color);
$cancelButtonBackgroundColor: var(--cancel-button-background-color); //#F4F5F8

$headingFont: #6d6e71;
$buttons_blue: #27AEDE;
$buttons_blue_hover: #4092C4;
$success_green: #48C89E;
$inctive_gray: #6D6E71;

$textColor: #524F63;
$errorColor: #DD4B39;
$mainFont: 'Poppins', sans-serif;
$mainPadding: 5%;
$greyBg: #F2F6FC;
$sidebarWidth: 280px;
$headerHeight: 95px;
$mobSidebarWidth: 100px;


@mixin mainColor($color) {
}
