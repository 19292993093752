.emailSetting {

    .emailConfig {
        display: flex;
        justify-content: space-between;

        .serverConfig, .templateConfig {
            width: 47%;

            .configTitle {
                background: #F9FBFE;
                border-radius: 4px;
                padding: 2.5% 3%;
                text-align: center;
                margin-bottom: 10%;

                h5 {
                    margin-bottom: 0;
                    font-size: 18px;
                    line-height: 30px;
                    color: $textColor;
                }
            }

            .form-group {
                margin-bottom: 33px;
            }
        }
    }

    @include media(1500) {
        .emailConfig {
            .serverConfig, .templateConfig {
                .configTitle {
                    h5 {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        .main-button {
            width: 140px;
            height: auto;
        }

    }

    @include media(1200) {

        .emailConfig {
            flex-direction: column;

            .serverConfig, .templateConfig {
                width: 80%;

                .configTitle {
                    margin-bottom: 7%;
                }

                .form-group {
                    margin-bottom: 6%;
                }
            }

            .serverConfig {
                margin: 0 auto;
            }

            .templateConfig {
                margin: 5% auto 0;
            }
        }

        .users_buttons {
            width: 80% !important;
            margin: 0 auto;
        }
    }

    @include media(992) {

        .emailConfig {
            .serverConfig, .templateConfig {
                width: 90%;
            }
        }

        .users_buttons {
            width: 90% !important;
        }

        .emailConfig {
            .phone-code-select {
                .IZ-select__input div {
                    font-size: 14px;
                }

                .main-select .IZ-select__menu-items div {
                    font-size: 14px;
                }
            }

            .form-group {
                label {
                    span {
                        font-size: 14px;
                    }
                }

                .form-control {
                    font-size: 14px;
                    height: 40px;
                }
            }
        }
    }

    @include media(768) {

        .emailConfig {
            .serverConfig, .templateConfig {
                width: 100%;
            }
        }

        .users_buttons {
            width: 100% !important;
        }
    }
}
