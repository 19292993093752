/*----- All start ------*/

.subscription-and-billing {
    font-family: $mainFont, sans-serif;

    &_main {
        padding: 0 12px;

        .subscription-overview-card {
            .card_body {
                .form-group-wrapper {
                    .form-group {
                        .term-label {
                            margin-top: 23px;
                        }

                        .form-label {
                            margin-top: 30px;
                            margin-bottom: 8px;
                        }

                        .first-label {
                            margin-top: 0 !important;
                        }

                        .form-distance {
                            margin-bottom: 10px !important;
                        }
                    }
                }

                p {
                    margin: 0;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: #524F63;
                }
            }
        }

        //@media only screen and (min-width: 1601px) {
        //    margin-bottom: -80px;
        //}
    }

    .all-cards {
        font-family: $mainFont, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 24px !important;
        min-height: 650px;

        .card_heading {
            height: 48px;
            display: flex;
            padding: 0  30px;
            align-items: center;
            font-weight: 500 !important;
            box-sizing: border-box;
            border-radius: 3px 3px 0 0;
            border: 1px solid rgba(64, 146, 196, 0.1);
            color: #4092C4;
            background: #FFFFFF;
            position: relative;
            text-align: center;

            p {
                margin: 0 !important;
            }
        }

        .card_body {
            height: calc(100% - 60px) !important;
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: rgb(242, 246, 252);
            border-radius: 0 0 3px 3px;

            .form-group {
                margin: 0;
                color: rgba(82, 79, 99, 0.4);

                .form-control::placeholder {
                    color: $textColor;
                }

                label {
                    font-size: 14px;
                    line-height: 21px;
                    color: $textColor !important;

                    @include media(1200) {
                        font-weight: 500;
                    }
                }

                .form-input {
                    margin-bottom: 10px !important;
                    color: rgba(82, 79, 99, 0.5);
                }

                .form-info, .form-input {
                    width: 100%;
                    height: 40px;
                    margin: 0;
                    border: 1px solid rgba(82, 79, 99, 0.2);
                    border-radius: 4px;
                    box-sizing: border-box;
                    background: rgba(255, 255, 255, 0.5);
                    padding: 0 15px;
                }

                .form-info {
                    display: flex;
                    align-items: center;
                }

                .change-plan-link {
                    display: flex;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    justify-content: flex-end;
                    color: $secondColor;
                    cursor: pointer;
                }
                .change-plan-btn{
                    background-color: $mainColor;
                    color: white;
                    border-radius: 3px;
                    padding: 5px 15px;
                    &:hover {
                        text-decoration: unset!important;
                    }
                }
            }
        }
    }

    .subscription-cancel-card {
        .card-heading {
            p {
                line-height: 21px !important;
            }
        }

        .card_body {

            p {
                margin-bottom: 0 !important;
                font-size: 14px;
                line-height: 21px;
                color: #524F63;

                &:first-of-type {
                    font-weight: 600 !important;
                }

                span {
                    font-weight: 600 !important;
                }
            }
        }
    }

}

/*----- All end ------*/

/*----- Paid start ------*/

.subscription-and-billing {
    .subscription-and-billing-paid {
        .subscription-overview-card {

            @include mediaDistance(1600, 1200) {
                padding-right: 10px !important;
            }
        }

        .billing-card {
            .card_body {
                .change-plan-link {
                    font-style: italic;
                    font-size: 16px;
                    text-decoration: underline;
                    cursor: pointer;
                    color: #27AEDE;
                }

                .form-group {
                    .form-info {
                        margin-bottom: 10px !important;
                    }
                }
            }

            @include mediaDistance(1600, 1200) {
                padding-left: 10px !important;
            }
        }

        .payment-card {
            .card_body {
                .form-group {
                    .main-select {
                        margin-bottom: 10px !important;

                        .IZ-select__input-wrap {
                            .IZ-select__input {
                                div {
                                    position: absolute;
                                    top: 50%;
                                    transform: translate(15px, -50%);
                                }

                                input {
                                    background: #FFFFFF;
                                    border: 1px solid rgba(64, 146, 196, 0.3);
                                    height: 40px !important;
                                }
                            }
                        }

                        .IZ-select__menu {
                            .IZ-select__menu-items {
                                padding: 17px 0 16px 0;
                                max-height: 373px !important;

                                .IZ-select__item {
                                    height: 40px;
                                    margin-bottom: 10px !important;

                                    &:nth-last-child(2) {
                                        margin-bottom: 0 !important;
                                    }

                                    div {
                                        height: 100% !important;

                                        .img-block {
                                            width: 114px;
                                            height: 40px;
                                            margin: 0 20px 0 24px;
                                            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                        }

                                        p {
                                            margin: 0 !important;
                                            color: #524F63;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .payment-img {
                        border: none;
                        margin-bottom: 10px;
                    }

                    .payment-btn-wrapper {
                        button {
                            &:first-of-type {
                                background: #F4F5F8;
                                opacity: 0.4;
                                border: 1px solid rgba(82, 79, 99, 0.5);
                            }
                        }

                        .payment-btn {
                            height: 40px;
                            width: 49%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .btn-change {
                        text-transform: capitalize;
                    }
                }
            }

            @include mediaDistance(1600, 1200) {
                padding-right: 10px !important;
            }
        }

        .subscription-cancel-card {
            @include mediaDistance(1600, 1200) {
                padding-left: 10px !important;
            }
        }

        //@include mediaDistance(1600, 1200) {
        //    padding: 0 20px !important;
        //}
    }
}

/*----- Paid end ------*/

/*----- For Charitable Organization start ------*/

.subscription-and-billing {
    .for-charitable-organization {
        .subscription-overview-card {
            .card_body {
                padding: 28px 34px 32px;

                .form-group-wrapper {
                    .form-group {

                        &:last-child {
                            margin-bottom: 94px !important;
                        }
                    }
                }

                .form-group {
                    .form-label {
                        line-height: normal !important;
                        margin-bottom: 5px !important;
                    }
                }
            }

            @include mediaDistance(1600, 1200) {
                padding-right: 10px !important;
            }
        }

        .proof-wrapper {
            .proof-of-status-card {
                height: 100%;

                .card_body {
                    font-family: $mainFont, sans-serif;

                    .free-service {
                        width: 82%;
                        margin: 0 auto;
                        background: #FFFFFF;
                        padding: 26px 0;

                        &_info {
                            width: 70.5%;
                            margin: 0 auto;
                            padding: 0;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: center;
                            opacity: 0.5;
                            color: #000;
                        }
                    }

                    .service-option {

                        .option {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: normal;
                            color: #27AEDE;
                        }

                        .option-line {
                            width: 15px;
                            height: 2px;
                            margin: 12px 0;
                            background-color: #27AEDE;
                            box-sizing: border-box;
                            border-radius: 5px;
                        }

                        .option-subtext {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 24px;
                            color: #524F63;
                        }
                    }

                    .first-option {
                        margin: 36px auto 25px;
                        position: relative;

                        .option-1 {
                            margin-bottom: 0;

                            &-subtext {
                                margin: 0;
                                width: 69.8%;

                                @include media(1200) {
                                    width: 82%;
                                }
                            }
                        }

                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 82%;
                            height: 1px;
                            bottom: 0;
                            background: #E2E2E2;

                            @include media(1200) {
                                width: 90%;
                            }

                            @include media(768) {
                                width: 100%;
                            }
                        }

                        .upload-btn {
                            width: 130px;
                            height: 40px;
                            margin-top: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: rgba(82, 79, 99, 0.4);
                            background: #FFFFFF;
                            border: 1px solid rgba(82, 79, 99, 0.2);

                            span {
                                &:first-of-type {
                                    margin-right: 12px;
                                }
                            }
                        }

                        .uploaded-file-wrapper {
                            width: 82%;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: space-evenly;

                            .uploaded-file {
                                width: 151px;
                                height: 31px;
                                margin-top: 20px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;;
                                border: 1px solid rgba(82, 79, 99, 0.2);
                                box-sizing: border-box;
                                border-radius: 40px;
                                display: flex;
                                justify-content: space-evenly;
                                align-items: center;
                                background: #FFFFFF;
                                color: #524F63;
                            }

                            @include media(1200) {
                                width: 100%;

                                .uploaded-file {
                                    font-size: 12px;

                                    span {
                                        svg {
                                            width: 8px;
                                            height: 8px;
                                        }
                                    }
                                }
                            }
                        }

                        .option-btn-wrapper {
                            margin-top: 53px;
                            margin-bottom: 33px;

                            button {
                                &:first-of-type {
                                    margin-right: 8px;
                                    margin-left: 0;
                                    background: #F4F5F8;
                                    opacity: 0.4;
                                    border: 1px solid rgba(82, 79, 99, 0.5);
                                }
                            }

                            .option-file-btn {
                                width: 110px;
                                height: 40px;
                                margin-left: 8px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }

                    .second-option {
                        margin-top: 0;

                        .option-2 {
                            margin: 0;

                            &-subtext {
                                width: 74%;
                                margin: 0 auto;

                                @include media(1200) {
                                    width: 90%;
                                }
                            }
                        }
                    }

                    .request-message-wrapper {
                        width: 66.9%;
                        height: 53%;
                        margin: 0 auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        background: #FFFFFF;

                        .mail-icon-wrapper {
                            width: 130px;
                            height: 88px;
                            margin-bottom: 31px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: linear-gradient(179.67deg, #EBF7FB -5.17%, rgba(201, 240, 251, 0) 123.41%);
                        }

                        .message-title {
                            width: 55%;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            text-align: center;
                            color: #524F63;
                        }

                        .skyblue-line {
                            width: 15px;
                            height: 2px;
                            margin: 20px 0;
                            background-color: #27AEDE;
                            box-sizing: border-box;
                            border-radius: 5px;
                        }

                        .message-subtitle {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                            text-align: center;
                            color: rgba(0, 0, 0, 0.6);
                        }

                        @include media(1600) {
                            .message-title {
                                width: 75%;
                            }
                        }

                        @include media(1200) {
                            width: 90%;

                            .message-title {
                                width: 80%;
                                font-weight: 500;
                                font-size: 12px;
                            }

                            .message-subtitle {
                                font-weight: 500;
                                font-size: 12px;
                            }
                        }

                        @include media(768) {
                            width: 100%;
                            .message-title {
                                width: 90%;
                            }
                        }
                    }

                    @include media(1200) {
                        .free-service {
                            width: 90%;

                            &_info {
                                width: 80%;
                                font-size: 14px;
                            }
                        }

                        .service-option {

                            .option {
                                font-size: 14px;
                            }

                            .option-subtext {
                                font-size: 12px;
                            }
                        }
                    }

                    @include media(768) {
                        .free-service {
                            width: 100%;

                            &_info {
                                width: 90%;
                            }
                        }

                        .service-option {
                            .option-subtext {
                                width: 100%;
                            }
                        }
                    }
                }
            }

        }

        .proof-of-status-docs-card {
            .card_body {
                padding: 53px 34px 32px;

                .form-group-wrapper {

                    .form-group {
                        position: relative;

                        &-icon {
                            position: absolute;
                            right: 15px;
                            top: 8px;
                        }
                    }

                    .domain-settings-group {
                        margin-top: 12px;
                    }

                    .subtitle {
                        margin-top: 14px;

                        p {
                            margin-bottom: 1px;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 21px;
                            color: #524F63;
                        }

                        a {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 21px;
                            text-decoration-line: underline;
                            color: #4092C4;
                        }
                    }

                    .uploaded-file-wrapper {
                        width: 100%;
                        margin-bottom: 34px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-evenly;

                        .uploaded-file {
                            width: 151px;
                            height: 31px;
                            margin-top: 20px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;;
                            border: 1px solid rgba(82, 79, 99, 0.2);
                            box-sizing: border-box;
                            border-radius: 40px;
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            background: #FFFFFF;
                            color: #524F63;
                        }

                        @include media(1200) {
                            .uploaded-file {
                                font-size: 12px;

                                span {
                                    svg {
                                        width: 8px;
                                        height: 8px;
                                    }
                                }
                            }
                        }
                    }
                }

                .main-button {
                    width: 174px;
                    height: 34px;
                    border-radius: 4px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #FFFFFF;
                    background: #48C89E;

                    span {
                        &:first-of-type {
                            margin-right: 11px;
                        }
                    }
                }
            }

        }

        .subscription-cancel-card {

            .card_body {
                padding: 28px 34px 32px;
            }

            @include mediaDistance(1600, 1200) {
                padding-left: 10px !important;
            }
        }
    }
}

/*----- For Charitable Organization end ------*/

.plan_content {
    .box_shadow {
        margin-bottom: 0;

        .information {
            height: auto;
            border-radius: 6px 6px 0px 0px;

            .plan-overflow {
                margin: 0;

                .short_description {
                    gap: 50px;
                    margin: 70px 20px;
                    @media (max-width: 1600px) {
                        gap: 25px;
                        margin: 0 20px;
                    }
                    .card-item {
                        align-items: stretch;
                        height: 100%;
                        width: 28%;
                        @media (max-width: 1600px) {
                            width: 360px;
                        }
                        .all_cards {
                            width: 100%;
                            //height: 100%;
                            //max-width: 430px;
                            margin: 0 auto;
                            font-family: "Popins", sans-serif;
                            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

                            .card_heading {
                                height: 80px;
                                color: #fff;

                                p {
                                    margin: 0;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 20px;
                                    line-height: 36px;
                                    letter-spacing: 0.1em;
                                    text-align: center;
                                    padding: 0 10px;
                                }
                            }

                            .card_body {
                                padding: 28px 23px 26px;
                                height: calc(100% - 278px) !important;
                                font-style: normal;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;
                                text-align: center;
                                opacity: 0.7;
                                min-height: 290px;
                                &-title {
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 27px;
                                    text-transform: capitalize;
                                    color: rgba(82, 79, 99, 0.8);
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .grey_line {
                                        width: 15px;
                                        height: 2px;
                                        margin: 9px 0;
                                        box-sizing: border-box;
                                        border-radius: 5px;
                                        background-color: rgba(82, 79, 99, 0.3);
                                    }

                                    p {
                                        margin: 0;
                                    }
                                }

                                &-subtitle {
                                    margin: 0;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 21px;
                                    text-align: center;
                                    color: #524F63;


                                    @include media(991) {
                                        margin-top: 15px !important;
                                    }
                                }
                            }

                            .card_footer {
                                width: 100%;
                                height: 265px;
                                margin-top: auto;
                                position: relative;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center bottom;

                                p {
                                    margin: 0;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 9px;
                                    letter-spacing: 0.1em;
                                    opacity: 1;
                                    text-transform: lowercase;
                                    color: #fff;
                                    position: absolute;
                                    right: 16px;
                                    bottom: 65.5px;
                                }

                                h5 {
                                    margin-bottom: 0;
                                    font-weight: 600;
                                    font-size: 36px;
                                    line-height: 18px;
                                    color: #fff;
                                    position: absolute;
                                    right: 38.5px;
                                    bottom: 30.6px;
                                }
                                .pricing-btn:not(.current-plan) {
                                    border: 1px solid;
                                    padding: 7px 10px;
                                    border-radius: 4px;
                                }
                            }

                            //@include mediaDistance(1600, 1300) {
                            //    margin: unset;
                            //}
                        }
                    }
                }
            }
        }
    }
}

.parent_content {
    .box_shadow {
        .information {
            .plan-overflow {
                .short_description {
                    .card-item {
                        .standard_card {
                            .card_heading {
                                background: linear-gradient(195.04deg, #82E3FF -24.58%, #27AEDE 114.16%);
                            }

                            .card_body {
                                &-subtitle {
                                    @include media(1600) {
                                        margin-top: 0;
                                    }
                                }
                            }

                            .card_footer {
                                background-image: url(../assets/images/Standard_Footer.svg);

                                .pricing-btn {
                                    color: #82E3FF;
                                }
                            }

                            @include mediaDistance(1600, 1300) {
                                margin-left: auto;
                            }
                        }

                        .professional_card {
                            .card_heading {
                                background: linear-gradient(250.28deg, #5473E9 -9.92%, #36C2CF 83.7%);
                            }

                            .card_footer {
                                background-image: url(../assets/images/Professional_Footer.svg);

                                .pricing-btn {
                                    left: 5%;
                                    color: #36C2CF
                                }
                            }
                        }

                        .large-business_card {
                            .card_heading {
                                background: linear-gradient(193.11deg, #8ADAD5 -25.88%, #3ED7C9 116.27%);
                            }

                            .card_body {
                                padding-bottom: 5px;
                            }

                            .card_footer {
                                background-image: url(../assets/images/Large-Business_Footer.svg);

                                .pricing-btn {
                                    color: #34CCBE
                                }
                            }

                            @include mediaDistance(1600, 1300) {
                                margin-left: auto;
                            }
                        }

                        .international_corporation_card {
                            .card_heading {
                                background: linear-gradient(80.97deg, #CEFF7C -94.69%, #88BA34 134.12%);
                            }

                            .card_body {
                                padding-bottom: 6px;
                            }

                            .card_footer {
                                background-image: url(../assets/images/International_Footer.svg);

                                .pricing-btn {
                                    color: #CEFF7C;
                                }
                            }
                        }

                        .enterprise_card {
                            .card_heading {
                                background: linear-gradient(223.83deg, #EFE85F 6.2%, #E8CD3F 95.41%);
                            }

                            .card_body {
                                padding-bottom: 6px;
                            }

                            .card_footer {
                                background-image: url(../assets/images/Enterprise_Footer.svg);

                                .pricing-btn {
                                    color: #EFE85F;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.edit-billing-details_content {
    .box_shadow {
        .information {

            .force-overflow {

                .short_discraption {
                    font-family: "Popins", sans-serif;

                    .form-group {
                        .form-control {
                            margin: 0;
                        }

                        label {
                            @include mediaDistance(1600, 992) {
                                font-size: 14.5px;
                            }
                            @include mediaDistance(991, 769) {
                                font-size: 13.5px;
                            }
                            @include media(768) {
                                font-size: 12px;
                            }
                        }
                    }

                    .fields-required {
                        .info-small-new {
                            font-style: italic;
                            color: #524F63
                        }
                    }

                    .editBillingDetails-btn {
                        justify-content: space-between;
                        text-align: center;

                        button {
                            width: 48.5%;
                        }
                    }
                }
            }
        }
    }
}

.trial_content {
    height: 94% !important;

    .form_area {
        padding-bottom: 15px;
        margin-top: 15px;

        .information {
            overflow-y: hidden;

            .trial-overflow {
                margin-top: 0 !important;
                margin-bottom: 23px !important;
                justify-content: center;

                .short_description {
                    justify-content: center;
                }

                .request-message-wrapper {
                    width: 578px;
                    margin: 87px 0 0;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background: linear-gradient(179.7deg, #EBF7FB -5.17%, rgba(201, 240, 251, 0) 123.41%);

                    .mail-icon-wrapper {
                        width: 22.5%;
                        height: 88px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #fff;
                    }

                    .message-heading {
                        font-style: normal;
                        font-weight: 800;
                        font-size: 30px;
                        line-height: 36px;
                        color: #27AEDE;
                        margin-bottom: 0;
                        margin-top: 45px;
                    }

                    .message-title {
                        padding: 0 187px;
                        margin: 14px 0 21px;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        display: flex;
                        color: #27AEDE;

                        @include media(700) {
                            padding: 0 142px;
                        }
                    }

                    .message-subtitle {
                        margin-bottom: 78px;
                        display: flex;
                        align-items: center;
                        color: rgba(0, 0, 0, 0.6);

                        span {
                            width: 8px;
                            height: 2px;
                            margin: 0 8px;
                            background-color: #27AEDE;
                            box-sizing: border-box;
                            border-radius: 5px;
                            display: inline-block;
                        }
                    }

                    @include media(768) {
                        margin-top: 23px;
                    }

                    @include media(700) {
                        width: 500px;
                    }
                }
            }

            .for-charitable-organization {
                .trial-charitable-wrapper {
                    margin: 0 auto;

                    .trial-charitable-card {
                        margin: 50px auto 0;

                        h4 {
                            margin: 0;
                            text-align: center;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 26px;
                            line-height: 39px;
                            color: #4092C4;
                        }

                        .card_body {
                            background: none;

                            .free-service {
                                width: 69.7%;
                                height: 160px;
                                padding: 32px 80px;
                                margin: 0 auto;
                                background: linear-gradient(179.85deg, #EBF7FB -5.17%, rgba(201, 240, 251, 0) 123.41%);

                                p {
                                    &:last-of-type {
                                        margin: 0 auto;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 21px;
                                        text-align: center;

                                        span {
                                            color: #777D7F;
                                        }

                                        a {
                                            font-weight: bold;
                                            color: #4092C4;
                                            text-decoration: underline;
                                        }
                                    }
                                }

                                @include mediaDistance(1600, 1400) {
                                    padding: 45px 80px;
                                }

                                @include mediaDistance(1600, 1200) {
                                    width: 80%;
                                }

                                @include mediaDistance(1200, 992) {
                                    padding: 40px 80px;
                                    width: 85%;
                                }

                                @include media(991) {
                                    padding: 20px 75px;
                                    width: 90%;
                                }

                                @include media(750) {
                                    padding: 20px 0;
                                    width: 100%;
                                }
                            }

                            .service-option {
                                width: 69.7%;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                text-align: center;

                                .option-subtext {
                                    width: 69.8%;

                                    span {
                                        color: #777D7F;
                                    }

                                    a {
                                        font-weight: bold;
                                        color: #4092C4;
                                        text-decoration: underline;
                                    }
                                }

                                @include mediaDistance(1600, 1200) {
                                    width: 80%;
                                }

                                @include mediaDistance(1200, 992) {
                                    width: 85%;
                                }

                                @include media(991) {
                                    width: 90%;
                                }

                                @include media(750) {
                                    width: 100%;
                                }
                            }

                            .first-option {
                                margin: 40px auto;

                                .uploaded-file-wrapper {
                                    width: 75%;

                                    .uploaded-file {
                                        margin-top: 0 !important;

                                        @include media(768) {
                                            &:not(:first-child) {
                                                margin-top: 5px;
                                            }
                                        }

                                        @media only screen and (min-width: 1072px) {
                                            &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
                                                margin-top: 5px !important;
                                            }
                                        }

                                        @include mediaDistance(1071, 768) {
                                            &:not(:nth-child(1)):not(:nth-child(2)) {
                                                margin-top: 5px !important;
                                            }
                                        }

                                        @include media(767) {
                                            &:not(:nth-child(1)) {
                                                margin-top: 5px !important;
                                            }
                                        }
                                    }

                                    @include mediaDistance(991, 768) {
                                        flex-direction: row !important;
                                    }
                                }

                                &::after {
                                    width: 100%;
                                }

                                .uploaded-file-wrapper {
                                    margin-top: 15px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    flex-wrap: wrap;
                                }

                                .option-1-subtext {

                                    @include mediaDistance(1200, 992) {
                                        width: 85%;
                                    }

                                    @include media(750) {
                                        width: 80%;
                                    }
                                }

                                .option-btn {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 0;
                                }

                                .option-btn-wrapper {
                                    margin-top: 35px;
                                    margin-bottom: 40px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                }
                            }

                            .second-option {
                                margin: 0 auto;

                                .option-2-subtext {

                                    @include mediaDistance(1200, 992) {
                                        width: 80%;
                                    }
                                }
                            }

                            @include media(750) {
                                padding: 25px 10px 21px;
                            }
                        }

                        @include media(1600) {
                            flex: unset;
                            max-width: unset !important;
                            min-width: unset !important;
                        }
                    }
                }
            }
        }
    }
}

@include media(1500) {
    .subscription-and-billing_main .all-cards .card_heading p {
        font-size: 16px;
    }
    .subscription-and-billing_main .all-cards .card_body .form-group .change-plan-link {
        font-size: 14px;
    }
    .parent_content label {
        font-size: 14px;
    }
    .subscription-and-billing .subscription-and-billing-paid .payment-card .card_body .form-group .payment-btn-wrapper .payment-btn {
        height: auto !important;
        padding: 2% 5%;
        font-size: 14px;
    }
    .parent_content p {
        font-size: 14px;
    }
}
