
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark1:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container1 input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container1 .checkmark:after {
    left: 7px;
    top: 1px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container1 {
    display: block;
    position: relative;
    padding-left: 18px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* checkbox_cont */
.checkbox_cont {
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox_cont input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    //background: url(/images/check_empty.svg) no-repeat;
    background: #FFFFFF;
    border: 2px solid rgba(112, 111, 117, 0.6);
    border-radius: 3px;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    &:after {
        display: none!important;
    }
    &:hover {
        border: 2px solid rgba(112, 111, 117, 0.8);
    }
}

.disable_check ~ .checkmark {
        border: 2px solid #DADADB;
        background-color: #EEEEEE;
        cursor: not-allowed;
}

.checkmark.error_checkbox {
    border: 2px solid rgba(255, 27, 27, 0.6);
}

/* On mouse-over, add a grey background color */
.checkbox_cont:hover input ~ .checkmark {
    background-color: $mainColor;
}

/* When the checkbox is checked, add a blue background */

input:checked ~ .checkmark {
    background: #27AEDE url(/images/check.svg) no-repeat center;
    //background: #27AEDE;
    border: none;
    &:hover {
        background: #1F9AC7 url(/images/check.svg) no-repeat center;
    }
}

input ~ .checked_not_all {
    background: #27AEDE url(/images/minus.svg) no-repeat center;
    z-index: 999;
    border: none;
    &:hover {
        background: #1F9AC7 url(/images/minus.svg) no-repeat center;
        border: none;
    }
}

/* When the checkbox is disable, add a blue background */
input.disable_check:checked ~ .checkmark {
    background: rgba(112, 111, 117, 0.5) url(/images/check.svg) no-repeat center;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox_cont input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox_cont .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*----- The BLUE_CHECK ------*/

.blue_chack {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 40px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.blue_chack input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* When the checkbox is checked, add a blue background */
//.blue_chack input:checked ~ .checkmark {
//    background-color: $mainColor;
//}

/* Show the checkmark when checked */
.blue_chack input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.blue_chack .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*------- Custom CheckBox Start ------*/

.parent_content {
    .information {
        .short_discraption {
            .radio-custom {
                opacity: 0;
                position: absolute;
            }

            .radio-custom, .radio-custom-label {
                width: 18px;
                height: 18px;
                display: inline-block;
                vertical-align: middle;
                margin: 7px;
                cursor: pointer;
                @include media("xxl") {
                    margin-left: 24px;
                }

                @include media(1200) {
                    width: 16px;
                    height: 16px;
                }
            }

            .radio-custom-label {
                position: relative;
                line-height: 17px;
            }

            .radio-custom + .radio-custom-label:before {
                content: '';
                background: $mainTransparent;
                border: 2px solid #eee;
                background: transparent;
                display: inline-block;
                vertical-align: middle;
                width: 18px;
                height: 18px;
                padding: 2px;
                margin-right: 10px;
                text-align: center;
                border-radius: 2px;

                @include media(1200) {
                    width: 16px;
                    height: 16px;
                }
            }

            .radio-custom:checked + .radio-custom-label:before {
                background: $mainColor;
                border-color: $mainColor;
            }

            .radio-custom:checked + .radio-custom-label:after {
                content: "";
                position: absolute;
                left: 7px;
                top: 3px;
                width: 5px;
                height: 11px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);

                @include media(1200) {
                    left: 6px;
                    top: 3px;
                }
            }
        }
    }
}

/*------- Custom CheckBox End ------*/

/*------- disable radio button ------*/

.disable_radio ~ .radio-blue-label {
    background: #EEEEEE;
    border: 2px solid #DADADB;
    cursor: not-allowed!important;
    &:hover {
        border: 2px solid #DADADB;
    }
}
